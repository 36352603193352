import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { useMediaQuery } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import { Checkbox } from '@mui/material';
import { FaEdit } from "react-icons/fa";
import { MdDelete, MdOutlineSettingsSuggest } from "react-icons/md";
import { BsFileEarmarkCodeFill } from "react-icons/bs";
import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SpinnerLoader } from "examples/Loader/Loader";
import { setChatbotHistoryUrl } from "context";
import ActivePublish from "assets/images/flow-icons/active.png"
import Deactivepublish from "assets/images/flow-icons/deActive.png"
import { getColorVlue } from 'services/Utils';
import infoIconShowDetail from 'assets/images/flow-icons/info-icon-show-detail.svg'
import darkModeInfoShowDetail from "assets/images/flow-icons/information-circle-outline.png"
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from 'components/MDTypography';
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md"
import { useResponsive, breakpoints } from 'services/Utils';
import { IoIosCloseCircle } from "react-icons/io";

var JSONPrettyMon = require('react-json-pretty/dist/monikai');


export default function FunctionAsToolsTable({ columns, widthhandle, searchTerm, selectedFunctions, setSelectedFunctions, functionList, isLoader, handleFunctionSelect }) {
    const location = useLocation()
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [accessDetail, setAccessDetail] = useState({ AuthenticationHeader: "", Note: "", Method: "", Url: "", RequestPayload: "", ResponsePayload: "" })
    const [controller, dispatch] = useMaterialUIController();
    const { themeColor, darkMode } = controller;
    const navigate = useNavigate();
    const [windowSize, getWindowSize] = useScreenWidth();
    const [open, setOpen] = useState(false);
    const [collapseRow, setCollapseRow] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const mediaQueries = useResponsive(breakpoints);
    const { isNestHub, isMdLoptop, isloptop, isTablet, isLargeMobile, isSmallMobile, isZoomLoptopScreen } = mediaQueries;

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedFunctions([]);
        } else {
            const allFunctionIds = functionList.map((func) => func.id);
            setSelectedFunctions(allFunctionIds);
        }
        setSelectAll(!selectAll);
    };

    useEffect(() => {
        setChatbotHistoryUrl(dispatch, '')
    }, []);

    const filteredFunctionList = functionList?.filter((functionItem) =>
        functionItem?.llmFunctionName?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    const getAccessDetails = (id) => {
        ApiRequest.getAccessDetail({ id: id }, (res) => {
            if (res.isSuccess) {
                toast.success(res?.data?.message ? res?.data?.message : "Fetch user detail successfully");
                const data = res?.data
                setAccessDetail({ AuthenticationHeader: data["Authentication(Header)"], Note: data?.Note, Method: data?.Method, Url: data.URL, RequestPayload: data?.['Request Payload'], ResponsePayload: data?.['Response Payload'] })
            } else {
                toast.error(res?.error?.message ? res?.error?.message : "Fetch user detail failed");
            }
        });
    };
    const handleAccesDetails = (id) => {
        getAccessDetails(id)
        setModalOpen(true)
    }
    const getBackgroundColor = () => {
        switch (themeColor) {
            case "info":
                return "#1D3E80";
            case "success":
                return "#5DAA45";
            default:
                return themeColor;
        }
    };

    const getTooltipBackgroundColor = () => {
        switch (themeColor) {
            case "info":
                return "#314B87";
            case "success":
                return "#57A846";
            default:
                return themeColor;
        }
    };

    // ==== Pagination ===

    const startItemIndex = page * rowsPerPage + 1;
    const endItemIndex = Math?.min((page + 1) * rowsPerPage, filteredFunctionList?.length);

    const handlePrevPage = () => {
        setPage((prev) => Math?.max(prev - 1, 0));
    };
    
    const handleNextPage = () => {
        setPage((prev) => (prev + 1) * rowsPerPage < filteredFunctionList?.length ? prev + 1 : prev);
    };

    const divider = () => {
        return (
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <div style={{ background: darkMode ? "white" : "#CCCCCC", height: "2px", width: "100%", borderRadius: "50%" }}></div>
          </div>
        )
      }

    return (
        <>
            <div style={{ height: "45px", width: '100%', display: 'flex', padding: '12px 16px', backgroundColor: "#C5D7FF", alignItems: 'center' }}>
                <div style={{ display: 'flex', width: windowSize.innerWidth <= 600 ? "7%" : "3%", }}>
                    <Checkbox style={{ padding: "0px" }} color={"black"}
                            icon={<MdCheckBoxOutlineBlank style={{ color: "black" }} />}
                            checkedIcon={ <MdCheckBox />}
                            checked={selectAll}
                            onChange={handleSelectAll}
                        />
                </div>
                <div style={{ display: 'flex', width: windowSize.innerWidth <= 600 ? "43%" : "32%", }}>
                    
                    {/* darkMode ? <MdOutlineCheckBox style={{ color: "black" }} /> : */}
                    <div
                        style={{ fontWeight: "500", fontSize: "12px", color: "#314B87" }}
                    >
                        Functions
                    </div>
                </div>
                <div
                    style={{ width: windowSize.innerWidth <= 600 ? "50%" : "30%", fontWeight: "500", fontSize: "12px", color: "#314B87"}}
                >
                    X-Agent/URL
                </div>
                {windowSize.innerWidth > 600 && <>
                    <div
                        style={{ width: windowSize.innerWidth <= 600 ? "50%" : "25%", fontWeight: "500", fontSize: "12px", color: "#314B87"}}
                    >
                        Type
                    </div>
                    <div
                        style={{ width:  windowSize.innerWidth <= 600 ? "50%" : "10%", fontWeight: "500", fontSize: "12px", color: "#314B87" }}
                    >
                        Description
                    </div>
                </>}
            </div>
            <Paper sx={{ width: '100%', position: "relative", overflow: 'hidden', background: darkMode ? colors?.background?.card : "white" }}>
                <TableContainer sx={{ minHeight: '200px', maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <Table sx={{ marginTop: "15px", width: '100%', overflowY: "auto" }}>
                        <TableBody>
                            {isLoader ? (
                                <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                                    {[1, 2, 3].map(() => (
                                        <div className="w-56 mr-8">
                                            <Code backgroundColor="grey" foregroundColor="darkgrey" />
                                        </div>
                                    ))}
                                </div>
                            ) : filteredFunctionList?.length ? (
                                <>
                                    {filteredFunctionList.map((row, i) => (
                                        <>
                                            <TableRow
                                                key={i}
                                                tabIndex={-1}
                                                style={{
                                                    backgroundColor: !darkMode ? (i % 2 === 0 ? "white" : "#F1F1F1") : "",
                                                }}
                                            >
                                                <TableCell padding="checkbox" style={{ width: windowSize.innerWidth <= 600 ? "7%" : "3%" }}>
                                                    <Checkbox style={{ padding: "0px" }} color={themeColor}
                                                        icon={<MdCheckBoxOutlineBlank style={{ color: darkMode ? "white" : "black" }} />}
                                                        checkedIcon={darkMode ? <MdOutlineCheckBox style={{ color: "white" }} /> : <MdCheckBox />}
                                                        checked={selectedFunctions.includes(row.id)}
                                                        onChange={() => handleFunctionSelect(row.id)}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    align={"left"}
                                                    style={{
                                                        color: darkMode ? "white" : "black",
                                                        fontSize: "14px",
                                                        padding: "0px",
                                                        width: windowSize.innerWidth <= 600 ? "43%" : "32%",
                                                    }}
                                                >
                                                    {windowSize.innerWidth <= 600 && (
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => {
                                                                setOpen(!open);
                                                                setCollapseRow(i + 1);
                                                            }}
                                                        >
                                                            {(open && collapseRow === (i + 1)) ? (
                                                                <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                                            ) : (
                                                                <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />
                                                            )}
                                                        </IconButton>
                                                    )}
                                                    {row?.llmFunctionName}
                                                </TableCell>
                                                <TableCell
                                                    align={"left"}
                                                    style={{
                                                        color: darkMode ? "white" : "black",
                                                        fontSize: "14px",
                                                        padding: "0px",
                                                        width: windowSize.innerWidth <= 600 ? "50%" : "30%",
                                                        // paddingLeft: isSmallMobile ? "12px" : "0px",
                                                    }}
                                                >
                                                    {row?.agentName}
                                                </TableCell>
                                                {windowSize.innerWidth > 600 && (
                                                    <>
                                                        <TableCell
                                                            align={"left"}
                                                            style={{
                                                                color: darkMode ? "white" : "black",
                                                                fontSize: "14px",
                                                                padding: "0px",
                                                                width: windowSize.innerWidth <= 600 ? "50%" : "25%",
                                                                // paddingLeft: windowSize.innerWidth < 1500 ? "11px" : "28px",
                                                            }}
                                                        >
                                                            {row?.Type}
                                                        </TableCell>

                                                        <TableCell align={"left"} style={{ paddingLeft: "0px", width: windowSize.innerWidth <= 600 ? "50%" : "10%" }}>
                                                            <div className="flex flex-row">
                                                                <Tooltip
                                                                    title="Detail of function tool"
                                                                    arrow
                                                                    slotProps={{
                                                                        tooltip: {
                                                                            sx: {
                                                                                color: "white",
                                                                                backgroundColor: getTooltipBackgroundColor(),
                                                                                fontSize: "12px",
                                                                                fontWeight: "400",
                                                                                '& .MuiTooltip-arrow': {
                                                                                    color: getTooltipBackgroundColor(),
                                                                                },
                                                                            },
                                                                        },
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <img src={darkMode ? darkModeInfoShowDetail : infoIconShowDetail} alt='show detail' onClick={() => handleAccesDetails(row?.id)} className={`cursor-pointer ml-[20px] ${darkMode && "white"} h-[14px] w-[14px]`} />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                            {/* Extended Row */}
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 1 }}>
                                                            <Table>
                                                                <MDBox component="thead">
                                                                    <TableRow >
                                                                        <TableCell
                                                                            align={"left"}
                                                                            style={{ color: darkMode ? "white" : "black", width: "50%", fontWeight: "bold", paddingLeft: "0px"}}
                                                                        >
                                                                            Type
                                                                        </TableCell>
                                                                        <TableCell
                                                                            // key={column.id}
                                                                            align={"left"}
                                                                            style={{ color: darkMode ? "white" : "black", width: "50%", fontWeight: "bold", paddingLeft: "0px" }}
                                                                        >
                                                                            Description
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </MDBox>
                                                                <TableBody >
                                                                    <>
                                                                        <TableRow tabIndex={-1}>
                                                                            <TableCell
                                                                                align={"left"}
                                                                                style={{
                                                                                    color: darkMode ? "white" : "black",
                                                                                    fontSize: "14px",
                                                                                    paddingLeft: "0px",
                                                                                    width: "50%"
                                                                                }}
                                                                            >
                                                                                {row?.Type}
                                                                            </TableCell>

                                                                            <TableCell align={"left"} style={{ paddingLeft: "0px",  width: "50%" }}>
                                                                                <div className="flex flex-row">
                                                                                    <Tooltip
                                                                                        title="Detail of function tool"
                                                                                        arrow
                                                                                        slotProps={{
                                                                                            tooltip: {
                                                                                                sx: {
                                                                                                    color: "white",
                                                                                                    backgroundColor: getTooltipBackgroundColor(),
                                                                                                    fontSize: "12px",
                                                                                                    fontWeight: "400",
                                                                                                    '& .MuiTooltip-arrow': {
                                                                                                        color: getTooltipBackgroundColor(),
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <img src={darkMode ? darkModeInfoShowDetail : infoIconShowDetail} alt='show detail' onClick={() => handleAccesDetails(row?.id)} className={`cursor-pointer ml-[20px] ${darkMode && "white"}`} />
                                                                                        </div>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>

                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </>

                                    ))}
                                </>
                            ) : (
                                <div style={{ height: "130px", fontSize: "14px", padding: "20px", color: "red" }}>No Function found</div>
                            )}
                        </TableBody>
                        {modalOpen &&
                            <Modal isNoPadding={true} open={modalOpen} setOpen={() => setModalOpen(false)} isMedium={isTablet}>
                                <div className="relative">
                                    <MDBox sx={{ position: "absolute", right: "10px",top: "10px", display: "flex", justifyContent: "flex-end" }}>
                                        {/* <CloseIcon className="w-[20px] h-[20px] cursor-pointer" style={{ color: "white" }} onClick={() => setModalOpen(false)} /> */}
                                        <IoIosCloseCircle className=" cursor-pointer" style={{ fontSize: "30px", color: "white" }} onClick={() => setModalOpen(false)} />
                                    </MDBox>
                                    <MDTypography style={{ display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "600", 
                                                    backgroundColor: getBackgroundColor() , padding: "30px", justifyContent: "center", color: "white"}}>
                                        User Access Detail
                                    </MDTypography>
                                </div>
                                
                                <div className="flex flex-col w-full mt-[20px] justify-center items-start text-xl p-[20px]" 
                                    style={{ overflowX: (isLargeMobile || isSmallMobile) && "scroll" }}>
                                    {Object?.entries(accessDetail)?.length ?
                                        Object?.entries(accessDetail).map(([key, value], index) => (
                                            <div key={key} className=" w-full">
                                                <div className="my-4 w-full">
                                                <strong>{key}:</strong> 
                                                <span style={{ color: key === "AuthenticationHeader" && "red" }}>
                                                    {JSON?.stringify(value, null, 2)}
                                                </span>
                                                </div>

                                                {index !== (Object?.entries(accessDetail)?.length -1) && 
                                                <div className="w-full">
                                                    {divider()}
                                                </div>
                                                }
                                            </div>
                                        ))
                                        :
                                        <></>
                                    }

                                </div>
                            </Modal>
                        }
                    </Table>

                    <div style={{ width: "100%",position: "absolute", bottom: "8px",   display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 20px" }}>
                        <div style={{ marginTop: "12px", fontSize: "12px", fontWeight: "500", color: darkMode ? "white" : "#344054" }}>
                            {filteredFunctionList?.length > 0
                            ? `${startItemIndex} - ${endItemIndex} of ${filteredFunctionList?.length}`
                            : `0-0 of 0`} items
                        </div>
                        <div style={{ display: "flex", gap: "20px" }}>
                            <MDButton  isNoWidth={true} size="medium" onClick={handlePrevPage} 
                                        disabled={page === 0} variant="outlined" color={darkMode ? "white" : themeColor} >
                            Previous
                            </MDButton>
                            <MDButton isNoWidth={true} size="medium" onClick={handleNextPage} disabled={(page + 1) * rowsPerPage >= filteredFunctionList?.length} variant="outlined" color={darkMode ? "white" : themeColor}>
                            Next
                            </MDButton>
                        </div>
                    </div>
                </TableContainer>
            </Paper>
        </>
    );

}
