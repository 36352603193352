import { useMediaQuery } from '@mui/material';

export const getFileType = (supportedFormats, mime) => {
    let fileType = supportedFormats[mime];
    return fileType ? '.' + fileType : 'Unsupported';
  };

export const readFileAsBinaryString = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        resolve(reader.result);
      };
      
      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const MAX_FILE_SIZE_MB = 100 
export const validateFileTypes=(validTypes,file) => {
  const inComingFileExt = file?.name.split('.').pop()
  return validTypes.includes(inComingFileExt)
}

  export function validateAndProcessFile(file, supportedFormats) {
    try {
      if (file.size > (MAX_FILE_SIZE_MB + 0.9) * 1024 * 1024) {
        return `"${file?.name}'s" size greater than ${MAX_FILE_SIZE_MB}MB cannot be uploaded. Please reduce the size`
      }
    let supFileTypes = Object.keys(supportedFormats).map((format, _) => {return supportedFormats[format]})
    console.log("sup format==>",supFileTypes)
    // `${supportedFormats[format]}${_ < (supportedFormats -1) ? ", ": ""}`
      supFileTypes = [...new Set(supFileTypes)];
      if (!supportedFormats[file.type]) {
        return `Unexpected File Type. We only support [${supFileTypes}].`
      }

      return true

    } catch (error) {
      return error
    }
  

  }

  export const getColorVlue = (color) => {
    if(color === "info"){
      return "#1c3b7c"
    }
    else if(color === "success"){
      return "#57a846"
    }
    else if(color === "dark"){
      return "#2e2e32"
    }
  }

  //responsiveness

  export const useResponsive = (breakpoints) => {
    return Object.keys(breakpoints).reduce((acc, key) => {
      const { minWidth, maxWidth } = breakpoints[key];
      acc[key] = useMediaQuery(
        minWidth && maxWidth
          ? `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`
          : minWidth
          ? `(min-width: ${minWidth}px)`
          : `(max-width: ${maxWidth}px)`
      );
      return acc;
    }, {});
  };
  
  export const breakpoints = {
    isLargeLoptop: { minWidth: 1700, maxWidth: 1910 },
    isZoomLoptopScreen: { minWidth: 1500, maxWidth: 1700 },
    isMdLoptop: { minWidth: 1000, maxWidth: 1199 },
    isZoomLoptopScreen:{ minWidth: 1500, maxWidth: 1700 },
    isloptop: { minWidth: 1200, maxWidth: 1500 },
    isTablet: { minWidth: 700, maxWidth: 900 },
    isLargeMobile: { minWidth: 400, maxWidth: 600 },
    isSmallMobile: { minWidth: 318, maxWidth: 390 },
    isNestHub:{ minWidth :1000 , maxWidth: 1300 }
  };