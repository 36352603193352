/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useNavigate, useLocation, } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Common Styles
import "assets/styles/core.css";
import 'assets/styles/tailwind.css';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import moment from 'moment'
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import ChatHistoryNav from "examples/Sidenav/ChatHistoryNav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import { TokenService, ACCESS_TOKEN_KEY } from "libraries/TokenService";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setMiniChatbotSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import "./assets/index.css";
import ApiRequest from "services/ApiRequest";
import { intervalShouldStart, setIntervalStartTime } from 'redux/Action';

export default function App() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, miniChatbotSidenav, direction, layout, openConfigurator, themeColor, transparentSidenav,
    whiteSidenav, darkMode, chatbotNavClose
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const dispatcher = useDispatch()
  // const shouldIntervalStart = useSelector((state) => state?.refreshTokenReducer?.intervalShouldStart);
  // const intervalStartTime = useSelector((state) => state?.refreshTokenReducer?.intervalStartTime);
  // const isUserLoggedIn = useSelector((state) => state?.refreshTokenReducer?.userLoginStatus);
  // const isTimeInterval = useSelector((state) => state?.refreshTokenReducer?.isTimeInterval);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseChatbotEnter = () => {
    if (miniChatbotSidenav && !onMouseEnter) {
      setMiniChatbotSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseChatbotLeave = () => {
    if (onMouseEnter) {
      setMiniChatbotSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const CallRefreshTokenApi = () => {
  //   ApiRequest.RefreshTokenExpirey((res) => {
  //     if (res?.isSuccess) {
  //       TokenService.setAccessToken(res?.data?.accessToken);
  //       TokenService.setRefreshToken(res?.data?.refreshToken);
  //     } else {
  //       navigate("/auth/login");
  //     }
  //   });
  // }

  console.log("ooo ACCESS_TOKEN_KEY", ACCESS_TOKEN_KEY)

  // useEffect(() => {
  //   if (!isUserLoggedIn || !shouldIntervalStart) return
  //   const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  //   if (!token) {
  //     navigate("/auth/login")
  //     return;
  //   }
  //   const totalTimeInMinutes = 55;
  //   let timeRemained;
  //   if (intervalStartTime) {
  //     const currentTime = moment();
  //     const differenceInMs = currentTime.diff(moment(intervalStartTime));
  //     const differenceInMinutes = Math.floor(differenceInMs / 1000 / 60);
  //     timeRemained = totalTimeInMinutes - differenceInMinutes;
 
  //   }

  //   let refreshTokenInterval;
  //   if (timeRemained < 0) {
  //     CallRefreshTokenApi()
  //     dispatcher(setIntervalStartTime(moment()));
  //     refreshTokenInterval = setInterval(() => {
  //       dispatcher(setIntervalStartTime(moment()));
  //       CallRefreshTokenApi();
  //     }, totalTimeInMinutes * 60 * 1000);
  //     return;
  //   }

  //   if (timeRemained > 0) {
  //     refreshTokenInterval = setTimeout(() => {
  //       ApiRequest.RefreshTokenExpirey((res) => {
  //         if (res?.isSuccess) {
  //           TokenService.setAccessToken(res?.data?.accessToken);
  //           TokenService.setRefreshToken(res?.data?.refreshToken);
  //           dispatcher(setIntervalStartTime(moment()))
  //           refreshTokenInterval = setInterval(() => {
  //             dispatcher(setIntervalStartTime(moment()))
  //             CallRefreshTokenApi()
  //           }, totalTimeInMinutes * 60 * 1000);
  //         }
  //       });
  //     }, timeRemained * 60 * 1000);

  //   } else {

  //     dispatcher(setIntervalStartTime(moment()))
  //     refreshTokenInterval = setInterval(() => {
  //       dispatcher(setIntervalStartTime(moment()))
  //       CallRefreshTokenApi()
  //     }, totalTimeInMinutes * 60 * 1000);
      
  //   }

  //   return () => clearInterval(refreshTokenInterval);
  // }, [isTimeInterval]);


  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {(!pathname?.includes("/chatbot-widget") && !pathname?.includes("/auth")) &&
            <Sidenav
              color={themeColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          }

          {/* && !chatbotNavClose */}
          {/* Chatbot History */}
          {pathname?.includes('llm/agent-creation/infer') &&
            <div>
              <ChatHistoryNav
                color={themeColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={conversationHistory}
              // onMouseEnter={handleOnMouseChatbotEnter}
              // onMouseLeave={handleOnMouseChatbotLeave}
              />
            </div>
          }
          <Configurator />
          {/* {configsButton} */}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </ThemeProvider>
  );
}

// Used for different language

// direction === "rtl" ? (
//   <CacheProvider value={rtlCache}>
//     <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
//       <CssBaseline />
//       {layout === "dashboard" && (
//         <>
//           <Sidenav
//             color={themeColor}
//             brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
//             brandName=""
//             routes={routes}
//             onMouseEnter={handleOnMouseEnter}
//             onMouseLeave={handleOnMouseLeave}
//           />
//           <Configurator />
//           {/* {configsButton} */}
//         </>
//       )}
//       {layout === "vr" && <Configurator />}
//       <Routes>
//         {getRoutes(routes)}
//         <Route path="*" element={<Navigate to="/dashboard" />} />
//       </Routes>
//     </ThemeProvider>
//   </CacheProvider>
// ) 
