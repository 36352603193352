import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { SpinnerLoader } from "examples/Loader/Loader";
import { Toggle } from "examples/Toggle/Toggle";
import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";
import { useMaterialUIController } from "context";
import { Card, Grid } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Images } from "libraries/Images";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { validateAndProcessFile } from "services/Utils";
import { Tag } from "examples/Tag/Tag";
import Methods from "libraries/CommonMethodsUI";
import { toast } from "react-toastify";
import ApiUrls from "services/ApiUrls";
import { FileIcon } from "examples/FileIcon/FileIcon";
import { MdDelete } from "react-icons/md";
import { getFileType } from "services/Utils";
import useScreenWidth from "libraries/ScreenSizeHook";

const PredictiveAnalysisXAgent = (props) => {
  const [loader, setLoader] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [bucketDocumentId, setBucketDocumentId] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false); // State to track file upload status
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [isDelete, setIsDelete] = useState(false)
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const { id, name } = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(`/patterns/${id}/${name}/agent-creation/infer/predictive-analysis`, { state: { ...location?.state } })
  }, [isDelete])

  const uploadedContent = (fl) => {
    const supportedFormats = {
      'text/csv': 'CSV',
      'application/vnd.ms-excel': 'XLSX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
      'application/octet-stream': 'PARQUET',
      '': 'PARQUET'
    };

    const afile = fl?.[0];
    console.log(afile, "file")

    const validation = validateAndProcessFile(afile, supportedFormats)
    if (validation === true && fl) {
      setUploadedFile(fl);
      setUploadedFileData({
        fileName: afile.name,
        fileSize: afile.size,
        fileType: getFileType(supportedFormats, afile?.type),
      });
      setIsFileUploaded(true);
      toast.success("File(s) have been saved")
    } else {
      toast.error(`${validation}`)
    }

  }

  const saveToDownload = () => {
    setLoader(true);
    if (uploadedFile?.length) {
      ApiRequest.inferFeatureAgent({
        file: uploadedFile?.[0],
        modelName: JSON.parse(localStorage.getItem("featureModelId") || "")?.name,
        saveToBucket: enabled ? 'True' : 'False',
      }, enabled,
        `${location?.state?.deployedURL}${ApiUrls.inferPredictiveAgent}/${location?.state?.objectId}`,
        async (res) => {
          setLoader(false);

          if (res.isSuccess) {
            if (res?.data?.documentId) setBucketDocumentId(res?.data?.documentId)
            else {
              Methods.downloadCSV(res?.data, uploadedFile?.[0]?.name);
            }
            toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
          } else {
            let json = {};
            if (!enabled) {
              const text = new TextDecoder().decode(res?.error);
              json = JSON.parse(text);
            } else {
              json = res?.error;
            }
            toast.error(json?.message ? json?.message : "Inference failed")
          }
        });
    } else {
      toast("Please Upload file for inference");
      setLoader(false);
    }
  }

  const saveToBucket = () => {
    setLoader(true);
    ApiRequest.inferBucketSaveFeatureAgent({
      documentId: bucketDocumentId,
      modelName: JSON.parse(localStorage.getItem("featureModelId") || "")?.name,
      inputOrOutput: "output",
    },
      `${location?.state?.objectId}`, (res) => {
        setLoader(false);
        if (res.isSuccess) {
          Methods.downloadCSV(res?.data, uploadedFile?.[0]?.name);
          toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
        } else {
          toast.error(res?.error?.message ? res?.error?.message : "Inference failed")
        }
      });
  }


  const Layout = ({ layoutTitle, children, style, className }) => {
    return (
      <Card>
        <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
          <MDBox
            variant="gradient"
            bgColor={themeColor}
            color={"white"}
            coloredShadow={themeColor}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            // width="4rem"
            pl={2} pr={2}
            height="4rem"
            mt={-5}
          >
            <MDTypography style={{ color: "white" }} variant="h6">
              {layoutTitle}
            </MDTypography>

          </MDBox>
        </MDBox>
        <MDBox lineHeight={1.25} px={2} py={1} className={className} style={{ paddingBottom: "30px", ...style }}>
          {children}
        </MDBox>
      </Card>
    )
  }
  console.log('uploadedfile', uploadedFile)
  return (
    <div
      style={{
        background: darkMode ? "rgba(46, 52, 73, 1)" : "white",
        overflowY: "hidden",
        height: "calc(100vh)",
      }}
    >
      <DashboardLayout>
        <DashboardNavbar />
        <div
          style={{
            backgroundImage: `url(${darkMode ? Images.flowBgDark : Images.flowXAgents})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
            boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.3)",
            overflowY: "auto",
            height: "calc(100vh - 115px)",
          }}
        >
          <div className="flex flex-col" style={{ justifyContent: "space-between" }}>
            <div style={{
              height: "calc(100vh - 337px)",
              padding:windowSize.innerWidth < 600 ? "50px 30px": windowSize.innerWidth <1500 ? "93px": "150px"
            }}>
              <div
                style={{
                  border: "1px solid rgb(180, 185, 189)",
                  boxShadow: "rgba(0, 0, 0, 0.3) 2px 2px 2px ",
                  background:darkMode  ? "#202940" :"white"
                }}
              >
                <div style={{ paddingTop: "20px", height: windowSize.innerWidth <600 ? "calc(100vh - 280px)": "calc(100vh - 430px)", overflow:"auto" }} >
                  <div className="flex flex-row w-full flex-wrap gap-[20px] px-[40px]" style={{ justifyContent: "end" }} >
                    <div className="mr-4 flex flex-row items-center">
                      <div
                        style={{ color: darkMode ? "white" : "black" }}
                        className="text-sm mr-4"
                      >
                        Save to Bucket
                      </div>
                      <Toggle enabled={enabled} setEnabled={(fl) => setEnabled(fl)} />
                    </div>
                    <MDButton
                      style={{ width: "max-content", minWidth: "140px" }}
                      disabled={false}
                      onClick={() => {
                        navigate(`/patterns/${id}/${name}/agent-creation/config`, {
                          state: {
                            ...location?.state,
                            page: 1,
                            step: 0,
                            isDeploying: false,
                          },
                        });
                      }}
                      variant="gradient"
                      color={themeColor}
                      fullWidth
                    >
                      Modify X-Agent
                    </MDButton>
                  </div>
                  <div style={{ padding:windowSize.innerWidth <1500 ? "30px 10px 20px 10px" :"40px 70px 10px 70px"    }}>
                    <div
                      style={{
                        color: darkMode ? "white" : "black",
                        overflowY: "auto",
                        scrollBehavior: "smooth",
                      }}
                    >
                      {!uploadedFileData ? (
                        <>
                          <MDTypography
                            style={{
                              color: darkMode ? "white" : "#5B5B5B",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            Upload File
                          </MDTypography>
                          <div
                            className={`${uploadedFile?.length ? "pt-24" : "mt-[20px]"}`}
                            style={{
                              position: "relative",
                              border:darkMode ? "1px dashed white" : "1px dashed #00000040",
                              borderRadius: "10px",
                            }}
                          >
                            <div style={{ fontSize: "16px" }} className="flex justify-center w-full pt-4 pl-2">
                              {`${bucketDocumentId
                                ? "Please click on download to save the infer results in your system."
                                : uploadedFile?.length
                                  ? "Click Next to save the uploaded file or remove to upload a new one."
                                  : "Please upload file for inference results of Predictive Analysis X-Agent."
                                }`}
                            </div>
                            {!uploadedFile?.length && (
                              <div>
                                <TabUpload
                                  supportedFileTypes="CSV, XLSX, PARQUET"
                                  isSingleFile={true}
                                  uploadedFile={(fl) => uploadedContent(fl)}
                                  loader={false}
                                />
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              borderRadius: "8px",
                              border: "1px solid #CCCCCC",
                              margin: "70px 50px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "10px",
                              }}
                            >
                              <MDTypography
                                sx={{
                                  width: "100%",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: darkMode ? "white" : "#676767",
                                  borderBottom: "1px solid #CCCCCC",
                                  paddingBottom: "10px",
                                }}
                              >
                                Uploaded Document
                              </MDTypography>
                            </div>
                            <div className="flex flex-grow justify-between w-full flex-wrap items-center py-[20px] px-[10px]">
                              {uploadedFile?.length &&
                                uploadedFile.map((afl, ind) => (
                                  <>
                                    <div key={ind} className="flex items-center gap-[10px]">
                                      <div className="flex gap-[10px]">
                                        <FileIcon fileName={afl?.name} />
                                        <div className="flex flex-col">
                                          <MDTypography
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: "500",
                                              color: darkMode ? "white" : "#101828",
                                            }}
                                          >
                                            {afl?.name}
                                          </MDTypography>
                                          <MDTypography
                                            sx={{
                                              fontSize: "12px",
                                              fontWeight: "400",
                                              color: darkMode ? "white" : "#475467",
                                            }}
                                          >
                                            {(uploadedFileData?.fileSize / 1024).toFixed(2)} KB
                                          </MDTypography>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <MdDelete
                                        style={{
                                          color: darkMode ? "white" : "#314B87",
                                          height: "24px",
                                          width: "24px",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setUploadedFile([]);
                                          setUploadedFileData(null);
                                          setIsFileUploaded(false);
                                        }}
                                      />
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                
                </div>
                  <MDBox
                    mb={4}
                    style={{
                      marginTop:windowSize.innerWidth <1500 ? "10px": "20px",
                      display: "flex",
                      gap: "20px",
                      justifyContent: "center",
                    }}
                  >
                    {bucketDocumentId && (
                      <MDButton
                        style={{ width: "max-content", minWidth: "140px" }}
                        disabled={false}
                        onClick={() => setBucketDocumentId("")}
                        variant="gradient"
                        color={themeColor}
                        fullWidth
                      >
                        Back
                      </MDButton>
                    )}
                    <MDButton
                      style={{ width: "max-content", minWidth: "140px" }}
                      disabled={loader}
                      onClick={() => (bucketDocumentId ? saveToBucket() : saveToDownload())}
                      variant="gradient"
                      color={themeColor}
                      fullWidth
                    >
                      {loader ? (
                        <SpinnerLoader adjustment={true} enhance="text-white text-xs" />
                      ) : bucketDocumentId ? (
                        "Download File"
                      ) : (
                        "Next"
                      )}
                    </MDButton>
                  </MDBox>
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default PredictiveAnalysisXAgent;
