import { TokenService } from "libraries/TokenService";

const fetchWithInterceptor = async (url, options) => {
    // Request interceptor logic
    options.headers = {
        ...options.headers,
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
    };

    try {
        const response = await fetch(url, options);

        // Response interceptor logic
        if (response.status === 401) {
            // Handle unauthorized access (e.g., refresh token)
            const refreshToken = TokenService.getRefreshToken();
            const refreshResponse = await fetch('/auth/refresh-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refreshToken }),
            });

            if (refreshResponse.ok) {
                const { accessToken } = await refreshResponse.json();
                TokenService.setAccessToken(accessToken);

                // Retry the original request with the new access token
                options.headers.Authorization = `Bearer ${accessToken}`;
                const retryResponse = await fetch(url, options);
                return retryResponse;
            } else {
                // Handle refresh token failure
                TokenService.clearTokens();
                window.location.href = '/login'; // Redirect to login
            }
        }

        return response;
    } catch (error) {
        // Handle fetch error
        throw error;
    }
};

export default fetchWithInterceptor;