/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { useMaterialUIController } from "context";
import { useResponsive, breakpoints } from "services/Utils";

function configs(labels, datasets) {
  const mediaQueries = useResponsive(breakpoints);
  const { isLargeLoptop, isMdLoptop,isSmallMobile,isLargeMobile} = mediaQueries;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const fontSize = isLargeLoptop ? 11 : isMdLoptop ? 9 :isSmallMobile|| isLargeMobile ? 8 : 12;
  return {
    data: {
      labels,
      datasets: [...datasets],
    },
    options: {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: darkMode ? '#FFFFFF' : '#000000' // White legend text in dark mode, black in light mode
          },
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem?.dataset?.label}: ${tooltipItem?.raw}`;
          },
        },
      },
      scales: {
        y: {
          grid: {
            color: '#D3D3D3',  // inner grid color (gray)
            lineWidth: 0.5,
            borderColor: '#000000',  // outer y-axis line color (black)
            borderWidth: 1,  // line thickness
          },
          ticks: {
            beginAtZero: true,
            color: darkMode ? '#FFFFFF' : '#8592A6', // White for dark mode, gray for light mode
            font: {
              size: fontSize,  // Apply the dynamic font size
            },
          },
        },
        x: {
          grid: {
            display: false, 
            color: '#D3D3D3',  // inner grid color (gray)
            lineWidth: 0.5,
            borderColor: '#000000',  // outer x-axis line color (black)
            borderWidth: 1,  // line thickness
          },
          ticks: {
            color: darkMode ? '#FFFFFF' : '#8592A6', // White for dark mode, gray for light mode
          },
        },
      },
    },
  };
}

export default configs;
