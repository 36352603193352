import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";

import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/BarCharts/VerticalBarChart/configs";
import colors from "assets/theme/base/colors";
import { getColorVlue } from "services/Utils";
import { useMaterialUIController } from "context";
import { Menu, MenuItem, } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useResponsive, breakpoints } from "services/Utils";
import { barChartFilter } from "libraries/Enums";

ChartJS.register(CategoryScale,ArcElement, LinearScale, BarElement, Title, Tooltip, Legend);

const VerticalBarChart = React.memo(({ icon, title, description, height, chart, storageTitle, noOfrequestTitile, tokenConsumedTitle, handleSelection, selectedData, selectedTokenConsumedData, handleTokenConsumeData })=> {
  const [controller] = useMaterialUIController();
  const mediaQueries = useResponsive(breakpoints);
  const { isloptop, isLargeMobile, isSmallMobile } = mediaQueries;
  const { darkMode, themeColor } = controller;
  const hasNoData = !chart?.datasets?.length || chart?.datasets?.every(dataset => dataset.data?.length === 0);

  // Menu logic
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event?.currentTarget);
    handleSelection(barChartFilter.tokenConsumed)
  };

  const handleMenuClose = (selection) => {
    setAnchorEl(null);
    if (selection) {
      handleTokenConsumeData(selection); 
    }
  };
  const getColorByValue = (value, maxValue, minValue) => {
    if (value === maxValue) return "#D90000";  
    if (value === minValue) return "#495361";  
    return "#1A73E8";                          
  };

  const chartDatasets = chart?.datasets?.length
    ? chart.datasets.map((dataset) => {
      const maxValue = Math.max(...dataset?.data);
      const minValue = Math.min(...dataset?.data);
      return {
      ...dataset,
      weight: 5,
      borderWidth: 0,
      borderRadius: 6, // Rounded bars
      backgroundColor: dataset?.data?.map(value => getColorByValue(value, maxValue, minValue)) || "#1F77B4",
      hoverBackgroundColor: dataset?.data?.map(value => getColorByValue(value, maxValue, minValue)) || "#FF0000",
      fill: false,
      maxBarThickness: 40,
      }
    })
    : [];

  const { data, options } = configs(chart?.labels || [], chartDatasets);
  const TabItem = ({ isSelected, onClick, label }) => (
    <div
      style={{
        backgroundColor: isSelected ? getColorVlue(themeColor) : "transparent",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        padding: isloptop ? "2px 10px" : "2px 15px",
        border: isSelected ? "2px solid #A9A9A9" : "1px solid #D3D3D3",
        boxShadow: isSelected ? "0 4px 6px rgba(0, 0, 0, 0.1)" : "none",
        transition: "background-color 0.3s, border 0.3s",
      }}
      onClick={onClick}
    >
      <MDTypography
        style={{
          fontSize: isloptop ? "11px" : "12px",
          fontWeight: "500",
          color: darkMode ? "white" : isSelected ? "white" : "#0F0F0F",
          padding: "0px 5px",
        }}
      >
        {label}
      </MDTypography>
    </div>
  );


  const renderChart = (
    <MDBox py={1} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon && (
            <MDBox
              width={isLargeMobile || isSmallMobile ? "102px" : "57px"}
              height="58px"
              bgColor={themeColor}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-4}
              mr={2}
            >
              <img src={icon} alt={'chart-icon'} />
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title &&
              <div className={`flex ${isloptop ? "gap-[5px]" : "gap-[20px] "} items-center flex-wrap`}>
                <MDTypography sx={{ fontSize: isloptop ? "11px" : "16px", fontWeight: "500", color: darkMode ? "white" : "#0F0F0F" }}>{title}</MDTypography>

                <TabItem
                  isSelected={selectedData === barChartFilter.storage}
                  onClick={() => handleSelection(barChartFilter.storage)}
                  label={storageTitle}
                />
                <TabItem
                  isSelected={selectedData === barChartFilter.requests}
                  onClick={() => handleSelection(barChartFilter.requests)}
                  label={noOfrequestTitile}
                />
                <div
                  style={{
                    backgroundColor: selectedData === barChartFilter.tokenConsumed ? getColorVlue(themeColor) : "transparent",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: isloptop ? "2px 10px" : "2px 15px",
                    border: selectedData === barChartFilter.tokenConsumed ? "2px solid #A9A9A9" : "1px solid #D3D3D3",
                  }}
                  onClick={handleMenuClick}
                >
                  <MDTypography
                    style={{
                      fontSize: isloptop ? "11px" : "12px",
                      fontWeight: "500",
                      color: selectedData === barChartFilter.tokenConsumed || darkMode ? "white" : "#0F0F0F",
                    }}
                  >
                    {selectedTokenConsumedData === "day" || selectedTokenConsumedData === "week" || selectedTokenConsumedData === "month" || selectedTokenConsumedData === "All Token Consumed"
                      ? `${selectedTokenConsumedData.charAt(0).toUpperCase()}` + selectedTokenConsumedData.slice(1)
                      : `All ${tokenConsumedTitle}`}
                  </MDTypography>

                  <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : selectedData === barChartFilter.tokenConsumed ? "white" : "#0F0F0F", }} />
                </div>

                <Menu
                  id="menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={() => handleMenuClose(null)}
                  MenuListProps={{
                    'aria-labelledby': 'menu-button',
                  }}
                >
                  <MenuItem onClick={() => handleMenuClose('All Token Consumed')}>All Tokens Consumed</MenuItem>
                  <MenuItem onClick={() => handleMenuClose('day')}>Day</MenuItem>
                  <MenuItem onClick={() => handleMenuClose('week')}>Week</MenuItem>
                  <MenuItem onClick={() => handleMenuClose('month')}>Month</MenuItem>
                </Menu>
              </div>
            }
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          hasNoData ? (
            <MDTypography variant="h6" color="text" align="center" style={{ height }} sx={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:"26px"}} >
              No Data Available
            </MDTypography>
          ) : (
            <div style={{ height }}>
              <Bar data={data} options={options} redraw />
            </div>
          )
        ),
        [chart, height,selectedData] // Memoize based on these dependencies
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
})

// Setting default values for the props of VerticalBarChart
VerticalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the VerticalBarChart
VerticalBarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default VerticalBarChart;