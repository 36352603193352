/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// PieChart configurations
import configs from "examples/Charts/PieChart/configs";

import { getColorVlue } from "services/Utils";
import { useMaterialUIController } from "context";
import { useResponsive, breakpoints } from "services/Utils";
import AegntsbypatternImage from "assets/images/patterns/agents-y-pattern.svg"
import AgentsByStatusImage from 'assets/images/flow-icons/agebtbystatus.svg'
import ApiRequest from "services/ApiRequest";


ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = React.memo(({ icon, title, description, pieChart }) => {
  const [dashboardStats, setDashboardStats] = useState({
    noOfRequests: [],
    agentsByStatus: { labels: [], data: [] },
    agentsByPattern: { labels: [], data: [] }
  });
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const mediaQueries = useResponsive(breakpoints);
  const { isNestHub } = mediaQueries;
  const [isLoader, setIsLoader] = useState(true);

  useEffect(() => {
    allDashboardStats()
  }, [])

  const allDashboardStats = () => {
    setIsLoader(true);
    ApiRequest.dashboardChartStats('', '', (res) => {
      if (res.isSuccess) {
        const { agentsByNumberOfRequests, agentsByPattern, agentsByStatus } = res.data;
        console.log(res?.data, "data")
        const { patternAPIRequests } = agentsByNumberOfRequests[0];
        const { statusCounts } = agentsByStatus[0];
        const { patternCounts } = agentsByPattern[0];

        const agentsStatusLabels = statusCounts?.map(status => status?.status);
        const agentsPatternLabels = patternCounts?.map(pattern => pattern?.patternName);

        setDashboardStats({
          noOfRequests: patternAPIRequests,
          agentsByStatus: {
            labels: agentsStatusLabels.map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()),
            data: agentsStatusLabels.map(item => {
              let hasStatus = statusCounts?.filter(status => status?.status?.toLowerCase() === item?.toLowerCase());
              return hasStatus.length > 0 ? hasStatus[0]?.count : 0;
            }),
          },
          agentsByPattern: {
            labels: agentsPatternLabels,
            data: agentsPatternLabels.map(item => {
              let hasPattern = patternCounts.filter(pattern => pattern.patternName?.toLowerCase() === item?.toLowerCase());
              return hasPattern.length > 0 ? hasPattern[0]?.count : 0;
            }),
          },
        });
        setIsLoader(false);
      } else {
        setIsLoader(false);
      }
    });
  };
  const chartData = {
    labels: dashboardStats?.agentsByPattern?.labels?.map((label, index) => { return `${label} (${dashboardStats?.agentsByPattern?.data[index]})` }),
    datasets:
    {
      label: 'Agents by pattern',
      data: dashboardStats?.agentsByPattern?.data,
      backgroundColors: ["#D81B60", "#1A73E8", "#495361", "#E53935", "#E53935"]
    }
  }

  const secondChartData = {
    labels: dashboardStats?.agentsByStatus?.labels?.map((label, index) => { return `${label} (${dashboardStats?.agentsByStatus?.data[index]})` }),
    // options:options,
    datasets:
    {
      label: 'Agents by status',
      data: dashboardStats?.agentsByStatus?.data,
      // #1E4585
      backgroundColors: ["#D81B60", "#1A73E8", "#495361", "#E53935", "#E53935"]
    }
  }

  const selectedChartData = pieChart === 1 ? chartData : secondChartData;
  const { data, options } = configs(selectedChartData.labels || [], selectedChartData.datasets || {});
  options.maintainAspectRatio = false;
  const noData = selectedChartData?.datasets?.data?.every((item) => item === 0);
  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2} sx={{ height: isNestHub ? "362px" : "360px" }}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "dark"}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={300}>
            <div className="flex items-center gap-[20px]">
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "58px", width: "57px", borderRadius: "9px", background: getColorVlue(themeColor), marginTop: "-30px", }}>
                <img src={pieChart === 1 ? AegntsbypatternImage : AgentsByStatusImage} alt={'storage'} />
              </div>
              <MDTypography style={{ weight: "500", fontSize: "16px", color: darkMode ? "white" : "black", marginTop: "-15px" }}>
                {pieChart === 1 ? "Agents by pattern" : "Agents by status"}
              </MDTypography>
            </div>
            {noData ? (
              <>
                <MDTypography variant="h6" color="text" align="center" sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "26px",height:"300px" }} >
                  No Data Available
                </MDTypography>
              </>

            )
              :
              (
                <>
                  <Pie data={data} options={options} redraw />
                </>
              )
            }



          </MDBox>
        ),
        [selectedChartData]
      )}
    </MDBox>
  );

  return <Card>{renderChart}</Card>;
})

// Setting default values for the props of PieChart
PieChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the PieChart
PieChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  pieChart: PropTypes.number.isRequired,
};
export default PieChart;