import { IS_LOGIN, IS_PERMISSION, IS_LOGOUT, THEME_COLOR, SQL_TOOLS,INTERVAL_SHOULD_START, INTERVAL_START_TIME, IS_USER_LOGIN_STATUS,IS_TIME_INTERVAL,FUNCTION_AS_TOOL } from "redux/Types";

export const isLoginAction = (data) => {
    const loginTime = new Date().getTime();
    localStorage.setItem('loginTime', loginTime);
    return {
        type: IS_LOGIN,
        payload: { ...data, loginTime },
    };
};

export const isPermissionAction = (data) => ({
    type: IS_PERMISSION,
    payload: data,
});

export const isLogoutAction = () => ({
    type: IS_LOGOUT,
    payload: null
});

export const isThemeColorAction = (data) => ({
    type: THEME_COLOR,
    payload: data
});
export const isSqlToolsAction = (data) => ({
    type: SQL_TOOLS,
    payload: data
});
export const isFunctionAsTool = (data) => ({
    type: FUNCTION_AS_TOOL,
    payload: data
});
export const intervalShouldStart = (data) => ({
    type: INTERVAL_SHOULD_START,
    payload: data
});
export const setIntervalStartTime = (data) => ({
    type: INTERVAL_START_TIME,
    payload: data
});
export const isUserLoginStatus = (data) => ({
    type: IS_USER_LOGIN_STATUS,
    payload: data
});
export const isTimeInterval = (data) => ({
    type: IS_TIME_INTERVAL,
    payload: data
});

