import { useMaterialUIController } from "context";

function configs(labels, datasets) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return {
    data: {
      labels: labels || [],
      datasets: datasets || [],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'top',
          labels: {
            color: darkMode ? '#FFFFFF' : '#000000' // White legend text in dark mode, black in light mode
          }
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem?.dataset?.label}: ${tooltipItem?.raw}`;
            },
          },
        },
      },
      scales: {
        y: {
          ticks: {
            beginAtZero: true,
            color: darkMode ? '#FFFFFF' : '#8592A6', // White for dark mode, gray for light mode
          },
          grid: {
            color: '#D3D3D3',  // inner grid color (gray)
            lineWidth: 0.5,
            borderColor: '#000000',  // outer y-axis line color (black)
            borderWidth: 1,  // line thickness
          },
        },
        x: {
          ticks: {
            color: darkMode ? '#FFFFFF' : '#8592A6', // White for dark mode, gray for light mode
          },
          grid: {
            display: false, 
            color: '#D3D3D3',  // inner grid color (gray)
            lineWidth: 0.5,
            borderColor: '#000000',  // outer x-axis line color (black)
            borderWidth: 1,  // line thickness
          },
        },
      },
    },
  };
}

export default configs;
