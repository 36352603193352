import { useEffect, useState, React } from "react";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { BsFillNutFill } from "react-icons/bs";

export const InputFieldComponent = ({ fi, config, setConfig, isNoLabel,disabled,handleChange, isHandleChange }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor } = controller;

    const hideShowEye = (pass) => {
      return (
        <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={()=> {
                  setShowPassword((pass) => !pass)
                }}
                style={{ color: darkMode ? "white" : "black" }}
                onMouseDown={(e)=> e.preventDefault()}
                edge="end"
              >
                {!pass ?  <Visibility style={{ width: "17px", height: "17px" }} /> 
                      : <VisibilityOff style={{ width: "17px", height: "17px" }}  />}
              </IconButton>
            </InputAdornment>
      )
    }

    const onChangeHandler = (e) => {
      const value = e.target.value;

      if (handleChange) {
          handleChange(e); 
      } else {
          isNoLabel ? setConfig(e) : setConfig({ ...config, [fi.name]: value });
      }
    };

    console.log("config ttt", config)
    console.log("fi ttt", fi)
    
    return (
            <div style={{ width: "100%" }}>
                       {!isNoLabel &&
                       <div style={{ marginBottom: "3px", display: "flex", flexDirection: "row", alignItems: "center",  fontSize: "14px", fontWeight: "450", margin: "0px", color: darkMode ? "white" : "black" }}>
                          {fi?.placeholder}
                          {fi?.mendatory  &&<span style={{ color: "red" }}>*</span>}
                          
                          <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
                          <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />
                          </div>
                        </div> 
                        }
                        <MDInput
                          style={{
                            borderRadius: '8px', // Rounded corners
                            // borderColor: "#CCCCCC"
                            // marginBottom: '16px' // Space between fields if needed
                          }}
                          id={fi.name}
                          type={fi.name === 'password' && showPassword ? 'text' : fi.type}
                          value={isNoLabel ? config : isHandleChange ? config : config[fi.name] || ''} // Ensure value is never undefined
                          onChange={onChangeHandler}
                          label="" // Hide the label
                          placeholder={`Enter ${fi.placeholder}`} // Add placeholder for user guidance
                          InputLabelProps={{
                            style: { display: 'none' } // Optionally hide any residual label styles
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter"){
                              e.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: fi?.type === 'password' ? (
                              hideShowEye(showPassword) 
                            ) : null
                          }}
                          variant="outlined" // Complete border with outlined style
                          disabled={disabled}
                          fullWidth

                        />

            </div>
    )
};
