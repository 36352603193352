/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-dupe-keys */
// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";


const { gradients, dark } = colors;


function configs(labels, datasets) {
  const backgroundColors = [];
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) =>
      gradients[color]
        ? backgroundColors.push(gradients[color].state)
        : backgroundColors.push(dark.main)
    );
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          fontColor: "blue",
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: datasets.backgroundColors,
          fill: true,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: darkMode ? "white" : "black", 
            usePointStyle: true, 
            pointStyle: 'rect',
            generateLabels: function(chart) {
              const labels = chart.data.labels.map((label, i) => {
                const meta = chart.data.datasets[0];
                return {
                  text: label,
                  fillStyle: meta.backgroundColor[i],
                  strokeStyle: '#D3D3D3', 
                  lineWidth: 3, 
                  usePointStyle: true, 
                  pointStyle: 'rect', 
                  fontColor: darkMode ? "white" : "black",  
                };
              });
              return labels;
            },
            // font: {
            //   size: 14, // Adjust font size if needed
            // }
          },
        },
      },
      interaction: {
        intersect: true,
        mode: "index",
      },
    },
  };
}

export default configs;
