import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ApiRequest from "services/ApiRequest";

import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import MDInput from "components/MDInput";
import useScreenWidth from "libraries/ScreenSizeHook";

import Grid from "@mui/material/Grid";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// import { Paths } from "libraries/Route";

import { useMaterialUIController } from "context";
import Constants from "libraries/Constants";
import MDTypography from "components/MDTypography";
import GraphTrain from "assets/images/flow-icons/graph-train.svg"
import GraphTest from "assets/images/flow-icons/graph-test.svg"
import GraphAccuracy from "assets/images/flow-icons/grap-accuracy.svg"

function PredictiveAnalysisResult(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [inputValues, setInputValues] = useState(
    {
      modelName: location?.state?.beforeTrained?.modelName,
      modelType: location?.state?.result?.modelType,
      precision: location?.state?.result?.metrics?.precision,
      recall: location?.state?.result?.metrics?.recall,
      testAccuracy: location?.state?.result?.metrics?.testAccuracy,
      trainAccuracy: location?.state?.result?.metrics?.trainAccuracy,
      validationAccuracy: location?.state?.result?.metrics?.validationAccuracy,
      r2: location?.state?.result?.metrics?.r2,
      rmse: location?.state?.result?.metrics?.rmse,
      mse: location?.state?.result?.metrics?.mse,
    }
  );

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;

  const navigation = (route, page, step, deployedURL, uploadedFile, result) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: deployedURL,
        status: location?.state?.status,
        isDeploying: location?.staate?.isDeploying,
        uploadedFile: uploadedFile,
        isTrained: false,
        result: result,
        beforeTrained: location?.state?.beforeTrained
      }
    });
  };


  const fields = [
    {
      placeholder: "Test Accuracy",
      name: "testAccuracy",
      val: `${(inputValues.testAccuracy * 100)}%`,
      type: "text",
      active: (inputValues.testAccuracy || inputValues.testAccuracy === 0) && 
              inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Train Accuracy",
      name: "trainAccuracy",
      val: `${(inputValues.trainAccuracy * 100)}%`,
      type: "text",
      active: (inputValues.trainAccuracy || inputValues.trainAccuracy === 0) && 
              inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Validation Accuracy",
      name: "validationAccuracy",
      val: `${(inputValues.validationAccuracy * 100)}%`,
      type: "text",
      active: (inputValues.validationAccuracy || inputValues.validationAccuracy === 0) && 
              inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Precision",
      name: "precision",
      val: `${(inputValues.precision * 100).toFixed(0)}%`,
      type: "text",
      active: (inputValues.precision || inputValues.precision === 0) && 
              inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "Recall",
      name: "recall",
      val: `${(inputValues.recall * 100)}%`,
      type: "text",
      active: (inputValues.recall || inputValues.recall === 0) && 
              inputValues.modelType === Constants.predictiveClassification?.toLowerCase()
    },
    {
      placeholder: "R2",
      name: "r2",
      val: `${(inputValues.r2 * 100)}%`,
      type: "text",
      active: (inputValues.r2 || inputValues.r2 === 0) && 
              inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    },
    {
      placeholder: "RMSE",
      name: "rmse",
      val: `${(inputValues.rmse * 100)}%`,
      type: "text",
      active: (inputValues.rmse || inputValues.rmse === 0) && 
              inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    },
    {
      placeholder: "MSE",
      name: "mse",
      val: `${(inputValues.mse * 100)}%`,
      type: "text",
      active: (inputValues.mse || inputValues.mse === 0) && 
              inputValues.modelType === Constants.predictiveRegression?.toLowerCase()
    }
  ];

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  const previousSetup = () => {
    navigation('/train', 3, 2, location?.state?.deployedURL, location?.state?.uploadedFile, location?.state?.result);
  }

  return (
    <div className="flex flex-col" style={{ justifyContent: "space-between" }}>
      <div
        className="w-[100%] flex flex-row flex-wrap"
        style={{ justifyContent: "center", gap: "20px", padding: "30px", height: "calc(100vh - 437px)", overflowY: "auto" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: "100%", gap: "20px", flexWrap: windowSize.innerWidth < 1000 ? "wrap" : "" }}>
          <div style={{ display: "flex", paddingTop: "20px", height: "50px", border: "1px solid #EFF0F6", borderRadius: "20px", padding: "10px", background: darkMode ? "transparent" : "#FBFCFD", width: windowSize.innerWidth > 600 && windowSize.innerWidth < 740 ? "50%" : "100%" }}>
            <MDTypography style={{ fontWeight: "400", fontSize: windowSize.innerWidth > 1030 ? "16px" : "14px", color: darkMode ? "white" : "#000000B2" }}>Model Name : </MDTypography>
            <MDTypography style={{ fontWeight: "600", fontSize: windowSize.innerWidth > 1030 ? "16px" : "14px", color: darkMode ? "white" : "#000000B2", marginLeft: "3px" }}> {inputValues.modelName} </MDTypography>
          </div>
          <div style={{ display: "flex", height: "50px", border: "1px solid #EFF0F6", borderRadius: "20px", padding: "10px", background: darkMode ? "transparent" : "#FBFCFD", width: windowSize.innerWidth > 600 && windowSize.innerWidth < 740 ? "50%" : "100%" }}>
            <MDTypography style={{ fontWeight: "400", fontSize: windowSize.innerWidth > 1030 ? "16px" : "14px", color: darkMode ? "white" : "#000000B2" }}>Model Type : </MDTypography>
            <MDTypography style={{ fontWeight: "600", fontSize: windowSize.innerWidth > 1030 ? "16px" : "14px", color: darkMode ? "white" : "#000000B2", marginLeft: "3px" }}> {inputValues.modelType} </MDTypography>
          </div>
        </div>

        {fields.filter(ch => ch.active).map(fi => {
          console.log(fi, "dataset")
          return (

            <div style={{ display: "flex", paddingTop: "20px", justifyContent: "center", gap: "15px", alignItems: "start", flexWrap: "wrap" }}>
              <div style={{ border: "1px solid #EFF0F6", borderRadius: "20px", padding: "30px 20px" }}>
                <MDTypography sx={{ fontSize: "14px", fontWeight: "500", color: darkMode ? "white" : "#262E36" }}>
                  {fi?.placeholder}
                </MDTypography>
                <MDTypography sx={{ fontSize: "24px", fontWeight: "700", color: darkMode ? "white" : "#000000", marginTop: "10px" }}>
                  {fi?.val}
                </MDTypography>
                <img src={GraphTrain} alt="train" />
              </div>
            </div>
          )
        })}

        {/* <div style={{ border: "1px solid #EFF0F6", borderRadius: "20px", padding: "30px 20px" }}>
            <MDTypography sx={{ fontSize: "14px", fontWeight: "500", color: darkMode ? "white" : "#262E36" }}>Test Accuracy</MDTypography>
            <MDTypography sx={{ fontSize: "24px", fontWeight: "700", color: darkMode ? "white" : "#000000", marginTop: "10px" }}>{(inputValues.testAccuracy * 100)}%</MDTypography>
            <img src={GraphTrain} alt="train" />

          </div>
          <div style={{ border: "1px solid #EFF0F6", borderRadius: "20px", padding: "30px 20px" }}>
            <MDTypography sx={{ fontSize: "14px", fontWeight: "500", color: darkMode ? "white" : "#262E36" }}>Validation Accuracy</MDTypography>
            <MDTypography sx={{ fontSize: "24px", fontWeight: "700", color: darkMode ? "white" : "#000000", marginTop: "10px" }}>{(inputValues.validationAccuracy * 100)}%</MDTypography>
            <img src={GraphTrain} alt="train" />

          </div> */}

        {/* {fields.filter(ch => ch.active).map(fi => (
					<MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "45%", outline: "none" }}>
					  <MDInput   
                // style={{outline: "none", border: "none"}}
                id={fi.name} 
                type={fi.type}
                value={fi.val}
                disabled={true} 
                onChange={(e) => console.log(e)}
                label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                variant="standard" fullWidth />
					  </MDBox>
            ))} */}


      </div>
      <MDBox mb={1} style={{ marginTop: windowSize.innerWidth < 1300 ? "50px" : "70px", display: "flex", gap: "20px", justifyContent: "center", flexWrap: "wrap" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => { previousSetup() }} variant="outlined" color={darkMode ? "white" : themeColor} fullWidth>
          Previous
        </MDButton>
        {/* <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={false} onClick={()=> {modelSetup()}} variant="gradient" color={themeColor} fullWidth>
              Model Setup
            </MDButton> */}
        <MDButton
          style={{ width: "max-content", minWidth: "140px" }} disabled={false}
          onClick={() => { navigate(`/patterns/${id}/${name}/agent-creation/infer/predictive-analysis`, { state: { ...location?.state } }) }}
          variant="gradient" color={themeColor} fullWidth>
          Inference
        </MDButton>

      </MDBox>
    </div>
  );
};

export default PredictiveAnalysisResult;

