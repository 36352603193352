import * as React from 'react';
import { useState, useEffect, useMemo } from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import MDBox from "components/MDBox";
import Box from '@mui/material/Box';
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import MDInput from "components/MDInput";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import Collapse from '@mui/material/Collapse';

import { FaEdit, FaDownload } from "react-icons/fa";
import { MdDelete, MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

import Constants from "libraries/Constants";
import colors from "assets/theme-dark/base/colors";
import { useMaterialUIController } from "context";
import { Code } from 'react-content-loader';
import Modal from "examples/Modal/Modal";
import useScreenWidth from "libraries/ScreenSizeHook";
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, TextField, InputAdornment, Autocomplete } from '@mui/material';
import { Tooltip } from 'examples/Tooltip/Tooltip';
import { getColorVlue } from 'services/Utils';
import Methods from "libraries/CommonMethodsUI";
import SearchIcon from '@mui/icons-material/Search';
import MDTypography from 'components/MDTypography';
import { InputFieldComponent } from "examples/InputField/InputField";

export default function MDTablePredictiveModels({ columns, widthhandle, selectedRow, onRowSelection }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modelFeatureList, setModelFeatureList] = useState([]);
  const [featuresLoader, setFeaturesLoader] = useState(true);
  const [modelLoader, setModelLoader] = useState(true);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, agentName: '', patternId: '', id: '' });
  const [delLoader, setDelLoader] = useState(false);
  const [controller] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const [windowSize, getWindowSize] = useScreenWidth();
  const [open, setOpen] = useState(false);
  const [collapseRow, setCollapseRow] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [outputFileSize, setOutputFileSize] = useState("")
  const [documentId, setDocumentId] = useState("")
  const [fileName, setFileName] = useState("")


  const promptPreValue = 'You are a label extraction expert. Use the context and feature list provided to extract labels from the given text. Make no mistakes.';
  const [createLoader, setCreateLoader] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState("")
  const [featureModelId, setFeatureModelId] = useState(
    localStorage.getItem("featureModelId")?.length ? Number(JSON?.parse(localStorage.getItem("featureModelId") || "")?.id) : 0
  );
  const [createUpdateNewModel, setCreateUpdateNewModel] = useState({
    modelName: '', modelType: '', algorithm: '', evaluationMetrics: [], trainingDataPercent: 80, validationDataPercent: 10, testingDataPercent: 10
  });
  const { modelName, modelType, algorithm, evaluationMetrics, trainingDataPercent, validationDataPercent, testingDataPercent } = createUpdateNewModel || {};
  const location = useLocation();
  const { state } = location;
  const [dropdownValues, setDropdownValues] = useState({
    modelType: (modelType !== undefined && modelType) ? modelType : '',
    algorithm: (algorithm !== undefined && algorithm) ? modelType : '',
    evaluationMetrics: (evaluationMetrics !== undefined && evaluationMetrics) ? evaluationMetrics : []
  });
  const [featureModelName, setFeatureModelName] = useState();
  const [docId, setDocId] = useState([]);
  const [isHover, setIsHover] = useState();
  const [modelNames, setModelNames] = useState([]);
  const [modelList, setModelList] = useState([]);

  const nameArr = ['classification', 'regression'];

  const algorithmClassificationArr = [
    { id: 1, title: "Logistic Regression (Classification)", apiName: "logisticRegression" },
    { id: 2, title: "Random Forest (Classification)", apiName: "randomForest" },
    { id: 3, title: "Decision Tree (Classification)", apiName: "decisionTree" },
    { id: 4, title: "K Nearest Neighbours (Classification)", apiName: "knn" },
    { id: 5, title: "Support Vector Machine (Classification)", apiName: "svm" },
  ];
  const algorithmRegressionArr = [
    { id: 1, title: "Linear Regression (Regression)", apiName: "linearRegression" },
    { id: 2, title: "Random Forest Regression (Regression)", apiName: "randomForestRegression" },
    { id: 3, title: "Decision Tree Regression (Regression)", apiName: "decisionTreeRegression" },
    { id: 4, title: "XGBoost Regression (Regression)", apiName: "xgboost" },
    { id: 5, title: "K-Means Regression (Regression)", apiName: "kmeans" },
  ];
  const metricsClassificationArr = ['r2', 'rmse', 'mse'];
  const metricsRegressionArr = ['accuracy', 'precision', 'recall'];

  useEffect(() => {
    setModelLoader(true);
    // Feature Extraction Model List
    featurePerdictiveModel();
    // Feature Model List
    featureDropdowns();
    // reset Selected Modal
    localStorage.setItem("featureModelId", "");
    // setFeatureModelId(0);

    // allModelsList();
  }, []);

  const allModelsList = () => {
    ApiRequest.listModels('', id, (res) => {
      if (res?.isSuccess) {

        console.log("Predictive models res", res)
        const name = res?.data?.patternModels?.map(pl => { return pl?.modelName });
        const completeList = res?.data?.patternModels?.map(pl => {
          return {
            title: pl?.permission === 0 ? pl?.displayName : `${pl?.displayName} (${pl?.planName} Plan)`,
            apiName: pl?.modelName,
            default: pl?.default
          }
        }
        );
        setModelNames(name);
        setModelList(completeList);
        setCreateUpdateNewModel({ ...createUpdateNewModel, modelType: modelType ?? completeList?.filter(pl => pl?.default)?.[0]?.apiName });
      } else {
        setModelNames([]); setModelList([]);
      }

    })
  }

  const fields = [
    {
      id: 1,
      placeholder: "Predictive Analysis Model*",
      name: "modelType",
      type: "dropdown",
      arr: nameArr,
      isDropdown: true,
      isActive: true,
      mendatory: true
    },
    {
      placeholder: "Model Name", name: "modelName",
      isActive: true,
      val: modelName, type: "text", isDropdown: false,
      information: "Unique name for the model",
      mendatory: true
    },
    {
      id: 2,
      placeholder: "Algorithm*",
      name: "algorithm",
      type: "dropdown",
      arr: algorithmClassificationArr.map((it) => it.apiName),
      isDropdown: true,
      isActive: dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase(),
    },
    {
      id: 3,
      placeholder: "Algorithm*",
      name: "algorithm",
      type: "dropdown",
      arr: algorithmRegressionArr.map((it) => it.apiName),
      isDropdown: true,
      isActive: dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase(),
    },
    {
      id: 4,
      placeholder: "Evaluation*",
      name: "evaluationMetrics",
      val: 0.85,
      type: "dropdown",
      arr: metricsClassificationArr,
      isDropdown: true,
      isActive: dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase(),
    },
    {
      id: 4,
      placeholder: "Evaluation*",
      name: "evaluationMetrics",
      val: 0.85,
      type: "dropdown",
      arr: metricsRegressionArr,
      isDropdown: true,
      isActive: dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase(),
    },

    {
      placeholder: "Training Data Percentage", name: "trainingDataPercent",
      isActive: (dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase() ||
        dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase()),
      val: trainingDataPercent, type: "number", isDropdown: false,
      information: "Proportion of the total dataset allocated for training",
      mendatory: false
    },
    {
      placeholder: "Validation Data Percentage", name: "validationDataPercent",
      isActive: (dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase() ||
        dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase()),
      val: validationDataPercent, type: "number", isDropdown: false,
      information: "Proportion of the total dataset for validation during the training",
      mendatory: true
    },
    {
      placeholder: "Testing Data Percentage", name: "testingDataPercent",
      isActive: (dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase() ||
        dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase()),
      val: testingDataPercent, type: "number", isDropdown: false,
      information: "Proportion of the total dataset reserved for evaluating the final performance of a trained model",
      mendatory: true
    },
  ];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handlePrevPage = () => {
    setPage((prev) => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setPage((prev) => (prev + 1) * rowsPerPage < modelFeatureList?.length ? prev + 1 : prev);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const startItemIndex = page * rowsPerPage + 1;
  const endItemIndex = Math.min((page + 1) * rowsPerPage, modelFeatureList?.length);

  const statusCheck = (status) => {
    if (status === Constants.CONFIGURED) return "#f94449"
    if (status === Constants.DEPLOYED) return "black"
    if (status === Constants.MODIFIED) return "#c30010"
    if (status === Constants.TRAINING) return "rgb(123, 128, 154)"
    if (status === Constants.DEPLOYING) return "royalblue"
    if (status === Constants.READY) return "#43a047"
    if (status === "TRAINED") return "#A020F0"
    else null
  };
  // const updatedFilename = location.state?.uploadedFile?.filter((fl) => fl?.fileName?.split('_')?.[0] === JSON?.parse(localStorage.getItem("featureModelId") || "")?.name)?.[0]?.fileName;


  const featureDropdowns = () => {
    ApiRequest.patternList(`/${location?.state?.id}`, '', (res) => {
      setModelLoader(false);
      if (res.isSuccess) {
        const updateModel = res?.data?.patternLearnMore?.[0]?.models?.split(',')?.map((cc, i) => {
          // return {
          //   title: cc, apiName: Constants.predictiveModelLabels(cc),
          //   id: i + 1, information: ""
          // }
          Constants.predictiveModelLabels(cc)
        })
        setModelList(updateModel ? updateModel : []);
      };
    });
  };

  const featurePerdictiveModel = () => {
    setFeaturesLoader(true);
    ApiRequest.agentList(`/${location?.state?.objectId}`, '', (res) => {
      if (res.isSuccess) {
        setModelFeatureList(res?.data?.agentsInformation?.[0]?.models);
        setDocId(res?.data?.agentsInformation?.[0]?.documentDetails);
        const outputDetails = res?.data?.agentsInformation?.[0]?.outputDocumentDetails?.map((item) => item)
        setFile(outputDetails)
      } else {
        setModelFeatureList([]);
      }
      setFeaturesLoader(false);
    });
  };

  const deleteModal = () => {
    setDelLoader(true);

    ApiRequest.deleteModelPredictiveAgent({ modelName: modalDelOpen?.modelName }, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, modelName: '' }); setDelLoader(false);
      featurePerdictiveModel();
      if (res.isSuccess) toast.success(res?.data?.message)
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  };

  const openNewModal = (updatingModal) => {
    var updateData = updatingModal;

    // updateData = {
    //   ...updatingModal, trainingDataPercent: 80,
    //   validationDataPercent: 10, testingDataPercent: 10
    // }

    setModalOpen(true); setIsUpdateModal(updatingModal?.modelName ? true : false)
    setCreateUpdateNewModel(updateData !== undefined && updateData?.dateCreated ? updateData :
      {
        modelName: '', modelType: '', algorithm: '', evaluationMetrics: [],
        trainingDataPercent: 80, validationDataPercent: 10, testingDataPercent: 10
      });

    setDropdownValues({
      modelType: updateData && updateData !== undefined ? updateData?.modelType : '',
      algorithm: updateData && updateData !== undefined ? updateData?.algorithm : '',
      evaluationMetrics: updateData && updateData !== undefined ? updateData?.evaluationMetrics : []
    });
  };


  const createUpdateModel = () => {
    var updatedData = {};
    const data = {
      modelName: modelName,
      modelType: dropdownValues?.modelType,
      algorithm: dropdownValues?.algorithm,
      evaluationMetrics: dropdownValues?.evaluationMetrics,
    }

    var nerValues = {
      trainingDataPercent: Number(trainingDataPercent),
      validationDataPercent: Number(validationDataPercent),
      testingDataPercent: Number(testingDataPercent)
    }

    if (dropdownValues.modelType?.toLowerCase() === Constants.predictiveClassification?.toLowerCase() ||
      dropdownValues.modelType?.toLowerCase() === Constants.predictiveRegression?.toLowerCase()) {
      updatedData = { ...data, ...nerValues };
    }


    if (!modelName || !dropdownValues?.modelType || !dropdownValues?.algorithm || !dropdownValues?.evaluationMetrics ||
      !trainingDataPercent || !validationDataPercent || !testingDataPercent
    ) {
      toast.error("Please fill all required fields");
    } else {
      setCreateLoader(true);
      ApiRequest.createUpdateModelPredictiveAgent(updatedData, location?.state?.objectId, isUpdateModal, (res) => {
        setCreateLoader(false);
        if (res.isSuccess) {
          setModalOpen(false);
          toast.success(res?.data?.message);
          featurePerdictiveModel();
        } else {
          toast.error(res?.error?.message ?? "Something Went Wrong")
        }
      });
    }
  };
  const saveToBucket = (fileData) => {

    const modalName = fileData?.modelName
    const fileToDownload = file.find((file => (file.fileName.split('-')[0]) === modalName))
    ApiRequest.inferBucketSaveFeatureAgent({
      documentId: fileToDownload?._id,
      modelName: modalName,
      inputOrOutput: "output",
    },
      `${location?.state?.objectId}`, (res) => {
        if (res.isSuccess) {
          Methods.downloadCSV(res?.data, fileToDownload?.fileName);
          toast.success(res?.data?.message ? res?.data?.message : "Inference saved successfully");
        } else {
          toast.error("Inference failed")
        }
      });
  }
  const getFile = (modelName) => {
    const fileData = file.find((file => (file.fileName.split('-')[0]) === modelName))
    return fileData?.fileSize ? convertBytesToMB(fileData.fileSize) : null;
  }

  const handleChange = (value) => {
    setFeatureModelName(typeof value === 'string' ? value.split(',') : value);
  };
  const convertBytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(3);

  const handleModelNameChange = (e) => {
    const validateModelName = e.target.value.replace(/[\s-]/g, '');
    setCreateUpdateNewModel(prevState => ({
      ...prevState,
      modelName: validateModelName
    }));
  };

  const handleSearchChange = (event, selectedModel) => {
    setSearchTerm(selectedModel ? selectedModel.modelName : ""); // Set the selected model's name directly
  };

  const handleInputChange = (event, newInputValue) => {
    setSearchTerm(newInputValue); // Update search term when typing
  };

  const filteredModels = modelFeatureList?.filter(model =>
    model?.modelName?.toLowerCase()?.includes((searchTerm || '')?.toLowerCase())
  );

  const CommonIcons = (row, i) => {
    const outPutFileSizeMB = getFile(row?.modelName);
    return (
      <div className="flex flex-row" key={i} >
        {outPutFileSizeMB !== null && (
          <FaDownload
            style={{ color: darkMode ? "white" : "black" }}
            onClick={(e) => {
              e.stopPropagation();
              saveToBucket(row);
            }}
            className="cursor-pointer"
          />
        )}

        <FaEdit
          style={{ color: darkMode ? "white" : "black" }}
          onClick={(e) => {
            e.stopPropagation(); openNewModal(row)
          }}
          className="ml-2 cursor-pointer"
        />

        <MdDelete
          style={{ color: darkMode ? "white" : "black" }}
          onClick={(e) => {
            e.stopPropagation(); setModalDelOpen({ flag: true, modelName: row?.modelName })
          }}
          className={`ml-2 cursor-pointer`}
        />

        <Tooltip uniqueId={`predictiveModal-${i}`}
          color={getColorVlue(themeColor)}
          isPattern={true}
          text={<>
            <div style={{ textAlign: "center" }} className="text-lg font-semibold">
              {row?.modelType === "classification" ? `Classification` : 'Regression'}
            </div>
            <div>
              {row?.evaluationMetrics?.length ? <><div style={{ fontWeight: "500" }}>Evaluation Metrics</div>
                {row?.evaluationMetrics?.join(',')}</> : ''}
            </div>
            <div>
              {row?.algorithm ? <><div style={{ fontWeight: "500" }}>Algorithm</div>
                {Constants.predictiveModelLabels(row?.algorithm, true)?.toString()}</> : ''}
            </div>
            <div>
              {row?.numberOfIteration ? <><div style={{ fontWeight: "500" }}>Number Of Iteration</div>
                {row?.numberOfIteration}</> : ''}
            </div>
            <div>
              {row?.dropoutPercent ? <><div style={{ fontWeight: "500" }}>Dropout Percent</div>
                {row?.dropoutPercent}</> : ''}
            </div>
            <div>
              {row?.trainingDataPercent ? <><div style={{ fontWeight: "500" }}>Training Data Percent</div>
                {row?.trainingDataPercent}</> : ''}
            </div>
            <div>
              {row?.validationDataPercent ? <><div style={{ fontWeight: "500" }}>Validation Data Percent</div>
                {row?.validationDataPercent}</> : ''}
            </div>
            <div>
              {row?.testingDataPercent ? <><div style={{ fontWeight: "500" }}>Testing Data Percent</div>
                {row?.testingDataPercent}</> : ''}
            </div>
            <div className="tableCellWrap">
              {row?.openAIKey ? <><div style={{ fontWeight: "500" }}>OpenAI Key</div>
                {row?.openAIKey}</> : ''}
            </div>
            <div>
              {row?.prompt ? <><div style={{ fontWeight: "500" }}>System Prompt</div>
                {row?.prompt}</> : ''}
            </div>
          </>} />
      </div>
    )
  }

  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 10px", width: "100%", height: "calc(100vh - 397px)" }}>
      <MDBox style={{ border: windowSize.innerWidth > 600 ? "1px solid #C4C5C6" : "", borderRadius: "8px", height: "100%" }}>
        <MDBox style={{ display: "flex", padding: "20px", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
          <div>
            <MDTypography style={{ fontSize: "18px", fontWeight: "700", color: darkMode ? "white" : "#101828" }}>Model</MDTypography>
          </div>
          <MDBox mb={1} style={{ display: "flex", justifyContent: "end" }}>
            <div className='flex gap-[10px] flex-wrap'>
              <Autocomplete
                options={filteredModels || []}
                getOptionLabel={(option) => option.modelName || ''}
                onInputChange={handleInputChange}
                onChange={handleSearchChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon style={{ color:darkMode ? "white" : '#344054', width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                      sx: {
                        height: '44px',
                        borderRadius: '8px',
                        '& .MuiOutlinedInput-root': {
                          borderColor: '#D0D5DD',
                          '&:hover fieldset': {
                            borderColor: '#D0D5DD',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#D0D5DD',
                          },
                        },
                        '& .MuiInputBase-input': {
                          fontSize: '14px',
                          color:darkMode ? "white" : '#344054',
                          '&::placeholder': {
                            color:darkMode ? "white" : '#344054',
                            opacity: 1,
                          },
                        },
                        '& .MuiAutocomplete-endAdornment': {
                          display: 'none',
                        },
                      },
                    }}
                  />
                )}
                style={{ width: '200px' }}
              />
              <MDButton style={{ width: "max-content", minWidth: "140px", fontSize: "14px", fontWeight: "500" }}
                disabled={false} onClick={() => { openNewModal() }} variant="gradient" color={themeColor} fullWidth>
                Create New Model
              </MDButton>
            </div>
          </MDBox>
        </MDBox>

        {/* ==== Header */}

        <div style={{ display: 'flex', alignItems: "center", flexWrap: 'wrap', backgroundColor: darkMode ? "transparent" : "#C5D7FF", height: "45px" }}>
          <div
            style={{
              width: windowSize.innerWidth > 600 ? '20%' : '50%',
              fontWeight: '500',
              fontSize: '12px',
              color: darkMode ? 'white' : '#314B87',
              paddingLeft: windowSize.innerWidth <= 600 ? '20px' : '16px',
            }}
          >
            Model
          </div>
          <div
            style={{
              width: windowSize.innerWidth > 600 ? '15%' : '50%',
              fontWeight: '500',
              fontSize: '12px',
              color: darkMode ? 'white' : '#314B87',
            }}
          >
            Created Date
          </div>
          {windowSize.innerWidth > 600 && <>
            <div
              style={{
                width: '15%',
                fontWeight: '500',
                fontSize: '12px',
                color: darkMode ? 'white' : '#314B87',
              }}
            >
              Modified Date
            </div>
            <div
              style={{
                width: '10%',
                fontWeight: '500',
                fontSize: '12px',
                color: darkMode ? 'white' : '#314B87',
              }}
            >
              Type
            </div>
            <div
              style={{
                width: '15%',
                fontWeight: '500',
                fontSize: '12px',
                color: darkMode ? 'white' : '#314B87',
              }}
            >
              Status
            </div>
            <div
              style={{
                width: '10%',
                fontWeight: '500',
                fontSize: '12px',
                color: darkMode ? 'white' : '#314B87',
              }}
            >
              Output File Size
            </div>
            <div
              style={{
                fontWeight: '500',
                width: '15%',
                fontSize: '12px',
                color: darkMode ? 'white' : '#314B87',
                display: "flex",
                justifyContent: "center"
              }}
            >
              Action
            </div>
          </>
          }
        </div>

        <Paper sx={{ height: "calc(100vh - 538px)", width: '100%', overflow: 'hidden',position:"relative", background: darkMode ? colors?.background?.card : "white", boxShadow: "none" }}>
          <TableContainer sx={{ height: "100%", boxShadow: "none", border: "none",overflowY: 'auto', overflowX: 'hidden' }}>
            <div style={{ height: "calc(100vh - 590px)", overflowY: "auto" }}>
              <Table sx={{ width: '100%' }}>
                <TableBody>

                  {/* Body List  */}
                  {featuresLoader ?
                    <div className="w-full flex mt-2" style={{ margin: "16px" }}>
                      {[1, 2, 3].map(() => (
                        <div className="w-56 mr-8">
                          <Code backgroundColor="grey" foregroundColor="darkgrey" />
                        </div>
                      ))}
                    </div>
                    :
                    filteredModels?.length ?
                      <>
                        {filteredModels
                          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          ?.map((row, i) => {
                            return (
                              <>
                                <TableRow tabIndex={-1}>
                                  <TableCell key={i} align={"left"}
                                    style={{
                                      padding: "12px 0px",
                                      // display: "flex", alignItems: "center", flexDirection: "row",
                                      color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px",
                                      width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[0]}%`
                                    }}>
                                    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
                                      {
                                        windowSize.innerWidth <= 600 &&
                                        <IconButton
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => { setOpen(!open); setCollapseRow(i + 1) }}
                                        >
                                          {(open && (collapseRow === (i + 1))) ?
                                            <KeyboardArrowUpIcon style={{ color: darkMode ? "white" : "black" }} />
                                            : <KeyboardArrowDownIcon style={{ color: darkMode ? "white" : "black" }} />}
                                        </IconButton>
                                      }
                                      {
                                        <Checkbox checked={selectedRow?.modelName === row?.modelName}
                                          color={themeColor}
                                          icon={<MdCheckBoxOutlineBlank style={{ fontSize: "20px", borderRadius: "6px", color: darkMode ? "white" : "black" }} />}
                                          checkedIcon={darkMode ? <MdOutlineCheckBox style={{ fontSize: "20px", borderRadius: "6px", color: "white" }} /> : <MdCheckBox style={{ fontSize: "20px", borderRadius: "6px" }} />}
                                          onClick={() => { onRowSelection(i, row, docId); }} />
                                      }
                                      {row?.modelName}{row?.fileSize && ` (${convertBytesToMB(row?.fileSize)} mbs)`}
                                    </div>
                                  </TableCell>
                                  <TableCell key={i} align={"left"}
                                    style={{ padding: windowSize.innerWidth > 600 ? "12px 0px " : "12px 10px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: windowSize.innerWidth <= 600 ? "50%" : `${widthhandle[1]}%` }}>
                                    {row?.dateCreated?.split('T')?.[0]}
                                  </TableCell>
                                  {windowSize.innerWidth > 600 && <>
                                    <TableCell key={i} align={"left"}
                                      style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[2]}%` }}>
                                      {row?.dateModified?.split('T')?.[0]}
                                    </TableCell>

                                    <TableCell key={i} align={"left"}
                                      style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[3]}%` }}>
                                      {row?.modelType === "classification" ? `Classification` : 'Regression'}
                                    </TableCell>
                                    <TableCell key={i} align={"left"}
                                      style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[4]}%` }}>
                                      <div className="px-2 rounded-md text-center text-white w-24"
                                        style={{ background: statusCheck(row?.modelStatus?.toUpperCase()) }}>
                                        {row?.modelStatus?.charAt(0) + row?.modelStatus?.substring(1)?.toLowerCase()}
                                      </div>
                                    </TableCell>
                                    <TableCell key={i} align={"left"}
                                      style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[5]}%` }}>
                                      {getFile(row?.modelName) !== null ? `${getFile(row?.modelName)} mbs` : " --"}
                                    </TableCell>

                                    <TableCell key={i} align={"left"} style={{ padding: windowSize.innerWidth > 1500 ? "12px 43px" : "12px", width: `${widthhandle[6]}%` }}>

                                      {CommonIcons(row, i)}
                                    </TableCell>
                                  </>
                                  }

                                </TableRow>

                                {/* Extended Row */}
                                <TableRow>
                                  <TableCell style={{ padding: 0 }} colSpan={6}>
                                    <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                      <Box sx={{ margin: 1 }}>

                                        {/* Headings outside the table in a div */}
                                        <div style={{ display: 'flex', width: '100%', backgroundColor: "#C5D7FF", padding: "10px", height: "44px" }}>
                                          {/* Div for Modified Date heading */}
                                          <div style={{ width: '50%', padding: "5px", fontWeight: "500", fontSize: "12px", color: darkMode ? 'white' : '#314B87' }}>
                                            Modified Date
                                          </div>

                                          {/* Div for Type heading */}
                                          <div style={{ width: '50%', padding: "5px", fontWeight: "500", fontSize: "12px", color: darkMode ? 'white' : '#314B87' }}>
                                            Type
                                          </div>
                                        </div>

                                        {/* Table for data only */}
                                        <Table>
                                          <TableBody>
                                            <>
                                              <TableRow tabIndex={-1}>
                                                {/* Data cell for Modified Date */}
                                                <TableCell key={i} align={"left"}
                                                  style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: '50%' }}>
                                                  {row?.dateModified?.split('T')?.[0]}
                                                </TableCell>

                                                {/* Data cell for Type */}
                                                <TableCell key={i} align={"left"}
                                                  style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: '50%' }}>
                                                  {row?.modelType === "classification" ? `Classification` : 'Regression'}
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell style={{ padding: 0 }} colSpan={6}>
                                    <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                      <Box sx={{ margin: 1 }}>
                                        <Table>
                                          <MDBox component="thead">
                                            <TableRow sx={{ padding: "0px", backgroundColor: "#C5D7FF", height: "44px" }}>

                                              <TableCell
                                                // key={column.id}
                                                align={"left"}
                                                style={{ fontWeight: "500", color: darkMode ? 'white' : '#314B87', fontSize: "12px" }}
                                              >
                                                Status

                                              </TableCell>
                                              <TableCell
                                                // key={column.id}
                                                align={"left"}
                                                style={{ fontWeight: "500", color: darkMode ? 'white' : '#314B87', fontSize: "12px" }}
                                              >
                                                Output File Size
                                              </TableCell>
                                            </TableRow>
                                          </MDBox>
                                          <TableBody >
                                            <>
                                              <TableRow tabIndex={-1}>
                                                <TableCell key={i} align={"left"}
                                                  style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[4]}%` }}>
                                                  <div className="px-2 rounded-md text-center text-white w-20"
                                                    style={{ background: statusCheck(row?.modelStatus?.toUpperCase()) }}>
                                                    {row?.modelStatus?.charAt(0) + row?.modelStatus?.substring(1)?.toLowerCase()}
                                                  </div>
                                                </TableCell>
                                                <TableCell key={i} align={"left"}
                                                  style={{ padding: "12px 0px", color: darkMode ? "white" : "black", fontSize: windowSize.innerWidth > 1500 ? "14px" : "11px", width: `${widthhandle[5]}%` }}>
                                                  {getFile(row?.modelName) !== null ? `${getFile(row?.modelName)} mbs` : " --"}
                                                </TableCell>
                                              </TableRow>
                                            </>

                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ padding: 0 }} colSpan={6}>
                                    <Collapse in={open && (collapseRow === i + 1)} timeout="auto" unmountOnExit>
                                      <Box sx={{ margin: 1 }}>
                                        <Table>
                                          <MDBox component="thead">
                                            <TableRow sx={{ padding: "0px", backgroundColor: "#C5D7FF", height: "44px" }}>

                                              <TableCell
                                                // key={column.id}
                                                align={"left"}
                                                style={{ fontWeight: "500", color: darkMode ? 'white' : '#314B87', fontSize: "12px" }}
                                              >
                                                Action

                                              </TableCell>
                                            </TableRow>
                                          </MDBox>
                                          <TableBody >
                                            <>
                                              <TableRow tabIndex={-1}>
                                                <TableCell key={i} align={"left"} style={{ padding: windowSize.innerWidth > 1500 ? "12px 43px" : "12px", width: `${widthhandle[6]}%` }}>

                                                  {CommonIcons(row, i)}
                                                </TableCell>
                                              </TableRow>
                                            </>

                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </>
                      : <TableRow>
                        <TableCell colSpan={windowSize.innerWidth > 600 ? 8 : 4} style={{ textAlign: "center", borderBottom: "none" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "160px",
                              width: "100%",
                            }}
                          >
                            <MDTypography style={{
                              fontSize: "14px",
                              color: darkMode ? "white" : "#667085",
                              marginTop: "50px"
                            }}>
                              No data Available. Create a new model!
                            </MDTypography>
                          </div>
                        </TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </div>
            
            <div style={{ width: "100%", position: "absolute", bottom: "3px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0px 20px" }}>
              <div style={{ fontSize: "12px", fontWeight: "500", color: darkMode ? "white" : "#344054" }}>
                {modelFeatureList?.length > 0
                  ? `${startItemIndex} - ${endItemIndex} of ${modelFeatureList?.length}`
                  : `0-0 of 0`} items
              </div>
              <div style={{ display: "flex", gap: "20px", marginBottom: "10px" }}>
                <MDButton isNoWidth={true} size="medium" onClick={handlePrevPage} disabled={page === 0} variant="outlined" color={darkMode ? "white" : themeColor} >
                  Previous
                </MDButton>
                <MDButton isNoWidth={true} size="medium" onClick={handleNextPage} disabled={(page + 1) * rowsPerPage >= modelFeatureList?.length} variant="outlined" color={darkMode ? "white" : themeColor}>
                  Next
                </MDButton>
              </div>
            </div>

          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            style={{ color: darkMode ? "white" : "black" }}
            count={modelFeatureList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}


          {modalDelOpen?.flag &&
            <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, modelName: '' })}>
              <div className="flex flex-row w-full justify-center items-center mb-4" >
                Are you sure you want to delete? Deleted model will be lost
              </div>

              <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                <MDButton style={{ width: "140px" }} disabled={false}
                  onClick={() => setModalDelOpen({ flag: false, modelName: '' })}
                  variant="gradient" color={"error"} fullWidth>
                  No
                </MDButton>

                <MDButton style={{ width: "140px" }} disabled={false}
                  onClick={() => deleteModal()}
                  variant="gradient" color={themeColor} fullWidth>
                  Yes
                </MDButton>
              </MDBox>
            </Modal>
          }

        </Paper>
      </MDBox>

      {/* Modals */}
      {
        modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)} isApikey={true}>
          <div style={{ zIndex: 999, minHeight: "300px", padding: "20px" }}>

            <MDTypography style={{ fontSize: "18px", color: darkMode ? "white" : "#314B87", fontWeight: "700", paddingBottom: "10px", borderBottom: "1px solid #D9DBE9" }}> {!isUpdateModal ? 'Creating' : 'Updating'} Model </MDTypography>

            <div className="w-[100%] flex flex-row flex-wrap"
              style={{ justifyContent: "space-between", padding: "30px 0px", minHeight: "150px" }}>
              {fields?.filter((fl) => fl?.isActive).map(fi => (
                <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : fi?.name === "testingDataPercent" ? "100%" : "48%", outline: "none", display: "flex", alignItems: "center", paddingBottom: "20px" }}>
                  {
                    fi.type === "dropdown" ?
                      <MDDropdown
                        dropdownName={fi?.placeholder}
                        nameArr={fi?.arr} isMultiple={fi?.name === 'evaluationMetrics'}
                        backSelect={(val) => {
                          if (fi?.name === "modelType") {
                            setDropdownValues({
                              ...dropdownValues,
                              [fi?.name]: typeof val === 'string' ? val.split(',')?.[0] : val,
                              algorithm: '', evaluationMetrics: []
                            })
                          } else {
                            setDropdownValues({ ...dropdownValues, [fi?.name]: typeof val === 'string' ? val.split(',')?.[0] : val })
                          }
                        }}
                        personName={[dropdownValues?.[fi?.name]]?.length ? dropdownValues[fi?.name] : ''} />
                      :
                      <InputFieldComponent
                        fi={fi}
                        disabled={fi.name === 'modelName' && isUpdateModal}
                        config={createUpdateNewModel}
                        handleChange={fi.name === 'modelName' ? handleModelNameChange : (e) => {
                          setCreateUpdateNewModel(prevState => ({ ...prevState, [fi.name]: e.target.value }));
                        }}
                      />
                  }
                </MDBox>
              ))}
            </div>
            <div className='flex justify-center items-center mt-[50px]'>
              <MDButton style={{ width: "max-content", minWidth: "140px" }}
                disabled={false} onClick={() => { createUpdateModel() }} variant="gradient"
                color={themeColor} fullWidth>
                {!isUpdateModal ? 'Create Model' : 'Update Model'}
              </MDButton>

            </div>
          </div>
        </Modal>
      }
    </div >
  );
}
