import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ApiRequest from "services/ApiRequest";
import { Checkbox, Grid, OutlinedInput, IconButton, InputAdornment, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import MDDropdown from "components/MDDropdown";
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import AddBoxIcon from '@mui/icons-material/AddBox';

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
// import { Paths } from "libraries/Route";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import { useMaterialUIController } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { getColorVlue } from 'services/Utils';
import { validateAndProcessFile, getFileType, validateFileTypes } from "services/Utils";
//actions
import { isSqlToolsAction } from "redux/Action"


//images
import sqlDatabaseIcon from "assets/images/flow-icons/sql-icon.svg"
import MDTypography from "components/MDTypography";
import { Tag } from "examples/Tag/Tag";
import { TabUpload } from "examples/TabUpload/TabUpload";
import Modal from "examples/Modal/Modal";
import { InputFieldComponent } from "examples/InputField/InputField";
import { IoIosSquareOutline } from "react-icons/io";
import { FunctionAsTool } from "layouts/agent-creation-flow/chatbot/functionAsTool";
import { GoogleSearchTool } from "layouts/agent-creation-flow/chatbot/googleSearchTool";
import { gptModelName } from "libraries/Enums";
import { TabsCustomized } from "examples/TabsCustomized/TabsCustomized";
import { Toggle } from "examples/Toggle/Toggle";
import Constants from "libraries/Constants";



function ChatbotTool() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();
  const [loader, setLoader] = useState(false);
  const [windowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [sqlLoader, setSqlLoader] = useState(false)
  const [sqlformData, setSqlFormData] = useState({})
  const [modelName, setModelName] = useState("")
  const dispatch = useDispatch()
  const isSqlTools = useSelector((state) => state?.loginReducer?.isSqlTool);
  const isLargeLoptop = useMediaQuery('(min-width: 1300px) and (max-width: 1500px)');
  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1199px)');
  const isloptop = useMediaQuery('(min-width: 1200px) and (max-width: 1295px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)')
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)')
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)')
  const [promptQuery, setPromptQuery] = useState([{ promptValue: "", sqlQuery: "" }]);
  const [sslFile, setSslFile] = useState(null)
  const [sslFilename, setSslFilename] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState('')
  const [allowFileDeleted, setAllowFileDeleted] = useState(false)
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [selectedUrls, setSelectedUrls] = useState([]); // State for selected URLs
  const [databaseType, setDatabaseType] = useState('')
  const [functionAsToolEnable, setFunctionAsToolEnable] = useState(false)
  const [googleSearchToolEnable, setGoogleSearchToolEnable] = useState(false)
  const [selectedFunctions, setSelectedFunctions] = useState([]);
  const [sqlToolFlag, setSqlToolFlag] = useState()
  const [functiontoolFlag, setFunctiontoolFlag] = useState()
  const [googletoolFlag, setGoogletoolFlag] = useState()
  const [inputValues, setInputValues] = useState({
    userId: "",
    dataBaseName: "",
    password: "",
    dataBasePort: 0,
    dataBaseServerUrl: "",

  });
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, document: {} });
  const defaultPrompt = `You are an agent designed to interact with a SQL database.
Given an input question, create a syntactically correct {dialect} query to run, then look at the results of the query and return the answer.
Unless the user specifies a specific number of examples they wish to obtain, always limit your query to at most {top_k} results.
You can order the results by a relevant column to return the most interesting examples in the database.
Never query for all the columns from a specific table, only ask for the relevant columns given the question.
You have access to tools for interacting with the database.
Only use the given tools. Only use the information returned by the tools to construct your final answer.
You MUST double check your query before executing it. If you get an error while executing a query, rewrite the query and try again.
DO NOT make any DML statements (INSERT, UPDATE, DELETE, DROP etc.) to the database.
If the question does not seem related to the database, just return "I don't know" as the answer.
Here are some examples of user inputs and their corresponding SQL queries`;
  const [backActiveTab, setBackActiveTab] = useState(0);
  const [searchURLs, setSearchURLs] = useState([]);
  const [googleURLs, setGoogleURLs] = useState(['']);
  const [selectedGoogleUrls, setSelectedGoogleUrls] = useState([]); // State for selected URLs


  const [promptMessage, setPromptMessage] = useState(defaultPrompt);
  const [showPassword, setShowPassword] = useState(false);
  const navigation = (route, page, step, objectId, configForm, isSqlTool) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location?.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        isSqlTool: isSqlTool,
        isTableBox: true,
        protect: location.state.protect,
        isEditAgent: location?.state?.isEditAgent,
        isTrained: page === 4 && route === '/train' ? true : false,
      }
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fields = [
    { id: 1, type: 'dropdown', placeholder: 'Database type', name: 'databaseType', arr: dropdownOptions?.map(option => option?.displayName) || [], mendatory: true, information: "There are two types of database Microsoft SQL Server 2022 and MySQL 8.0" },
    { id: 2, type: 'text', placeholder: 'Database Name', name: "dataBaseName", mendatory: true, information: "Name of the database." },
    { id: 3, type: 'text', placeholder: 'User ID', name: "userId", mendatory: true, information: " User ID which has permissions to connect to the database. NOTE: Please ensure that minimum rights are given to this user, as LLMs can be unstable and may try to execute unexpected or harmful queries. Read only permission are strongly recommended." },
    { id: 4, type: 'password', placeholder: 'Password', name: "password", mendatory: true, information: "Password for the user to connect to the database." },
    { id: 5, type: 'text', placeholder: 'Database Port', name: "dataBasePort", mendatory: true, information: "Port number at which the database runs on." },
    { id: 6, type: 'text', placeholder: 'Database Server URL', name: "dataBaseServerUrl", mendatory: true, information: "URL of the database server." },

  ]
  const fewShotPrompt = promptQuery?.map(query => ({
    input: query?.promptValue,
    query: query?.sqlQuery
  }))

  console.log("sqlLoader", sqlLoader)  
  const saveConfig = (formData) => {
    setSqlLoader(true);
    ApiRequest.sqldatabaseTool(formData, { id: location?.state?.objectId }, (res) => {
      if (res.isSuccess) {
        toast.success(res.data?.message ? res?.data?.message : "Urls fetched successfully");
        setSqlLoader(false);
        if (isSqlTools) {
          dispatch(isSqlToolsAction(true));
        } else {
          dispatch(isSqlToolsAction(false));
        }
        navigation('/protect', 3, 2, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
      } else {
        toast.error(res.error?.message ? res?.error?.message : "Urls fetch failed");
        setSqlLoader(false);
      }
    });

  }

  const updateSaveConfig = (formData) => {
    setSqlLoader(true);
    ApiRequest.updateFunctionTool(formData, { id: location?.state?.objectId }, (res) => {
      if (res.isSuccess) {
        toast.success(res.data?.message ? res?.data?.message : "Urls fetched successfully");
        setSqlLoader(false);
        if (isSqlTools) {
          dispatch(isSqlToolsAction(true));
        } else {
          dispatch(isSqlToolsAction(false));
        }
        navigation('/protect', 3, 2, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
      } else {
        toast.error(res.error?.message ? res?.error?.message : "Urls fetch failed");
        setSqlLoader(false);
      }
    });

  };

  // Common Function for Tools
  const generatePostData = () => {

    return {
      sqlTool: {
        isActive: isSqlTools ? "True" : "False",
        ...(isSqlTools && {
          databaseUserID: inputValues?.userId,
          databaseUserPassword: inputValues?.password,
          databaseName: inputValues?.dataBaseName,
          databaseServerURL: inputValues?.dataBaseServerUrl,
          databasePort: inputValues?.dataBasePort,
          sqlDefaultPrompt: promptMessage,
          databaseType: selectedDatabase,
          fewShotExamples: JSON?.stringify(fewShotPrompt),
          sslCertificate: sslFile,
        }),
      },
      functionTool: {
        isActive: functionAsToolEnable ? "True" : "False",
        ...(functionAsToolEnable && { functionList: selectedFunctions ?? [] }),
      },
      realtimeSearchTool: {
        isActive: googleSearchToolEnable ? "True" : "False",
        ...(googleSearchToolEnable && { searchURLs: searchURLs ?? [] }),
      },
    };
  };

  const sqldatabaseTool = () => {
    const postDataSql = {
      sqlTool: {
        isActive: "True",
        databaseUserID: inputValues?.userId,
        databaseUserPassword: inputValues?.password,
        databaseName: inputValues?.dataBaseName,
        databaseServerURL: inputValues?.dataBaseServerUrl,
        databasePort: inputValues?.dataBasePort,
        sqlDefaultPrompt: promptMessage,
        databaseType: selectedDatabase,
        fewShotExamples: JSON?.stringify(fewShotPrompt),
        sslCertificate: sslFile
      },
      realtimeSearchTool: { isActive: "False" },
      functionTool: { isActive: "False" }
    };

    const postDataFunctionAsTool = {
      functionTool: { isActive: "True", functionList: selectedFunctions ?? [] },
      sqlTool: { isActive: "False" },
      realtimeSearchTool: { isActive: "False" }
    };

    const postDataGoogleSearch = {
      realtimeSearchTool: { isActive: "True", searchURLs: searchURLs ?? [] },
      sqlTool: { isActive: "False" },
      functionTool: { isActive: "False" },
    };

    const postDataForDisable = {
      sqlTool: { isActive: "False" },
      functionTool: { isActive: "False" },
      realtimeSearchTool: { isActive: "False" },
    };

    // const payload = (!isSqlTools && !functionAsToolEnable && !googleSearchToolEnable) ? postDataForDisable 
    //                 : (functionAsToolEnable && !isSqlTools && !googleSearchToolEnable) ? postDataFunctionAsTool 
    //                 : (googleSearchToolEnable && !isSqlTools && !functionAsToolEnable) ? postDataGoogleSearch
    //                 : (isSqlTools && !googleSearchToolEnable && !functionAsToolEnable) ? postDataSql
    //                 : postDataSql;
    const payload = generatePostData();

    if (isSqlTools) {
      const emptyFields = fields?.filter(field => inputValues[field?.name] === "");
      if (emptyFields.length === fields.length) {
        toast.error("Please fill the all required fields");
        return;
      } else if (emptyFields.length > 0) {
        const firstEmptyField = emptyFields[0];
        toast.error(`Please fill in the ${firstEmptyField.placeholder} field`);
        return;
      }
      const undefinedFields = Object?.entries(postDataSql)?.filter(([key, value]) => value === undefined);

      if (undefinedFields.length > 0) {
        const firstUndefinedField = undefinedFields[0];
        toast.error(`Please fill in the ${firstUndefinedField[0]} field`);
        return;
      }
      if (modelName === gptModelName) {
        toast.error("gpt-3.5-turbo-instruct is not compatible with the SQL Tool. Please select a different LLM to continue");
        return;
      }
      if (!promptMessage.includes("{dialect}") && !promptMessage.includes("{top_k}")) {
        toast.error("Please do not remove the {dialect} and {top_k} variables from the system prompt");
        return;
      }
      const invalidPromptQuery = promptQuery.some(query =>
        (query.promptValue && !query.sqlQuery) || (!query.promptValue && query.sqlQuery)
      );
      if (invalidPromptQuery) {
        toast.error("Please fill in both Prompt Input Prompt and SQL Query fields or leave both empty.");
        return;
      }
    }

    const formData = Constants.convertToFormData(payload);
    if (sslFile) {
      formData.append('sslCertificate', sslFile);
    }
    // ====  Google Search Check
    if (googleSearchToolEnable) {

      if (!Constants.validateUrls(searchURLs)) {
        toast.error(`Please fill in proper "Search URL" field`);
        return
      }
    }
    // ====  Function as tool Check
    if (functionAsToolEnable) {
      if (!selectedFunctions?.length) {
        toast.error(`Please select atleast one function as tool`);
        return
      }
    }
    // ===== Hit API

    if ((sqlToolFlag !== undefined) || (functiontoolFlag !== undefined) || (googletoolFlag !== undefined)) {
      updateSaveConfig(formData);
    } else {
      saveConfig(formData);
    }
  }
  const setDatabaseName = (res) => {
    const agentdb = res.data?.agentsInformation?.[0];
    setSqlToolFlag(agentdb?.sqlTool); setFunctiontoolFlag(agentdb?.functionTool); setGoogletoolFlag(agentdb?.realtimeSearchTool?.isActive)
    setGoogleSearchToolEnable(agentdb?.realtimeSearchTool?.isActive)
    setSearchURLs(agentdb?.realtimeSearchTool?.searchURLs ?? [])
    setGoogleURLs(agentdb?.realtimeSearchTool?.searchURLs ?? [])

    const ModelName = res.data?.agentsInformation?.[0]?.configuration?.modelName;
    setModelName(ModelName)
    setSqlFormData({
      userId: agentdb?.databaseUserID,
      dataBaseName: agentdb?.databaseName,
      dataBasePort: agentdb?.databasePort,
      dataBaseServerUrl: agentdb?.databaseServerURL,
    })
    setSslFilename(agentdb?.secretFileName);
    setAllowFileDeleted(agentdb?.sslSecretId)
    const fewShotExamples = agentdb?.fewShotExamples;
    const preloadedPromptQuery = fewShotExamples.map(example => ({
      promptValue: example.input,
      sqlQuery: example.query,
    }));
    setPromptMessage(agentdb?.sqlDefaultPrompt)
    setPromptQuery(preloadedPromptQuery)
    setSelectedDatabase(agentdb?.databaseType)
  };

  const getPreloadedValues = async () => {
    ApiRequest.agentList(`/${location?.state?.objectId}`, '', (res1) => {
      if (res1.isSuccess) {

        ApiRequest.getSqlToolDropDownValues("", (res) => {
          if (res.isSuccess) {
            setDropdownOptions(res?.data?.databases?.map(db => ({
              displayName: db?.databaseType?.displayName,
              databaseName: db?.databaseType?.databaseName,
              defaultPort: db?.databaseType?.defaultPort
            })));
            const dropdownvalue = res?.data?.databases?.map(db => ({
              displayName: db?.databaseType?.displayName,
              databaseName: db?.databaseType?.databaseName,
              defaultPort: db?.databaseType?.defaultPort
            }))
            const selectedOption = dropdownvalue?.find(option => option?.databaseName === res1?.data?.agentsInformation?.[0]?.databaseType);
            const displayName = selectedOption ? selectedOption.displayName : '';
            setSelectedDropdownValue(displayName);
          } else {
            toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
          }
        });
        setDatabaseName(res1)
      }
    })
  };

  useEffect(() => {
    getPreloadedValues()
  }, []);

  useEffect(() => {
    if (sqlformData?.userId !== undefined && sqlformData?.userId !== null && sqlformData?.userId !== "" && sqlformData.databasePort !== 0 && sqlToolFlag) {
      setInputValues(sqlformData)
      dispatch(isSqlToolsAction(true))
    } else {
      dispatch(isSqlToolsAction(false))
    }
  }, [sqlformData]);

  const handleCheckBox = () => {
    dispatch(isSqlToolsAction(!isSqlTools));
    if (!isSqlTools && modelName === gptModelName) {
      toast.error("gpt-3.5-turbo-instruct is not compatible with the SQL Tool. Please select a different LLM to continue");
      return;
    }
    // dispatch(isSqlToolsAction(!isSqlTools))
  };

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location.state?.objectId, location?.state?.configForm, location?.state?.protect);
  };

  const handleInputChange = (index, e, name) => {
    const { value } = e?.target;
    const updatedPromptQueries = [...promptQuery];
    updatedPromptQueries[index][name] = value;
    setPromptQuery(updatedPromptQueries);
  };

  const addUrlInput = () => {
    setPromptQuery([...promptQuery, { promptValue: "", sqlQuery: "" }]);
  };

  const deleteUrlInput = (index) => {
    const updatedPromptQueries = promptQuery?.filter((_, i) => i !== index);
    setPromptQuery(updatedPromptQueries);

    // const updatedURLs = [...scrapedURLs];
    // updatedURLs.splice(index, 1);
    // setScrapedURLs(updatedURLs);

    setSelectedUrls((prevSelected) => prevSelected.filter((i) => i !== index));

    // Adjust selection indices after deletion
    setSelectedUrls((prevSelected) =>
      prevSelected
        .filter((i) => i !== index) // Remove the deleted index
        .map((i) => (i > index ? i - 1 : i)) // Adjust remaining indices
    );
  };

  const uploadedFile = (acceptedFiles) => {
    if (sslFile) {
      toast.error('Please delete the existing SSL certificate before uploading a new one.');
      return;
    }

    const uploadfile = acceptedFiles?.[0];

    const supportedFormats = ['pem']
    if (!validateFileTypes(supportedFormats, uploadfile)) {
      toast.error('Unexpected File Type. We only support SSL certificates.');
      return;
    }

    setSslFile(uploadfile);
    setSslFilename(uploadfile?.name);
    toast.success(`${uploadfile?.name} uploaded successfully.`);
  };

  const deleteDocument = () => {
    if (!allowFileDeleted) {
      setModalDelOpen({ flag: false, document: !sslFile })
      setSslFile(null)
      setSslFilename(null)
      return;
    }
    ApiRequest.sqlDeleteDocumnet(`/${location?.state?.objectId}`, (res) => {
      if (res.isSuccess) {
        setModalDelOpen({ flag: false, document: !sslFile })
        setSslFile(null)
        setSslFilename(null)
        toast.success(res?.data?.message ? res?.data?.message : "Query successful.");
      } else {
        toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
        setModalDelOpen({ flag: false, document: !sslFile })
      }
    });
  };

  const handleDropdownChange = (selectedValue) => {
    setSelectedDropdownValue(selectedValue)
    const selectedDb = dropdownOptions?.find(option => option?.displayName === selectedValue);

    if (selectedDb) {
      setSelectedDatabase(selectedDb?.databaseName);
      setInputValues(prevValues => ({
        ...prevValues,
        dataBasePort: selectedDb?.defaultPort
      }));
    }
  };

  // const deleteAllInputs = () => {
  //   setPromptQuery([]);
  // };

  const handleDeleteSelected = () => {
    setPromptQuery(promptQuery.filter((_, index) => !selectedUrls.includes(index))); // Remove selected URLs
    // setSearchURLs(searchURLs.filter((_, index) => !selectedUrls.includes(index))); // Remove selected URLs
    setSelectedUrls([]); // Clear the selected URLs after deletion
  };

  const handleFunctionSelect = (id) => {

    if (selectedFunctions.includes(id)) {
      setSelectedFunctions(prev => (selectedFunctions.filter((func) => func !== id)));
    } else {
      setSelectedFunctions(prev => ([...selectedFunctions, id]));
    }
  }

  const inputStyled = `${isSmallMobile ? "w-[190px]" : isLargeMobile ? "w-[296px]" : isMdLoptop ? "w-[330px]" : isTablet ? "w-[221px]" : "min-w-[390px]"} 
    rounded-md h-[25px] w-full mt-[20px] text-[#000000] placeholder-gray-300 
    ${darkMode ? 'bg-transparent placeholder:placeholder-gray-300 text-white focus:border-[#ffff]'
      : 'border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-500'}`;


  // const handleSelectAll = () => {
  //   if (selectedUrls.length === promptQuery.length) {
  //     // Deselect all if all are already selected
  //     setSelectedUrls([]);
  //   } else {
  //     // Select all URLs
  //     const allIndexes = promptQuery.map((_, index) => index);
  //     setSelectedUrls(allIndexes);
  //   }
  // };

  const handleSelectAll = () => {
    if (selectedUrls.length === promptQuery.length) {
      setSelectedUrls([]); // Deselect all
    } else {
      setSelectedUrls(promptQuery.map((url, index) => index)); // Select all by using the index correctly
    }
  };

  // const handleCheckboxToggle = (index) => {
  //   setSelectedUrls((prevSelected) => {
  //     const updatedSelected = [...prevSelected]; // Create a copy of the state
  //     if (updatedSelected.includes(index)) {
  //       // Deselect if already selected
  //       return updatedSelected.filter((i) => i !== index);
  //     } else {
  //       // Select the checkbox
  //       return [...updatedSelected, index];
  //     }
  //   });
  // };

  const handleCheckboxToggle = (index) => {
    if (selectedUrls.includes(index)) {
      setSelectedUrls(selectedUrls.filter(i => i !== index)); // Deselect the URL
    } else {
      setSelectedUrls([...selectedUrls, index]); // Select the URL
    }
  };


  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 70px" }}>
      <div>
        {/* <div className={`flex items-center text-[20px] font-semibold ${darkMode ? "text-white" : "text-black"}`}>
          Tools (Optional)
        </div> */}
        <TabsCustomized activeTab={backActiveTab} backActiveTab={(e) => setBackActiveTab(e)} arr={["SQL", "FUNCTION", "GOOGLE SEARCH"]} />
        <div style={{ marginTop: "2px", height: "calc(100vh - 415px)", overflowY: "auto" }}>
          {/* <div className="flex items-center mt-[10px]">
          <div className="flex gap-[10px] items-center ">
            <div className="h-[34px] w-[34px] rounded-full  flex items-center justify-center" style={{ background: getColorVlue(themeColor) }}><img src={sqlDatabaseIcon} alt="" /></div>
            <Typography style={{ color: darkMode ? "white" : "black" }} sx={{ fontWeight: "600", fontSize: "14px" }}>SQL</Typography>

          </div>
          <Tooltip text="This tool can be used to ask questions in natural language from your database.
           Supported databases include: Microsoft SQL Server & MySQL 8.0 " uniqueId="sqlDatabase"
            color={getColorVlue(themeColor)}
          >
          </Tooltip>
        </div> */}
          {/* <div className="flex items-center gap-[3px] ">
          <Checkbox style={{ padding: "0px" }} color={themeColor}
            icon={<MdCheckBoxOutlineBlank style={{ color: darkMode ? "white" : "black" }} />}
            checkedIcon={darkMode ? <MdOutlineCheckBox style={{ color: "white" }} /> : <MdCheckBox />}
            checked={isSqlTools}
            onChange={() => handleCheckBox()}
          />
          <span style={{ marginTop:"2px", fontSize: "14px", color: darkMode ? "white" : "black" }}>{"Enable"}</span>
        </div> */}

          {backActiveTab === 0 &&
            <div style={{ padding: "5px" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", fontSize: "14px", fontWeight: "450", margin: "0px", color: darkMode ? "white" : "black" }}>
                SQL
                {/* <Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} /> */}
              </div>
              <div className="w-full flex flex-row items-center"
                style={{ borderRadius: "7px", border: "1px solid #CCCCCC", padding: "10px" }}>
                <div style={{ color: darkMode ? "white" : "black" }} className=" text-sm mr-4">Enable</div>
                <Toggle enabled={isSqlTools} setEnabled={() => handleCheckBox()} />
              </div>
            </div>
          }

          {backActiveTab === 0 && isSqlTools && (

            <div className={` mt-[10px]`}>
              <div className="w-[100%] flex" style={{ gap: "20px", padding: "5px" }}>
                <Grid container className={`flex justify-between gap-[20px]`}>
                  {/* ${(isLargeMobile || isSmallMobile) ? "gap-[20px]" : isTablet ? "gap-[90px]" : isloptop ? "gap-[100px]" : "gap-[100px]" */}
                  {/* <Grid sx={{ marginTop: "5px" }}> */}
                  <div className={`w-[100%] flex flex-row flex-wrap`}
                    style={{ gap: "20px", justifyContent: "space-between" }}>
                    {/* ${isTablet ? "gap-[50px]" : "gap-[30px]"} */}
                    {fields?.map(fi => (
                      <MDBox className="sqlTool" style={{ marginBottom: "12px", width: windowSize.innerWidth <= 600 ? "100%" : "48%", outline: "none", display: "flex", alignItems: "center" }}>
                        {
                          fi.type === "dropdown" ?

                            <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                              nameArr={fi?.arr?.length ? fi?.arr : ["No Option"]}
                              isMultiple={false}
                              fi={fi}
                              isLarge={true}
                              backSelect={(value) => handleDropdownChange(value)}
                              personName={fi?.name === "databaseType" ? [selectedDropdownValue] : []}
                            />
                            :
                            <InputFieldComponent
                              fi={fi}
                              config={inputValues}
                              setConfig={setInputValues}
                            />
                          // <MDInput
                          //   id={fi.name}
                          //   type={fi.name === 'password' && showPassword ? 'text' : fi.type}
                          //   value={inputValues[fi.name]}
                          //   onChange={(e) => {
                          //     setInputValues({ ...inputValues, [fi.name]: e?.target?.value });
                          //   }}
                          //   label={fi.placeholder}
                          //   InputLabelProps={{
                          //     paddingLeft: "10px !important"
                          //   }}
                          //   InputProps={{
                          //     endAdornment: fi.type === 'password' ? (
                          //       <InputAdornment position="end">
                          //         <IconButton
                          //         style={{marginTop:"-1px"}}
                          //           onClick={handleClickShowPassword}
                          //           onMouseDown={handleMouseDownPassword}
                          //           edge="end"
                          //         >
                          //           {showPassword ? <Visibility style={{width:"17px",height:"17px",color:darkMode? "white" : ""}}/> : <VisibilityOff style={{width:"17px",height:"17px", color:darkMode? "white" : ""}}/>}
                          //         </IconButton>
                          //       </InputAdornment>
                          //     ) : null,
                          //   }}
                          //   // variant="standard" 
                          //   fullWidth />
                        }

                        {/* {<Tooltip uniqueId={fi.name} text={fi?.information} color={getColorVlue(themeColor)} />} */}

                      </MDBox>
                    ))}

                    {
                      selectedDropdownValue === 'MySQL 8.0' && (
                        <div className="w-full">
                          <div className="flex">
                            <MDTypography sx={{ fontSize: "14px", }} style={{ color: darkMode ? "white" : "black" }}>SSL Certificate File</MDTypography>
                            {<Tooltip uniqueId={"SSlCertificate"} text={"SSL pem file for securely connecting to the database. If the file is not provided, the connection to the database will be insecure."} color={getColorVlue(themeColor)} />}
                          </div>
                          <div className={`${isMdLoptop ? "h-[160px]" : isTablet ? "h-[270px]" : ""} w-full border-2 border-dashed flex justify-center flex-col rounded-xl py-[10px] mt-[10px]`}>
                            <div>
                              <TabUpload supportedFileTypes={"pem"} isSingleFile={true} uploadedFile={uploadedFile} isSql={true} isWebToolTrain={true} />
                            </div>
                            {sslFilename ? (
                              <>
                                <Tag
                                  key={sslFilename}
                                  name={sslFilename}
                                  onClick={() => console.log("")}
                                  isDelete={true}
                                  onDelete={(e) => {
                                    e.stopPropagation();
                                    setModalDelOpen({ flag: true, document: sslFile });
                                  }}
                                  className="mt-6"
                                  isLogo={true}
                                  isWebToolTrain={true}
                                />
                              </>
                            ) : null}
                          </div>

                        </div>
                      )
                    }
                  </div>
                  {/* </Grid> */}

                  {/* <Grid> */}
                  <MDBox style={{ width: "100%" }}>
                    {/* <div className="flex ">
                      <MDTypography sx={{ fontSize: "14px", }} style={{ color: darkMode ? "white" : "black", fontWeight: "600" }}>System Prompt</MDTypography>
                      {<Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} />}
                    </div> */}

                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px", color: darkMode ? "white" : "black" }}>
                      System Prompt
                      {<span style={{ color: "red" }}>*</span>}

                      <Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} />
                    </div>
                    <OutlinedInput placeholder="Write prompt here"
                      sx={{
                        fontSize: "12px",
                        width: "100%",
                        minHeight: "70px",
                        borderRadius: "7px",
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#CCCCCC',
                          borderWidth: '1px',
                        },
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        resize: 'none', // Disable resizing by the user
                        '& .MuiInputBase-input::placeholder': {
                          fontSize: '12px',
                        },
                      }}
                      multiline
                      value={promptMessage} onChange={(e) => setPromptMessage(e?.target?.value)}
                    />

                    <div className={"mt-[30px]"}
                      style={{
                        border: "1px solid #FF9800", borderRadius: "7px",
                        background: "#FFF3E0", padding: "10px"
                      }}
                    >
                      <MDTypography style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "600", color: "#FF9800" }}>
                        <ReportGmailerrorredIcon style={{ width: "20px", height: "20px", marginRight: "5px" }} />

                        Warning Message!
                      </MDTypography>
                      <div className={`flex flex-col gap-[10px]`}>
                        <MDTypography style={{ fontSize: "12px", marginTop: "10px", color: "black" }}>
                          Please do not remove the <span className="text-red-500">{" {dialect} "}</span> and
                          <span className="text-red-500">{" {top_k} "}</span>
                          variables from the system prompt, if you choose to edit the default system prompt.
                          Protect settings will not be applicable on training of the agent with the SQL Tool enabled
                          User feedback mechanism will not be applicable with the SQL Tool enabled

                        </MDTypography>
                        {/* <MDTypography style={{ fontSize: "10px", color: darkMode ? "white" : "black" }}>
                          Protect settings will not be applicable on training of the agent with the SQL Tool enabled
                        </MDTypography>
                        <MDTypography style={{ fontSize: "10px", color: darkMode ? "white" : "black" }}>
                          User feedback mechanism will not be applicable with the SQL Tool enabled
                        </MDTypography> */}
                      </div>
                    </div>
                  </MDBox>
                  {/* </Grid> */}
                </Grid>

              </div>
            </div>
          )}

          {
            backActiveTab === 0 && isSqlTools && (
              <MDBox sx={{
                marginTop: "30px", padding: "15px", marginRight: "5px", marginLeft: "5px",
                border: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, borderRadius: "7px"
              }}>
                {/* <Grid style={{ width: "100%" }}> */}
                <div className="flex w-full justify-between"
                  style={{ borderBottom: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, paddingBottom: "5px", marginBottom: "5px" }}>
                  <div className="flex items-center">
                    {promptQuery.length === 0 ? (
                      <IoIosSquareOutline
                        style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                        onClick={() => handleSelectAll()}
                      />
                    ) : selectedUrls.length === promptQuery.length ? (
                      <MdCheckBox
                        style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : getColorVlue() }}
                        onClick={() => handleSelectAll()}
                      />
                    ) : (
                      <IoIosSquareOutline
                        style={{ fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                        onClick={() => handleSelectAll()}
                      />
                    )}
                    <MDTypography sx={{ fontSize: windowSize.innerWidth <= 600 ? "12px" : "16px", fontWeight: "600" }} style={{ color: darkMode ? "white" : "black" }}>
                      Example Prompt & SQL Query (Optional)
                    </MDTypography>
                    <Tooltip uniqueId={"sqlprompt&query"} text={`A set of sample natural language prompts and equivalent SQL query pairs that can be used to improve the model's responses, directly tailored to your database schema and tables.`} color={getColorVlue(themeColor)} />
                  </div>
                  <div className="flex items-center" style={{ marginTop: "-4px" }}>
                    {/* <MDButton size="small" sx={{
                      color: darkMode ? "#ec1313 !important" : "#FF0000!important", fontSize: "10px", padding: "7px !important",
                      display: "flex", gap: "4px", padding: "7px !important", fontSize: "10px !important", backgroundColor: darkMode ? "transparent !important" : "", marginTop: "4px"
                    }}
                      onClick={deleteAllInputs}>Delete</MDButton> */}

                    <Icon
                      sx={{ marginLeft: "15px", cursor: "pointer", color: darkMode ? "white !important" : "" }}
                      fontSize="small"
                      onClick={() => handleDeleteSelected()}
                    >
                      delete
                    </Icon>
                    <AddBoxIcon color={darkMode ? "white" : themeColor} className="mr-[5px] mt-[2px] pl-[5px] cursor-pointer flex justify-center items-center" fontSize="medium" onClick={() => addUrlInput()} />
                  </div>
                </div>


                <div>

                  {promptQuery?.map((query, index) => {

                    const isSelected = selectedUrls.includes(index); // Check if the URL is selected

                    return (
                      <MDBox key={index} className={"flex w-full flex-wrap justify-between"} sx={{ display: "flex", marginTop: "10px" }}>
                        {/* gap: (isLargeMobile || isSmallMobile) ? "20px" : isMdLoptop ? "138px" : "120px" */}
                        <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "48%", display: 'flex', alignItems: "center" }}>
                          {/* <input
                        name="promptValue"
                        className={inputStyled}
                        style={{ minWidth: "100%", borderColor: darkMode ? "white" : "#000000",color:darkMode ? "white" : "#000000"  }}
                        value={query?.promptValue || ""}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Input Prompt"
                      /> */}

                          {isSelected ? (
                            <MdCheckBox
                              style={{ fontSize: "33px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : getColorVlue() }}
                              onClick={() => handleCheckboxToggle(index)}
                            />
                          ) : (
                            <IoIosSquareOutline
                              style={{ fontSize: "33px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                              onClick={() => handleCheckboxToggle(index)}
                            />
                          )}

                          <InputFieldComponent
                            fi={{ type: 'text', name: "promptValue", placeholder: "Input Prompt" }}
                            config={query?.promptValue || ""}
                            isNoLabel={true}
                            setConfig={(e) => { handleInputChange(index, e, "promptValue") }}
                          />
                        </div>
                        <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "48%", display: 'flex', alignItems: "center" }}>
                          {/* <input
                        name="sqlQuery"
                        className={inputStyled}
                        style={{  minWidth: "90%",borderColor: darkMode ? "white" : "#000000", color:darkMode ? "white" : "#000000"  }}
                        value={query?.sqlQuery || ""}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Sql Query"
                      /> */}
                          {windowSize.innerWidth <= 600 &&
                            <div style={{ width: "43px" }}></div>
                          }
                          <div style={{ width: "100%", marginTop: windowSize.innerWidth <= 600 && "6px" }}>
                            <InputFieldComponent
                              fi={{ type: 'text', name: "sqlQuery", placeholder: "Sql Query" }}
                              config={query?.sqlQuery || ""}
                              isNoLabel={true}
                              setConfig={(e) => handleInputChange(index, e, "sqlQuery")}
                            />
                          </div>
                          {/* <Icon
                        sx={{ marginLeft: "15px", cursor: "pointer", color: darkMode ? "white !important" : "", marginTop: "20px" }}
                        fontSize="small"
                        onClick={() => deleteUrlInput(index)}
                      >
                        delete
                      </Icon> */}
                        </div>
                      </MDBox>
                    )
                  })}

                </div>

                {/* </Grid> */}
              </MDBox>

            )
          }

          {/*  ==== Function as tool  */}

          {
            backActiveTab === 1 && (
              <FunctionAsTool isSqlEnable={isSqlTools} functionAsToolEnable={functionAsToolEnable} setFunctionAsToolEnable={setFunctionAsToolEnable} selectedFunctions={selectedFunctions} setSelectedFunctions={setSelectedFunctions}
                handleFunctionSelect={handleFunctionSelect} />
            )
          }


          {
            backActiveTab === 2 && (
              <GoogleSearchTool isGoogleSearchEnable={googleSearchToolEnable}
                setGoogleSearchEnable={(fl) => setGoogleSearchToolEnable(fl)}
                setGoogleURLs={(e) => setGoogleURLs(e)} googleURLs={googleURLs}
                setSelectedGoogleUrls={(e) => setSelectedGoogleUrls(e)} selectedGoogleUrls={selectedGoogleUrls}
                setSearchURLs={(e) => setSearchURLs(e)} searchURLs={searchURLs}

              />
            )
          }


        </div>
      </div>



      <MDBox mt={5} mb={2} style={{ display: "flex", width: "100%", gap: "20px", justifyContent: "center", flexWrap: isSmallMobile ? "" : "wrap" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={loader}
          onClick={() => {
            // (backActiveTab === 2) ? setBackActiveTab(1)
            //   : (backActiveTab === 1) ? setBackActiveTab(0)
            //     : 
                reconfigBtn()
          }}
          variant="outlined" color={darkMode ? "white" : themeColor} fullWidth>
          {
          // (backActiveTab === 1 || backActiveTab === 2) ? 'Back' : 
          'Previous'
          }
        </MDButton>
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={loader}
          onClick={() => {
            // (backActiveTab === 0) ? setBackActiveTab(1)
            //   : (backActiveTab === 1) ? setBackActiveTab(2)
            //     : 
                sqldatabaseTool()
          }}
          variant="gradient" color={themeColor} fullWidth>
          {sqlLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" />
            // : (backActiveTab === 0 || backActiveTab === 1) ? 'Next'
              : 'Save & Next'}
        </MDButton>
      </MDBox>



      {modalDelOpen?.flag && (
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: sslFile })}>
          <div style={{ textAlign: "center" }} className="flex flex-row w-full justify-center items-center text-xl font-semibold mb-4">
            Are you sure you want to delete "{sslFilename}"? Deleted document will be lost
          </div>

          <div className="mt-6 flex flex-row w-full justify-center items-center text-xl font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: !sslFile })} variant="gradient" color={"error"} fullWidth>
              No
            </MDButton>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={deleteDocument} variant="gradient" color={themeColor} fullWidth>
              Yes
            </MDButton>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChatbotTool;

