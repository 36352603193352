import React, { useEffect, useState } from "react";
import { Code } from "react-content-loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerLoader } from "examples/Loader/Loader";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { uploadPreview } from "libraries/Enums";
import { Tag } from "examples/Tag/Tag";
import ApiRequest from "services/ApiRequest";
import { validateAndProcessFile } from "services/Utils";
import { getFileType } from "services/Utils";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Modal from "examples/Modal/Modal";
import MDBox from "components/MDBox";
import ApiUrls from "services/ApiUrls";
import Dropdown from "examples/Dropdown/Dropdown";
import MDDropdown from "components/MDDropdown";

export default function RecommendationTrain(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams()
  const [loader, setLoader] = useState(false);
  const [uploadPreviewLoader, setUploadPreviewLoader] = useState(false);
  const [modelName, setModelName] = useState(localStorage.getItem("featureModelId"));
  const [uploadfilePreviewList, setUploadfilePreviewList] = useState({ columns: [], rows: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [previewUploadLoader, setPreviewUploadLoader] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [dropdownValues, setDropdownValues] = React.useState({});
  const [modalDelOpen, setModalDelOpen] = React.useState({ flag: false, document: {} });
  const [delLoader, setDelLoader] = React.useState(false);
  const [documentId, setDocumentId] = React.useState('');
  const [searchNamesRecommend, setSearchNamesRecommend] = React.useState([]);
  const [searchListRecommend, setSearchListRecommend] = React.useState([]);
  const [selectRecommend, setSelectRecommend] = React.useState('');
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor, recommendType } = controller;
  const [trainingLoader, setTrainingLoader] = useState(false)
  const sourceLength = Object?.values(dropdownValues)?.filter((df) => df?.includes("source") || [])?.map((sc) => sc?.split("-sour")?.[0] || "")?.length;
  const userIdLength = Object?.values(dropdownValues)?.filter((df) => df?.includes("userId") || [])?.map((sc) => sc?.split("-userId")?.[0] || "")?.length;
  const sourceValue = Object?.values(dropdownValues)?.filter((df) => df?.includes("source") || [])?.map((sc) => sc?.split("-sour")?.[0] || "");
  const userIdValue = Object?.values(dropdownValues)?.filter((df) => df?.includes("userId") || [])?.map((sc) => sc?.split("-userId")?.[0] || "");

  console.log("location.state Train", location?.state)

  useEffect(() => {
    var dropval = {};
    // Source Dropdown

    if (recommendType === "Collaborative") {
      if (location?.state?.beforeTrained?.ratingCol?.length) {
        dropval = { ...dropval, [location.state?.beforeTrained?.ratingCol]: `${location.state?.beforeTrained?.ratingCol}-rating` }
      }
      if (location?.state?.beforeTrained?.userIdCol?.length) {
        dropval = { ...dropval, [location.state?.beforeTrained?.userIdCol]: `${location.state?.beforeTrained?.userIdCol}-userId` }
      }
      if (location?.state?.beforeTrained?.productCol?.length) {
        dropval = { ...dropval, [location.state?.beforeTrained?.productCol]: `${location.state?.beforeTrained?.productCol}-product` }
      }
    } else {
      if (location?.state?.beforeTrained?.sourceCol?.length) {
        dropval = { ...dropval, [location.state?.beforeTrained?.sourceCol]: `${location.state?.beforeTrained?.sourceCol}-source` }
      }

      // Features Dropdown List
      if (location?.state?.beforeTrained?.featureList) {
        location?.state?.beforeTrained?.featureList?.map(
          (feat) => { return dropval = { ...dropval, [feat]: `${feat}-feature` }; }
        )
      }
    }

    setSelectRecommend(recommendType === "Collaborative" ? location?.state?.beforeTrained?.userIdToSearch : location?.state?.beforeTrained?.productName);
    setDropdownValues(dropval);

  }, []);

  const navigation = (route, page, step, uploadedFile, beforeTrained, objectId, result) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        id: location?.state?.id,
        name: name,
        objectId: objectId || location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: uploadedFile,
        isTrained: false,
        beforeTrained: beforeTrained ?? location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
        previousPath: location.state?.previousPath ?? '',
        result: result
      }
    });
  };

  const uploadedFile = (acceptedFiles) => {
    setLoader(true);

    const supportedFormats = {
      'text/csv': 'CSV',
      'application/vnd.ms-excel': 'XLSX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
      'application/octet-stream': 'PARQUET',
      '': 'PARQUET'
    };

    const afile = acceptedFiles?.[0];

    const validation = validateAndProcessFile(afile, supportedFormats)
    if (validation === true) {
      ApiRequest.uploadChatbotAgent({ file: afile, modelName: modelName ? JSON.parse(modelName)?.name : "", fileName: afile?.name, fileSize: afile?.size, fileExtension: getFileType(supportedFormats, afile?.type) }, location?.state?.objectId, (res) => {
        if (res.isSuccess) {
          setSelectRecommend("");
          toast.success(`"${res.data.fileName}" uploaded successfully.`);
          navigation('/train', 4, 2, res?.data?.documentNames, false);
        } else {
          toast.error(res.error ? res?.error?.message : 'Something went wrong')
        }
        setLoader(false);
      });
    }
    else {
      toast.error(`${validation}`)
      setLoader(false);
    }
  };

  const previewFile = (fileData) => {
    setModalOpen(true); setUploadPreviewLoader(true);
    setDocumentId(fileData?._id ?? fileData?.documentId ?? '');
    const data = {
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      agentId: location?.state?.objectId,
      documentId: fileData?._id ?? fileData?.documentId ?? ''
    }

    ApiRequest.previewUploadFeatureAgent(data, `${location?.state?.objectId}`, (res) => {
      setUploadPreviewLoader(false);
      if (res.isSuccess) {
        setUploadfilePreviewList({
          columns: res.data?.columns,
          rows: res.data?.sampleRows
        })
      } else {
        toast.error(res.error.message ?? "Something went wrong")
      }
    });
  };

  const modal = (str) => {
    var output = [
      { title: "Feature", apiName: `${str}-feature`, id: 1 },
      { title: "Source", apiName: `${str}-source`, id: 2 },
    ]
    return output;
  };

  const modal2 = (str) => {
    var output = [
      { title: "User ID", apiName: `${str}-userId`, id: 1 },
      { title: "Rating", apiName: `${str}-rating`, id: 2 },
      { title: "Product", apiName: `${str}-product`, id: 3 },
    ]
    return output;
  };

  const returnedLabel = (columnName) => {
    return (
      <div> {dropdownValues[columnName]?.includes(uploadPreview?.source) ?
        <div style={{ padding: '2px' }} className="text-white bg-green-700 font-semibold text-xs rounded-sm border border-2 border-green-800">S</div>
        : dropdownValues[columnName]?.includes(uploadPreview?.feature) ?
          <div style={{ padding: '2px' }} className="text-white bg-primary-blue font-semibold text-xs rounded-sm border border-2 border-primary-blue">F</div>
          : <div style={{ height: "25px" }}></div>}</div>
    )
  };

  const InputNewFields = uploadfilePreviewList?.rows?.length ? Object?.keys(uploadfilePreviewList?.rows?.[0])?.map((cl, i) => {
    return {
      id: i + 1,
      // returnedLabel(cl)
      name: '',
      apiName: cl,
      type: "dropdown",
      arr: recommendType === "Collaborative" ? modal2(cl) : modal(cl),
    }
  }) : [];

  const deleteDocument = () => {
    setDelLoader(true);
    const data = {
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      documentId: modalDelOpen?.document?._id ?? modalDelOpen?.document?.documentId ?? ''
    }

    ApiRequest.documentDeleteFeatureAgent(data, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, document: {} }); setDelLoader(false);
      // featureExtractionModels();
      if (res.isSuccess) {
        toast.success(res?.data?.message);
        navigation('/train', 4, 2, res?.data?.documentNames, false);
        setDropdownValues({})
      }
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  }

  const saveModel = () => {
    var data = {};

    if (recommendType === "Collaborative") {
      data = {
        ratingCol: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes("rating"))
          ?.map((sc) => sc?.split("-rating")?.[0])?.[0] : '',
        productCol: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes("product"))
          ?.map((sc) => sc?.split("-product")?.[0])?.[0] : '',
        userIdCol: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes("userId"))
          ?.map((sc) => sc?.split("-userId")?.[0])?.[0] : '',
        modelName: modelName ? JSON.parse(modelName)?.name : "",
        userIdToSearch: Number(selectRecommend),
        documentId: documentId ?? ''
      }
    } else {
      data = {
        sourceCol: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes("source"))
          ?.map((sc) => sc?.split("-sour")?.[0])?.[0] : '',
        featureList: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes("feature"))
          ?.map((sc) => sc?.split("-feat")?.[0]) : [],
        modelName: modelName ? JSON.parse(modelName)?.name : "",
        productName: selectRecommend,
        documentId: documentId ?? ''
      }
    }

    if (recommendType === "Collaborative") {
      if (!data?.userIdCol) {
        toast.error(`Please select only one column as "user ID"`)
      } else if (!Number(selectRecommend)) {
        toast.error(`On the basis of user ID column, please select only one value from "${userIdValue}" dropdown`)
      }
      else if (!data?.ratingCol) {
        toast.error(`Please select only one column as "rating"`)
      }
      else if (!data?.productCol) {
        toast.error(`Please select only one column as "product"`)
      }
      else {
        toast.success("Column configuration saved successfully")
        navigation('/train', 4, 2, location?.state?.uploadedFile, data);
        setModalOpen(false);
      }
    } else {
      if (!data?.sourceCol) {
        toast.error(`Please select only one column as "source"`)
      } else if (!data?.productName?.length) {
        toast.error(`On the basis of source column, please select only one value from "${sourceValue}" dropdown`)
      }
      else if (!data?.featureList?.length) {
        toast.error(`Please select atleast one column as "feature"`)
      }
      else {
        toast.success("Column configuration saved successfully")
        navigation('/train', 4, 2, location?.state?.uploadedFile, data);
        setModalOpen(false);
      }
    }

  }

  const trainXAgent = () => {
    if (recommendType === "Collaborative" ?
      (location?.state?.beforeTrained?.userIdCol && location?.state?.beforeTrained?.ratingCol && location?.state?.beforeTrained?.productCol && location?.state?.beforeTrained?.userIdToSearch)
      : (location?.state?.beforeTrained?.sourceCol && location?.state?.beforeTrained?.featureList && location?.state?.beforeTrained?.productName)
    ) {
      setTrainingLoader(true);
      ApiRequest.trainFeatureAgent(location?.state?.beforeTrained, `${location?.state?.deployedURL}${ApiUrls.trainRecommendationAgent}/${location?.state?.objectId}`, (res) => {
        setTrainingLoader(false);
        if (res.isSuccess) {
          toast.success(res.data.message ? res.data.message : "X-Agent trained successfully");
          // if (location?.state?.modelType === 'ContentBased') {
          navigate(`/patterns/${id}/${name}/agent-creation/infer/recommendation-system`, { state: { ...location?.state } })
          // } 
          // else {
          //   navigation('/result', 5, 3, location?.state?.uploadedFile, location?.state?.beforeTrained, location?.state?.objectId, { graphValues: res?.data?.graphValues, trainingLoss: res?.data?.trainingLoss });
          // }

        } else {
          toast.error(res.error.message ? res.error.message : "Training failed")
        }
      });
    } else {
      toast.error(`Please select all values in file before start of training.`)
    }
  }

  const modelSetup = () => {
    navigation('/model', 2, 1, location?.state?.uploadedFile, location?.state?.beforeTrained, location?.state?.objectId, location?.state?.result);
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.uploadedFile, location?.state?.objectId, location?.state?.result);
  }

  const searchRecommendationPattern = () => {
    setSearchLoader(true);
    const data = {
      sourceCol: dropdownValues ? Object?.values(dropdownValues)?.filter((df) => df?.includes(recommendType === "Collaborative" ? "userId" : "source"))
        ?.map((sc) => sc?.split(recommendType === "Collaborative" ? "-userId" : "-sour")?.[0])?.[0] : '',
      searchQuery: '',
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      documentId: documentId ?? ''
    }

    ApiRequest.searchRecommendPattern(data, `${location?.state?.deployedURL}/recommendation-system-agent/search/${location?.state?.objectId}`, (res) => {
      setSearchLoader(false);
      if (res?.isSuccess) {
        const name = res?.data?.itemsList?.map(pl => { return pl });
        const completeList = res?.data?.itemsList?.map(pl => { return { title: pl, apiName: pl, default: '' } });
        setSearchNamesRecommend(name);
        setSearchListRecommend(completeList);
      } else {
        setSearchNamesRecommend([]); setSearchListRecommend([]);
      }
    });
  };

  const onlyLoadSourceCol = Object?.values(dropdownValues)?.filter((df) => df?.includes(recommendType === "Collaborative" ? "userId" : "source"))?.length;

  useEffect(() => {

    if ((sourceLength || userIdLength) && documentId?.length) {
      searchRecommendationPattern();
    }
  }, [onlyLoadSourceCol, documentId]);

  return (
    <div className="flex flex-col h-full" style={{ justifyContent: "space-between" }}>
      <form autoComplete="off">
        <div className="flex flex-row w-full flex-wrap justify-between mt-4">
          <div className="flex flex-row my-2 w-full" style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
            <div className="text-primary-blue w-full flex flex-col" style={{ justifyContent: "center", alignItems: "center" }}>
              {location?.state?.isTrained ?
                <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                  <div>Agent trained successfully. Please go to inference.</div>
                  {location?.state?.deployedURL && <div className="mt-2"><span className="font-semibold"> Deployed URL: </span>{location.state?.deployedURL}</div>}
                </div>
                : <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>Training has not started yet. Please upload file and click file to preview.</div>
              }
              {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
                fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
                <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                  Only one document can be stored in a model. Please delete already uploaded item to save new file.
                </div> : null}
            </div>
          </div>
        </div>

        <div className=" flex flex-grow justify-center w-full  flex-wrap">
          {location.state?.uploadedFile?.length ?
            location.state?.uploadedFile?.filter((fl) => fl?.fileName?.split('_')?.[0] === JSON?.parse(modelName || "")?.name)
              ?.map((afl, ind, arr) => (
                (arr?.length - 1 === ind) &&
                <Tag
                  name={afl?.fileName}
                  onClick={(e) => previewFile(afl)}
                  isDelete={true}
                  onDelete={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, document: afl }) }}
                  className="mt-20"
                  isLogo={true}
                  isWebToolTrain={true}
                />

              )) : null}
        </div>
      </form>

      {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
        fl?.fileName?.split('_')?.[0] === JSON?.parse(modelName || "")?.name)?.length) ?
        <div></div>
        :
        <>
          <div>
            <TabUpload supportedFileTypes="CSV, XLSX, PARQUET" isSingleFile={true} uploadedFile={(fl) => uploadedFile(fl)} loader={loader} />
          </div>


        </>
      }

      <MDBox mt={4} mb={1} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
        {/* <MDButton style={{width: "max-content", minWidth: "125px"}} disabled={trainingLoader} onClick={()=> {reconfigBtn()}} variant="gradient" color={themeColor} fullWidth>
              Reconfig
            </MDButton> */}
        <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={trainingLoader} onClick={() => { modelSetup() }} variant="gradient" color={themeColor} fullWidth>
          Previous
        </MDButton>
        <MDButton style={{ width: "max-content", minWidth: "125px" }}
          disabled={
            recommendType === "Collaborative" ?
              !(location?.state?.beforeTrained?.userIdCol && location?.state?.beforeTrained?.ratingCol && location?.state?.beforeTrained?.productCol && location?.state?.beforeTrained?.userIdToSearch) || trainingLoader
              :
              !(location?.state?.beforeTrained?.sourceCol && location?.state?.beforeTrained?.featureList && location?.state?.beforeTrained?.productName) || trainingLoader

          }
          onClick={() => { trainXAgent() }} variant="gradient" color={themeColor} fullWidth>
          {trainingLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'train'}
        </MDButton>
      </MDBox>

      {/* ========= Modal =============== */}

      {modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
          <div className="flex flex-row w-full justify-between items-center text-xl font-semibold mb-4" >
            <div>Select Columns</div>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => saveModel()} variant="gradient" color={themeColor} fullWidth>
              {previewUploadLoader ? <SpinnerLoader style={{ color: darkMode ? "white" : "black" }} adjustment={true} enhance="text-xs" /> : "Save"}
            </MDButton>
          </div>

          {/* ====== Tags */}

          {/* User ID */}
          {recommendType === "Collaborative" &&
            <div className="flex flex-row flex-wrap items-center w-full">
              <div className="font-semibold" style={{ fontSize: "18px" }}>
                User ID:
              </div>
              {Object?.values(dropdownValues)?.length ? Object?.values(dropdownValues || {})?.filter((df) => df?.includes("-userId"))?.map((df) => {
                return (
                  <div className="mr-2 my-4">
                    <Tag
                      name={df?.split("-userId")?.[0]}
                      onClick={(e) => console.log("")}
                      isDelete={true}
                      onDelete={(e) => {
                        e.stopPropagation();
                        delete dropdownValues[df?.split("-userId")?.[0]]
                        setDropdownValues({ ...dropdownValues, })
                      }}
                      fontColor={"white"}
                      backgroundColor={df?.includes("userId") ? "#006b3c" : "#E3E4E5"}
                      isLogo={false}
                      isWebToolTrain={true}
                    />
                  </div>
                )
              }) : null}
            </div>
          }

          {/* Source */}
          {recommendType !== "Collaborative" &&
            <div className="flex flex-row flex-wrap items-center w-full">
              <div className="font-semibold" style={{ fontSize: "18px" }}>
                {recommendType === "Collaborative" ? "User ID:" : "Source:"}
              </div>
              {Object?.values(dropdownValues)?.length ? Object?.values(dropdownValues)?.filter((df) => df?.includes("-sourc"))?.map((df) => (
                <div className="mr-2 my-4">
                  <Tag
                    name={df?.split("-sourc")?.[0]}
                    onClick={(e) => console.log("")}
                    isDelete={true}
                    onDelete={(e) => {
                      e.stopPropagation();
                      setSelectRecommend('');
                      delete dropdownValues[df?.split("-sourc")?.[0]]
                      setDropdownValues({ ...dropdownValues })
                    }}
                    fontColor={"white"}
                    backgroundColor={df?.includes("source") ? "#006b3c" : "#E3E4E5"}
                    isLogo={false}
                  />
                </div>
              )) : null}
            </div>
          }

          {/* Feature */}
          {recommendType !== "Collaborative" &&
            <div className="flex flex-row flex-wrap items-center w-full">
              <div className=" font-semibold" style={{ fontSize: "18px" }}>Features:</div>
              {Object?.values(dropdownValues)?.length ? Object?.values(dropdownValues)?.filter((df) => df?.includes("-featur"))?.map((df) => (
                <div className="mr-2 my-4">
                  <Tag
                    name={df?.split("-featur")?.[0]}
                    onClick={(e) => console.log("")}
                    isDelete={true}
                    onDelete={(e) => {
                      e.stopPropagation();
                      delete dropdownValues[df?.split("-featur")?.[0]]
                      setDropdownValues({ ...dropdownValues })
                    }}
                    fontColor={"white"}
                    backgroundColor={df?.includes("feature") ? "#0d98ba" : "#E3E4E5"}
                    isLogo={false}
                  />
                </div>
              )) : null}
            </div>
          }

          {/* Product */}
          {recommendType === "Collaborative" &&
            <div className="flex flex-row flex-wrap items-center w-full">
              <div className="font-semibold" style={{ fontSize: "18px" }}>
                Product:
              </div>
              {Object?.values(dropdownValues)?.length ? Object?.values(dropdownValues)?.filter((df) => df?.includes("-product"))?.map((df) => (
                <div className="mr-2 my-4">
                  <Tag
                    name={df?.split("-product")?.[0]}
                    onClick={(e) => console.log("")}
                    isDelete={true}
                    onDelete={(e) => {
                      e.stopPropagation();
                      delete dropdownValues[df?.split("-product")?.[0]]
                      setDropdownValues({ ...dropdownValues })
                    }}
                    fontColor={"white"}
                    backgroundColor={df?.includes("product") ? "#006b3c" : "#E3E4E5"}
                    isLogo={false}
                  />
                </div>
              )) : null}
            </div>
          }

          {/* Rating */}
          {recommendType === "Collaborative" &&
            <div className="flex flex-row flex-wrap items-center w-full">
              <div className="font-semibold" style={{ fontSize: "18px" }}>
                Rating:
              </div>
              {Object?.values(dropdownValues)?.length ? Object?.values(dropdownValues)?.filter((df) => df?.includes("-rating"))?.map((df) => (
                <div className="mr-2 my-4">
                  <Tag
                    name={df?.split("-rating")?.[0]}
                    onClick={(e) => console.log("")}
                    isDelete={true}
                    onDelete={(e) => {
                      e.stopPropagation();
                      delete dropdownValues[df?.split("-rating")?.[0]]
                      setDropdownValues({ ...dropdownValues })
                    }}
                    fontColor={"white"}
                    backgroundColor={df?.includes("rating") ? "#006b3c" : "#E3E4E5"}
                    isLogo={false}
                  />
                </div>
              )) : null}
            </div>
          }

          {/* Product search */}
          {searchLoader ?
            <div className="flex flex-row w-full items-center">
              <span className="mr-4 text-sm mt-4">Searching "{recommendType === "Collaborative" ? userIdValue : sourceValue}" ...</span>
              <SpinnerLoader style={{ color: darkMode ? "white" : "black" }} adjustment={true} enhance="text-md" />
            </div>
            : ((sourceLength || userIdLength) && searchNamesRecommend?.length) ?
              <div className="flex flex-row flex-wrap items-center w-full mt-6">
                {/* <div className="font-semibold"  style={{ fontSize: "18px" }}>Search:</div> */}

                <div className="mt-1 w-[15%]">
                  <MDDropdown dropdownName={recommendType === "Collaborative" ? userIdValue : sourceValue}
                    nameArr={searchNamesRecommend}
                    backendArr={searchListRecommend?.length ? searchListRecommend : []}
                    isMultiple={false}
                    backSelect={(value) => setSelectRecommend(searchListRecommend?.filter(pl => pl?.title === value)?.[0]?.apiName)}
                    isNoSelection={true}
                    isNoLabel = {true}
                  // personName={[]} 
                  />
                </div>

                {selectRecommend ?
                  <div className="mt-3">
                    <Tag
                      name={selectRecommend}
                      onClick={(e) => console.log("")}
                      isDelete={true}
                      onDelete={(e) => {
                        e.stopPropagation();
                        setSelectRecommend('');
                      }}
                      fontColor={"white"}
                      backgroundColor={"#E3E4E5"}
                      isLogo={false}
                    />
                  </div>
                  : null}

              </div>
              : null}

          {/* ====== Header & Dropdowns */}
          <div style={{ overflowX: 'auto' }}>
            <table className="w-full min-w-max table-fixed text-left">
              <thead>
                <tr className="flex flex-row border-transparent items-end w-full"
                  style={{
                    borderBottom: darkMode ? "2px solid white" : "2px solid black",
                    borderColor: darkMode ? "white" : "black",
                    width: "100%",
                    fontSize: "16px",
                    marginTop: "30px",
                    // height: "100px"
                  }}
                >
                  {uploadfilePreviewList?.rows?.length ? Object?.keys(uploadfilePreviewList?.rows?.[0])?.map((aObj, i) => (
                    <th key={i}
                      style={{
                        // width: `${100 / uploadfilePreviewList?.columns?.length}%`,
                        width: '200px'
                        // width: 'max-content'
                      }}
                      className="flex flex-row items-end font-bold text-primary-blue">
                      <div className="mb-1 tableCellWrap"
                        style={{ maxWidth: '190px', color: darkMode ? "white" : "black" }}>
                        {aObj}
                      </div>
                      <div className="">
                        {InputNewFields?.length ? InputNewFields.filter((fl) => (fl.apiName === aObj)).map((item, index) => {
                          return (
                            <div style={{ width: "90px" }}>
                              <Dropdown options={item?.arr}
                                onSelection={(selectioedOpion) => {
                                  console.log("selectioedOpion", selectioedOpion?.apiName);
                                  console.log("selectioedOpion", selectioedOpion);
                                  console.log(dropdownValues, "dropdownvalues")
                                  if (recommendType === "Collaborative") {
                                    if (selectioedOpion?.apiName?.includes('rating') && Object?.values(dropdownValues)?.some((it) => it.includes('-rating'))) {
                                      toast.error("Rating already assigned. Only one column can be used as a rating")
                                    } else if (selectioedOpion?.apiName?.includes('product') && Object?.values(dropdownValues)?.some((it) => it.includes('-product'))) {
                                      toast.error("Product already assigned. Only one column can be used as a product")
                                    } else if (selectioedOpion?.apiName?.includes('userId') && Object?.values(dropdownValues)?.some((it) => it.includes('-userId'))) {
                                      toast.error("User ID already assigned. Only one column can be used as a user ID")
                                    } else {
                                      setDropdownValues({ ...dropdownValues, [item.apiName]: selectioedOpion?.apiName });
                                    }
                                  } else {
                                    if (selectioedOpion?.apiName?.includes('source') && Object?.values(dropdownValues)?.some((it) => it.toLowerCase().includes('source'))) {
                                      toast.error("Source already assigned. Only one column can be used as a source")
                                    } else {
                                      setDropdownValues({ ...dropdownValues, [item.apiName]: selectioedOpion?.apiName });
                                    }
                                  }

                                }} />
                            </div>
                          )
                        }) : []}
                      </div>
                    </th>
                  )) : ''}
                </tr>
              </thead>

              {/* ============== List  ================ */}

              <tbody>
                {uploadPreviewLoader ?
                  <tr className="w-full flex mt-2">
                    {[1, 2, 3].map(() => (
                      <td className="w-56 mr-8">
                        <Code backgroundColor="grey" foregroundColor="darkgrey" />
                      </td>
                    ))}
                  </tr>
                  :
                  <tr style={{ height: "60vh", overflow: 'auto', scrollBehavior: 'smooth' }}>
                    {uploadfilePreviewList?.rows?.length ? uploadfilePreviewList?.rows?.map((aTable, i) => (
                      <div className={`flex flex-row pt-4 border-0 items-center w-full`}
                        style={{
                          overflowX: 'auto',
                          borderBottom: darkMode ? "1px solid white" : "1px solid black",
                          borderColor: darkMode ? "white" : "black",
                        }}

                      >
                        {Object?.values(aTable)?.map((aObj) => (
                          <td className="pr-2"
                            style={{
                              fontSize: "14px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              // width: `${100 / uploadfilePreviewList?.columns?.length}%`,
                              width: '200px'
                            }}>
                            {aObj}
                          </td>
                        ))}
                      </div>
                    )) : <td className="text-red-500 pt-4">No model found</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>


        </Modal>
      }

      {
        modalDelOpen?.flag &&
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: modalDelOpen.document })}>
          <div className="flex flex-row w-full justify-center items-center  font-semibold mb-4" >
            Are you sure you want to delete "{modalDelOpen?.document?.fileName}"? Deleted document will be lost
          </div >

          <div className="mt-6 flex flex-row w-full justify-center items-center font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: {} })} variant="gradient" color={"error"} fullWidth>
              {"No"}
            </MDButton>

            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => { deleteDocument() }} variant="gradient" color={themeColor} fullWidth>
              {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </div>
        </Modal>
      }

    </div >
  );
};
