/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";


// Dashboard components
import { useEffect, useState } from "react";
import { setChatbotHistoryUrl } from "context";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import { Tooltip } from "examples/Tooltip/Tooltip";


import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import Modal from "examples/Modal/Modal";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { FileIcon } from "examples/FileIcon/FileIcon";
import { TabsCustomized } from "examples/TabsCustomized/TabsCustomized";
import { Tag } from "examples/Tag/Tag";
import { CustomRadioButtons } from "examples/RadioButton/RadioButton";
import { ViewerPDF } from "examples/ViewerPDF/ViewerPDF";
// import DocViewer, { PDFRenderer, DocViewerRenderers } from 'react-doc-viewer'

import { validateAndProcessFile, getFileType } from "services/Utils";
import ApiRequest from "services/ApiRequest";
import { Box,TextField, FormControl } from "@mui/material";
import Icon from "@mui/material/Icon";
import { getColorVlue } from "services/Utils";
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useSelector } from "react-redux";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank,MdDelete, MdOutlineVisibility } from "react-icons/md";
import { IoMdCloseCircle } from 'react-icons/io';
import { FaRegWindowClose } from "react-icons/fa";

//images
import deleteIcon from "assets/images/flow-icons/delete icon.svg"
import LinkIcon from '@mui/icons-material/Link';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IoIosSquareOutline } from "react-icons/io";
import MDTypography from "components/MDTypography";
import Iframe from 'react-iframe';
import { InputFieldComponent } from "examples/InputField/InputField";
import useScreenWidth from "libraries/ScreenSizeHook";

function ChatbotTrain() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams();

  const [agentList, setAgentList] = useState();
  const [loader, setLoader] = useState(false);
  const [scrapedURLs, setScrapedURLs] = useState([]);
  const [delLoader, setDelLoader] = useState(false);
  const [feedbackLoader, setFeedbackLoader] = useState(false);
  const [trainingLoader, setTrainingLoader] = useState(false);
  const [trainingPopupLoader, setTrainingPopupLoader] = useState(false);
  const [backActiveTab, setBackActiveTab ] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [siteMapUrl, setSiteMapUrl] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isFilePreview, setIsFilePreview] = useState(false);
  const [isLoaderwebsiteUrl, setIsLoaderWebsiteUrl] = useState(false);
  const [isLoaderSitemap, setIsLoaderSitemap] = useState(false);
  const [urlsFetched, setUrlsFetched] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [errorURLs, setErrorURLs] = useState([]);
  const [isFeedbackPopupHandle, setIsFeedbackPopupHandle] = useState(false);
  const stateUser = useSelector((state) => state?.loginReducer?.isSqlTool);
  const [inputValue, setInputValue] = useState("");
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, document: {} });
  const [selectedUrls, setSelectedUrls] = useState([]); // State for selected URLs
  const [windowSize, getWindowSize] = useScreenWidth();

  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const isMobile = useMediaQuery('(min-width: 320px) and (max-width: 426px)');
  const isTablet = useMediaQuery('(min-width: 641px) and (max-width: 768px)');
  const isMediumLarge = useMediaQuery('(min-width: 770px) and (max-width: 1026px)');

  const navigation = (route, page, step, isDeploying, uploadedFile, isTrained) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        protect: location?.state?.protect,
        isDeploying: isDeploying,
        uploadedFile: uploadedFile,
        isEditAgent: location?.state?.isEditAgent,
        isTrained: isTrained,
      }
    });
  };

  useEffect(() => {
    feedbackPopupHandle();
    setChatbotHistoryUrl(dispatch, '')
    allAgentsList();

  }, [])
  const addUrlInput = () => {
    setScrapedURLs(["", ...scrapedURLs]);
  };

  const feedbackPopupHandle = () => {
    setIsLoader(true);
    ApiRequest.isFeedbackChatbot(`${location.state?.deployedURL}/chatbot-agent/isFeedbackEmbeddingsExist/${location.state?.objectId}`, '', (res) => {
      if (res?.isSuccess) {
        setIsFeedbackPopupHandle(res?.data?.isFeedbackExist)
      }
    });
  };

  const allAgentsList = () => {
    setIsLoader(true);
    ApiRequest.agentList(`/${location.state.objectId}`, '', (res) => {
      if (res.isSuccess) {
        setAgentList(res.data?.agentsList);
        setScrapedURLs(res.data?.agentsInformation[0]?.selectedWebsiteURLS ?? [])
        if(res.data?.agentsInformation[0]?.baseWebsiteURL.includes(".xml")){
          setSiteMapUrl(res.data?.agentsInformation[0]?.baseWebsiteURL)
        }else{
        setWebsiteUrl(res.data?.agentsInformation[0]?.baseWebsiteURL)
        }
      } else {
        setAgentList([]);
      }
      setIsLoader(false);
    });
  };

  var resIndex = 0;

  const uploadedFile = (acceptedFiles) => {

    setLoader(true);
    // baseWebsiteURL
    // selectedWebsiteURLS
    const supportedFormats = {
      'application/pdf': 'PDF',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
      'text/plain': 'TXT',
      'application/msword': 'DOCX',
    };


    acceptedFiles?.length && acceptedFiles?.map((afile, i) => {
      // Unexpected File Type. We only support [PDF, DOCX, TXT, CSV, XLSX, PARQUET].
      const validation = validateAndProcessFile(afile, supportedFormats)
      if (validation === true) {
        ApiRequest.uploadChatbotAgent({
          file: afile, modelName: '',
          fileName: afile?.name, fileSize:afile?.size, fileExtension: getFileType(supportedFormats, afile?.type)
        },
          location?.state?.objectId, (res) => {
            console.log("res file upload oooo", res)
            if (res.isSuccess) {
              toast.success(`"${res.data.fileName}" uploaded successfully.`);
              resIndex = resIndex + 1;
              if (resIndex === acceptedFiles?.length) {
                resIndex = 0;
                navigation('/train', 4, 3, false,
                  (location?.state?.documentNames?.length > res?.data?.documentNames?.length)
                    ? location?.state?.documentNames : res?.data?.documentNames, location?.state?.isTrained);
              }
            } else {
              toast.error(res.error ? res?.error?.message : 'Something went wrong')
            }
            //  Loop Through All Files
            if (acceptedFiles?.length === i + 1) {
              setLoader(false);
            }
            setLoader(false);
          });
      }
      else {
        toast.error(`${validation}`)
        setLoader(false);
      }

    });
  };

  const deleteDocument = () => {
    setDelLoader(true);
    const data = {
      modelName: "",
      documentId: modalDelOpen?.document?._id ?? modalDelOpen?.document?.documentId ?? ''
    }

    ApiRequest.documentDeleteFeatureAgent(data, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, document: {} }); setDelLoader(false);
      // featureExtractionModels();
      if (res.isSuccess) {
        toast.success(res?.data?.message);
        navigation('/train', 4, 3, false, res?.data?.documentNames, location?.state?.isTrained);
      }
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  }

  const TrainXAgent = async (isFeedback) => {
    // if (scrapedURLs?.length > 40) {
    //   toast.error("You may only train the LLM on maximum 40 Page URLs at a time. ")
    //   setTrainingLoader(false);
    //   return;
    // }
    const invalidUrls = scrapedURLs?.filter(url => !isUrlValid(url));
  
    if (invalidUrls?.length > 0) {
      toast.error("Please enter correct URL");
      return;
    }
    if (isFeedback) setFeedbackLoader(true);
    else setTrainingLoader(true);
    setTrainingPopupLoader(true);
    
    const saveUrlsResult = await saveAllUrls();
    if (!saveUrlsResult) {
      setTrainingLoader(false);
      setFeedbackLoader(false);
      setTrainingPopupLoader(false);
      return;
    }
  
    ApiRequest.retainFeedbackChatbotAgent({ deleteFeedback: !isFeedback }, `${location.state?.deployedURL}/chatbot-agent/deleteembeddings/${location.state?.objectId}`, (resFeedback) => {
      setTrainingLoader(false); 
      setFeedbackLoader(false); 
      setModalOpen(false);
      if (resFeedback.isSuccess) { 
        if (isFeedback) toast.success(resFeedback?.data?.message);
      } else { 
        if (isFeedback) toast.error(resFeedback?.error?.message);
      }
  
      ApiRequest.trainChatbotAgent('', `${location.state?.deployedURL}/chatbot-agent/train/${location.state?.objectId}`, (res) => {
        setTrainingPopupLoader(false);
        if (res.isSuccess) {
          toast.success(res.data.message ? res.data.message : "xAgent trained successfully");
          navigation('/train', 4, 3, false, location?.state?.uploadedFile, true);
        } else {
          toast.error(res.error.message ? res.error.message : "Training failed");
        }
      });
    });
  };

  const reconfigBtn = () => {
    navigation('/config', 1, 0, false, location?.state?.uploadedFile, location?.state?.isTrained);
  };
  const isUrlValid = (url) => {
    const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    if (errorURLs.includes(url)) return false
    return urlRegex.test(url);
  };

  const deleteUrlInput = (index) => {
    const updatedURLs = [...scrapedURLs];
    updatedURLs.splice(index, 1);
    setScrapedURLs(updatedURLs);

    setSelectedUrls((prevSelected) => prevSelected.filter((i) => i !== index));

    // Adjust selection indices after deletion
    setSelectedUrls((prevSelected) =>
      prevSelected
        .filter((i) => i !== index) // Remove the deleted index
        .map((i) => (i > index ? i - 1 : i)) // Adjust remaining indices
    );
  };

  const fetchWebsiteUrls = async (urlType) => {

    let url = '';
    if (urlType === 'sitemap') {
      url = siteMapUrl;
      setIsLoaderSitemap(true);
    } else {
      url = websiteUrl;
      setIsLoaderWebsiteUrl(true);
    }
    if (!url?.trim() || url === "") {
      toast.error("Please enter a URL");
      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false);
      return;
    } else if (!isUrlValid(url)) {
      toast.error("Please enter a valid URL");
      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false);
      return;
    }
    ApiRequest.websiteURls({ [urlType === 'sitemap' ? 'sitemapURL' : 'websiteURL']: url }, { id: location.state.objectId }, (res) => {

      setIsLoaderWebsiteUrl(false);
      setIsLoaderSitemap(false)
      if (res.isSuccess) {
        toast.success(res.data?.message ? res.data.message : "Urls fetched successfully");
        setScrapedURLs(res.data?.scrapedURLs ?? []);
        setUrlsFetched(true);

      } else {
        toast.error(res.error?.message ? res.error.message : "Urls fetch failed")
      }
    });
  };

  const saveAllUrls = async () => {
    const invalidUrls = scrapedURLs?.filter(url => !isUrlValid(url));
  
    if (invalidUrls?.length > 0) {
      toast.error("Please enter correct URL");
      return false;
    }
  
    return new Promise((resolve) => {
      ApiRequest.saveUrlsForTrain({ baseWebsiteURL: websiteUrl ? websiteUrl : siteMapUrl || "", selectedWebsiteURLS: (scrapedURLs && scrapedURLs.length ? scrapedURLs : []) }, { id: location.state.objectId }, (res) => {
        if (res.isSuccess) {
          if (scrapedURLs?.length > 0) {
            toast.success(res.data.message ? res.data.message : "Urls fetched successfully");
          }
          setUrlsFetched(true);
          resolve(true);
        } else {
          toast.error(res.error.message ? res.error.message : "Urls fetch failed");
          resolve(false);
        }
      });
    });
  };
  

  const ChatXAgent = () => {
    navigate(`/patterns/${id}/${name}/agent-creation/infer/llm`, { state: { id: id, deployedURL: location.state?.deployedURL, objectId: location?.state?.objectId, configForm: location?.state?.configForm, ...location?.state } })
  };
  const previousBtn = () => {
    navigation('/protect', 3, 2, false, location?.state?.uploadedFile, location?.state?.isTrained);
  };
  const deleteAllLinks = () => {
    ApiRequest.saveUrlsForTrain({ baseWebsiteURL: "", selectedWebsiteURLS: [] }, { id: location.state.objectId }, (res) => {

      if (res.isSuccess) {
        if(scrapedURLs?.length > 0){
        toast.success( "Website links deleted successfully");
        setScrapedURLs([]);
        }

      } else {
        toast.error( "Website links deleted failed")
      }
    });
  
  };
  const handleUrlChange = (newValue, index) => {
    const updatedURLs = [...scrapedURLs];
    updatedURLs[index] = newValue;
    setScrapedURLs(updatedURLs);
  };



  const handleSelectAll = () => {
    if (selectedUrls.length === scrapedURLs.length) {
      // Deselect all if all are already selected
      setSelectedUrls([]);
    } else {
      // Select all URLs
      const allIndexes = scrapedURLs.map((_, index) => index);
      setSelectedUrls(allIndexes);
    }
  };

  const handleCheckboxToggle = (index) => {
    setSelectedUrls((prevSelected) => {
      const updatedSelected = [...prevSelected]; // Create a copy of the state
      if (updatedSelected.includes(index)) {
        // Deselect if already selected
        return updatedSelected.filter((i) => i !== index);
      } else {
        // Select the checkbox
        return [...updatedSelected, index];
      }
    });
  };

  const getTooltipBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return darkMode ? "white !important" : "#314B87 !important";
      case "success":
        return darkMode ? "white !important" : "#57A846  !important";
      default:
        return themeColor;
    }
  };

  const scrapedURLInputs = scrapedURLs?.map((url, index) => {
    const inputStyled = `rounded-md h-[25px] w-full ${isPreview ? "w-[100%]" : "w-[50%]"} mt-[20px]  ${isUrlValid(url) ? "text-[#0F0F0F]" : "text-red-500"}
          ${darkMode ? `bg-transparent placeholder:placeholder-gray-100 ${!isUrlValid(url) ? 'text-red-500' : 'text-white focus:border-[#ffff]'}`
          : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}`;

    const isSelected = selectedUrls.includes(index); // Check if the URL is selected
    
    

    return (
      <div className="flex gap-[20px] pt-20px items-center" key={index}>
        {isSelected ? (
          <MdCheckBox
            style={{ marginTop: "20px", fontSize: "30px",  cursor: "pointer", color: darkMode ? "white" : "black" }}
            onClick={() => handleCheckboxToggle(index)}
          />
        ) : (
          <MdCheckBoxOutlineBlank
            style={{ marginTop: "20px", fontSize: "30px", cursor: "pointer", color: darkMode ? "white" : "black" }}
            onClick={() => handleCheckboxToggle(index)}
          />
        )}
        <input
          className={inputStyled}
          value={url}
          onChange={(e) => handleUrlChange(e.target.value, index)}
          style={{ borderColor: darkMode && !isUrlValid(url) ? "red" : darkMode ? "white " : (isUrlValid(url) ? "#0F0F0F" : "red") }}
        />
        <Icon sx={{ cursor: "pointer", marginTop: "20px", color: darkMode ? "white !important" : "" }} fontSize="small" onClick={() => setIsPreview(true)}>visibility</Icon>

        <Icon sx={{ cursor: "pointer", marginTop: "20px", color: darkMode ? "white !important" : "" }} fontSize="small" onClick={() => deleteUrlInput(index)}>delete</Icon>
      </div>
    );
  });


  const sortedScrapedURLInputs = scrapedURLInputs?.sort((a, b) => {
    const urlA = scrapedURLs?.find((url, index) => !isUrlValid(url) && index === scrapedURLInputs?.indexOf(a));
    const urlB = scrapedURLs?.find((url, index) => !isUrlValid(url) && index === scrapedURLInputs?.indexOf(b));
    return urlA && !urlB ? -1 : urlB && !urlA ? 1 : 0;
  });
  const buttonStyles = {
    color: "white !important",
    borderRadius: "4px !important",
    padding: `${isMobile ? "5px 10px !important" : (isTablet ? "5px 10px !important" : "5px 18px !important")}`,
    textAlign: "center"
  };

  const handleDeleteSelected = () => {
    setScrapedURLs(scrapedURLs.filter((_, index) => !selectedUrls.includes(index))); // Remove selected URLs
    setSelectedUrls([]); // Clear the selected URLs after deletion
  };

  const fileUrl = "";
  // const fileUrl = "https://storage.googleapis.com/intellixcore-frontend-qa/uploads/IntellixcoreWidgetAssets/default-icons/power.docx"
  // const fileUrl = "https://storage.googleapis.com/intellixcore-frontend-qa/uploads/IntellixcoreWidgetAssets/default-icons/power.xlsx"
  // const fileUrl = "https://storage.googleapis.com/intellixcore-frontend-qa/uploads/IntellixcoreWidgetAssets/default-icons/power.pptx"
  // const fileUrl = "https://storage.googleapis.com/intellixcore-frontend-qa/uploads/IntellixcoreWidgetAssets/default-icons/hr_doc.pdf"

  const [selectedOption, setSelectedOption] = useState('crawl');  // Default selection
  const options = [
    { label: 'Crawl', value: 'crawl' },
    { label: 'Sitemap', value: 'sitemap' },
  ];

  useEffect(() =>{
    setWebsiteUrl(""); setSiteMapUrl("");
  },[selectedOption])


  return (
    <div style={{padding: windowSize.innerWidth <= 600 ? "0px" : "0px 30px" }}>
      
      {isFilePreview ?
      <>
      <div className="w-full pt-[10px] px-[15px] pb-[15px]">
          <div className="flex justify-end">

          {/* <div className="text-xl mt-1 font-semibold" 
                      style={{ color: darkMode ? "white" : "black"}}>
                    File Name
          </div>   */}
          <FaRegWindowClose
                onClick={() => setIsFilePreview(false)}
                className={`font-semibold text-xl ${darkMode ? "text-white" : "text-red-500"} cursor-pointer`}
            />
          </div>
                
      </div>
      <div>
          {/* <ViewerPDF
            url={"/hr_doc.pdf"}    // URL or path to the fil
          /> */}

        {/* <FileViewer
              fileType={"pptx"}  // 'pdf', 'docx', 'pptx', 'xlsx', etc.
              filePath={"/power.pptx"}  // Path or URL to the file
            /> */}
            test
      {/* <DocViewer
            documents={[{ uri: encodeURIComponent(fileUrl)}]}
            pluginRenderers={DocViewerRenderers}
          /> */}
        </div> 
      </>     
      : 
      <>
      <TabsCustomized  activeTab={backActiveTab} backActiveTab={(e)=> setBackActiveTab(e)} arr={["FILE UPLOAD", "WEBSITE"]} />
       
       <div style={{ width: "100%", height: "calc(100vh - 394px)", paddingRight: "5px", overflowY: "auto" }}>      
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {backActiveTab === 1 ?
        <>
        <Grid item xs={12} sm={12} md={isPreview ? 6 : 12}>
          <Box className='w-full mt-[20px]'>
            <div  style={{ minHeight: "calc(100vh - 422px)" }}  className="mt-2  w-full flex justify-center rounded-xl">
              <div className="w-full pt-[10px] px-[15px] pb-[15px]">  
          
          {/* <p className={`text-xl font-semibold text-[#0F0F0F] ${darkMode ? "text-white" : "text-black"}`}>Website</p> */}

          <div className="mt-4 w-full flex gap-[4px] flex-row flex-wrap justify-between">

            <Box sx={{ width: windowSize.innerWidth < 600 ? "100%" : "48%" }} >
              
              <div className={`flex xs:flex gap-[4px] sm:flex gap-[4px] md:gap-[15px] lg:gap-[15px] items-center ${isPreview && "justify-end"} `}>
              
              <div className="w-full">
                <div className="flex items-center">
                  <label className={`text-sm text-[#0F0F0F] font-medium ${darkMode ? "text-white" : "text-black"}`}>
                    Scraped URL
                  </label>
                  <Tooltip
                    text="Provide the link to the sitemap of your website for accessing all links within the website."
                    uniqueId="siteMapTooltip"
                    color={getColorVlue(themeColor)}
                  />
                </div>

                <div>
                  <CustomRadioButtons options={options}
                    defaultValue={selectedOption}   // Default selected option
                    onSelectionChange={(newValue)=> setSelectedOption(newValue)}
                  />
                </div>
              </div>
              
              
              
              {/* <div className="w-full">
              <div className="flex items-center">
                <label className={`text-sm text-[#0F0F0F] font-medium ${darkMode ? "text-white" : "text-black"}`}>Crawl</label>
                <Tooltip text="Provide the base link to crawl all the links starting with this URL, ignoring files." uniqueId="crawlTooltip"
                  color={getColorVlue(themeColor)}
                >
                </Tooltip>
              </div>
              <div className="flex items-center space-x-2">
                <input
                    className={`flex-grow rounded-md h-[25px] text-[#0F0F0F] border placeholder:flex justify-center text-[13px] 
                    ${darkMode ? "bg-transparent text-white border-[#ffff] focus:border-[#ffff] placeholder:placeholder-gray-100" : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}
                    `}
                  placeholder="https://www.website.com"
                  value={websiteUrl}
                  onChange={(e) => setWebsiteUrl(e.target.value)}
                  disabled={siteMapUrl?.length > 0}

                />
                <MDButton color={themeColor} variant="gradient" size="small" 
                    style={{ width: "135px", fontSize: `${isMobile ? "6px" : (isTablet ? "10px" : isMediumLarge ? "11px" : "13px")}`, }} 
                    sx={buttonStyles} 
                    disabled={siteMapUrl?.length > 0}
                  onClick={() => fetchWebsiteUrls('website')}>{isLoaderwebsiteUrl ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'FETCH LINKS'} </MDButton>
              </div>
              </div> */}
              </div>
            </Box>

            <Box sx={{ width: windowSize.innerWidth < 600 ? "100%" : "48%" }} >
              <div className="flex justify-end xs:flex gap-[4px] sm:flex gap-[4px] md:gap-[15px] lg:gap-[15px]">
              <div className="w-full">
                <div className="flex items-center">
                  <label className={`text-sm text-[#0F0F0F] font-medium ${darkMode ? "text-white" : "text-black"}`}>
                  {selectedOption === "crawl" ? "Crawl" : "Submit Sitemap"}
                  </label>
                  <Tooltip
                    text={selectedOption === "crawl" ? 
                      "Provide the base link to crawl all the links starting with this URL, ignoring files"
                      : "Provide the link to the sitemap of your website for accessing all links within the website"
                    }
                    uniqueId={selectedOption === "crawl" ? "crawlTooltip" : "siteMapTooltip"}
                    color={getColorVlue(themeColor)}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  {/* <input
                    className={`flex-grow rounded-md h-[25px] text-[#0F0F0F] border placeholder:flex justify-center text-[13px] 
                    ${darkMode ? "bg-transparent text-white border-[#ffff] focus:border-[#ffff] placeholder:placeholder-gray-100" : " border-[#0F0F0F] focus:border-[#0F0F0F] placeholder-gray-200 "}
                    `}
                    placeholder="https://www.website.com/sitemap.xml"
                    value={siteMapUrl}
                    onChange={(e) => setSiteMapUrl(e.target.value)}
                    disabled={websiteUrl?.length > 0}
                  /> */}

                    <InputFieldComponent
                            fi={{
                              type: 'text', 
                              name: (selectedOption === "crawl") ? "websiteUrl" : "siteMapUrl", 
                              placeholder: (selectedOption === "crawl") ? "https://www.website.com" : "https://www.website.com/sitemap.xml"
                              }}
                            config={selectedOption === "crawl" ? (websiteUrl || "") :  (siteMapUrl || "")}
                            isNoLabel={true}
                            setConfig={(e) => { 
                              selectedOption === "crawl" ? setWebsiteUrl(e.target.value)  :  setSiteMapUrl(e.target.value)  
                            } }
                    />
                  <MDButton
                    color={themeColor}
                    variant="gradient"
                    size="small"
                    isNoWidth={true}
                    style={{
                      width: "85px",
                      height: "44px",
                      fontSize: `${isMobile ? "6px" : isTablet ? "10px" : isMediumLarge ? "11px" : "13px"}`,
                    }}
                    sx={buttonStyles}
                    onClick={() => {selectedOption === "crawl" ? fetchWebsiteUrls('website') : fetchWebsiteUrls('sitemap')}  }
                  >
                    {(isLoaderSitemap || isLoaderwebsiteUrl) ? 
                    <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> 
                    : 'FETCH LINK'}
                  </MDButton>
                </div>
              </div>
              </div>
            </Box>
          </div>


           {/* ====== Divider */}

          {/* <div className="mt-[30px] border-t border-gray-300 w-[100%]"></div> */}


          {/* Open URL */}

          <MDBox sx={{ marginTop: "30px",padding: "15px", marginTop: "15px", 
                      border: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, borderRadius: "7px" }}>
              {/* <Grid style={{ width: "100%" }}> */}
                <div className="flex w-full justify-between"  
                      style={{ borderBottom: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, paddingBottom: "5px", marginBottom: "5px" }}>
                  <div className="flex items-center">
                    {scrapedURLs?.length === 0 ? (
                      <IoIosSquareOutline
                      style={{fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                      onClick={() => handleSelectAll()}
                    />
                    ) :
                    (selectedUrls?.length === scrapedURLs?.length) ? (
                          <MdCheckBox
                            style={{ fontSize: "30px", marginRight: "10px",  cursor: "pointer", color: darkMode ? "white" :  getColorVlue() }}
                            onClick={() => handleSelectAll()}
                          />
                        ) : (
                          <IoIosSquareOutline
                            style={{fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                            onClick={() => handleSelectAll()}
                          />
                    )}
                    <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }} style={{ color: darkMode ? "white" : "black" }}>
                      Included Links (Optional)
                    </MDTypography>
                    {/* <Tooltip uniqueId={"sqlprompt&query"} text={`A set of sample natural language prompts and equivalent SQL query pairs that can be used to improve the model's responses, directly tailored to your database schema and tables.`} color={getColorVlue(themeColor)} /> */}
                  </div>
                  <div className="flex items-center" style={{marginTop:"-4px"}}>
                    {/* <MDButton size="small" sx={{
                      color: darkMode ? "#ec1313 !important" : "#FF0000!important", fontSize: "10px", padding: "7px !important",
                      display: "flex", gap: "4px", padding: "7px !important", fontSize: "10px !important", backgroundColor: darkMode ? "transparent !important" : "", marginTop: "4px"
                    }}
                      onClick={deleteAllInputs}>Delete</MDButton> */}

                    <Icon
                        sx={{ marginLeft: "15px", cursor: "pointer", color: darkMode ? "white !important" : ""}}
                        fontSize="small"
                        onClick={() => 
                          (selectedUrls?.length === scrapedURLs?.length && scrapedURLs?.length !== 0)
                          ? deleteAllLinks() :  handleDeleteSelected()}
                      >
                        delete
                      </Icon>
                    <AddBoxIcon color={darkMode ? "white" : themeColor} className="mr-[5px] mt-[2px] pl-[5px] cursor-pointer flex justify-center items-center" fontSize="medium" onClick={() => addUrlInput()} />
                  </div>
                </div>


                <div>
                
                {scrapedURLs?.map((query, index) => {

                const isSelected = selectedUrls?.includes(index); // Check if the URL is selected
                  
                  return(
                      <MDBox key={index} className={"flex w-full flex-wrap justify-between"} sx={{ display: "flex", marginTop: "10px" }}>
                    {/* gap: (isLargeMobile || isSmallMobile) ? "20px" : isMdLoptop ? "138px" : "120px" */}
                    <div style={{ width: "100%", display: 'flex', alignItems: "center"  }}>

                        {isSelected ? (
                          <MdCheckBox
                            style={{ fontSize: "30px", marginRight: "10px",  cursor: "pointer", color: darkMode ? "white" : getColorVlue() }}
                            onClick={() => handleCheckboxToggle(index)}
                          />
                        ) : (
                          <IoIosSquareOutline
                            style={{  fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                            onClick={() => handleCheckboxToggle(index)}
                          />
                        )}

                      <InputFieldComponent
                            fi={{type: 'text', name: "scrapedURLs", placeholder: "Scraped URL"}}
                            config={scrapedURLs[index] || ""}
                            isNoLabel={true}
                            setConfig={(e) => { handleUrlChange(e.target.value, index)  } }
                          />
                    </div>
                      </MDBox>
                  )    
              })}

                </div>

              {/* </Grid> */}
          </MDBox>          

          {/* Close URL */}

          </div>
            </div>
          </Box>
        </Grid>

        {isPreview &&        
        <Grid item xs={12} sm={12} md={isPreview ? 6 : 12}>
          <Box className='w-full mt-[20px]'>
          <div  style={{ minHeight: 
            scrapedURLs?.length && darkMode ? "428px" 
            : scrapedURLs?.length && !darkMode ? "435px"  
            : "calc(100vh - 422px)" }} 
            className="mt-2 border-2 border-dashed  w-full flex justify-center rounded-xl">
              <div className="w-full pt-[10px] px-[15px] pb-[15px]">
                <div className="flex justify-end">
                <FaRegWindowClose
                      onClick={() => setIsPreview(false)}
                      className={`font-semibold text-xl ${darkMode ? "text-white" : "text-red-500"} cursor-pointer`}
                  />
                </div>
                
              </div>
          </div>
          </Box>
        </Grid>
        }
        </>
        :

        //  =========================== TRAINING ====================
        <>
        <Grid item xs={12} sm={12} md={isPreview ? 6 : 12} className="w-full">
          {/* <p className={`text-xl font-semibold ${isMobile ? "0px" : "pl-[23px]"} ${darkMode ? "text-white" : "text-black"}`}>Document upload</p> */}
          <Box className='w-full mt-[20px]'>
            <div  style={{ minHeight: "calc(100vh - 422px)" }}  className="w-full">
            <MDTypography style={{ color: darkMode ? "white" : "#5B5B5B", fontSize: "14px",fontWeight:"600" }}>Upload File</MDTypography>
        
              <div style={{ border: "1px dashed #CCCCCC", borderRadius: "10px", 
                    marginTop:"10px", background: !darkMode && loader && "#F5F8FC"}}>
          <form autoComplete="off">
            <div className="flex flex-row w-full flex-wrap justify-between mt-2">
              <div className="flex flex-row my-2 w-full" style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                <div className="text-primary-blue w-full flex flex-col" style={{ justifyContent: "center", alignItems: "center" }}>
                  {location?.state?.isTrained ?
                    <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                      <div style={{ textAlign: "center" }}>Agent trained successfully. Please go to inference</div>
                      {location?.state?.deployedURL && <div className="mt-2" style={{ textAlign: "center" }}>
                        <span className="font-semibold"> Deployed URL: </span>{location.state?.deployedURL}
                        </div>}
                    </div>
                    : <div style={{ color: darkMode ? "white" : "#5B5B5B", fontSize: "14px",fontWeight:"400" }}>
                      Training has not started yet. Please upload file and click <span className="font-semibold">Train</span>
                      </div>
                  }
                  {/* {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
                    fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
                    <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                      Only one document can be stored in a model. Please delete already uploaded item to save new file.
                    </div> : null} */}
                </div>
              </div>
            </div>

          </form>

          {/* {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
            fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
            <div></div>
            :
            <> */}
              <div>
                <TabUpload isNoHeight={true} supportedFileTypes="PDF, DOCX, TXT" uploadedFile={uploadedFile} loader={loader} />
              </div>
            {/* </> */}
          {/* } */}
              </div>
              
              
              {/* Uploaded Files */}

            {/* <div className="mt-4 flex flex-grow justify-center w-full  flex-wrap">
              {location.state?.uploadedFile?.length ? (
                      location.state?.uploadedFile?.map((afl) => (
                        <Tag
                          key={afl.fileName}
                          name={afl?.fileName}
                          onClick={() => console.log("")}
                          isDelete={true}
                          onDelete={(e) => {
                            e.stopPropagation();
                            setModalDelOpen({ flag: true, document: afl });
                          }}
                          onPreview={(e) => {
                            e.stopPropagation();
                            setIsPreview(true)
                          }}
                          className="mt-4 max-w-max"
                          isLogo={true}
                          // isPreview={true}
                          isWebToolTrain={true}
                        />
                      ))
                    ) : null}
            </div> */}

            {location.state?.uploadedFile?.length ?
            <div style={{ borderRadius: "8px", border: "1px solid #CCCCCC", margin: "20px 0px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <MDTypography sx={{ width: "100%", fontSize: "14px", fontWeight: "600", 
                  color: darkMode ? "white" : "#5B5B5B", borderBottom: "1px solid #CCCCCC", paddingBottom: "10px" }}>
                    Uploaded Document
                 </MDTypography>
                <div></div>
              </div>
              <div className="  w-full  py-[5px] px-[10px]">
                {location.state?.uploadedFile?.length ?
                  location.state?.uploadedFile?.map((afl, ind, arr) => (
                      <div className="flex items-center w-full justify-between pb-[10px]">
                      <div key={ind} className="flex items-center gap-[10px]">
                        <div className="flex gap-[10px] ">
                          <FileIcon fileName={afl?.fileName} />
                          <div className="flex flex-col">
                            <MDTypography sx={{ paddingTop: "5px", fontSize: "16px", fontWeight: "500", color: darkMode ? "white" : "#101828" }}>
                              {afl?.fileName}
                            </MDTypography>
                            {/* <MDTypography sx={{ fontSize: "12px", fontWeight: "400", color: darkMode ? "white" : "#475467" }}>
                              {(uploadedFileData?.fileSize / 1024).toFixed(2)} KB
                            </MDTypography> */}
                          </div>
                        </div>
                        </div>
                        <div className="flex items-center gap-[10px] pt-[5px]">
                          {/* <MdOutlineVisibility
                            style={{ color: darkMode ? "white" : "black", height: "24px", width: "24px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              previewFile(afl); 
                            }}
                          /> */}
                          

                          <Icon
                            sx={{ marginLeft: "15px", cursor: "pointer", color: darkMode ? "white !important" : ""}}
                            fontSize="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setModalDelOpen({ flag: true, document: afl });
                            }}
                            >
                            delete
                          </Icon>
                        </div>
                        </div>
                    ))
                  : null}

              </div>
            </div> : null }


            </div>
          </Box>
        </Grid>

        {isPreview &&        
        <Grid item xs={12} sm={12} md={isPreview ? 6 : 12}>
          <Box  className='w-full mt-[20px]'       
                onClick={() => setIsInputVisible(true)}>
          <div style={{ minHeight: "calc(100vh - 422px)" }}  className="mt-2 border-2 border-dashed  w-full flex justify-center rounded-xl">
              <div className="w-full pt-[10px] px-[15px] pb-[15px]">
                <div className="flex justify-end gap-[10px]">
                  <MDButton size="small" sx={{
                      color: darkMode ? "white !important" : "black !important", fontSize: "10px",
                      display: "flex", gap: "4px", fontSize: "13px !important", backgroundColor: darkMode ? "transparent !important" : ""
                      , border: `1px solid ${darkMode ? "white" : "black"}`, padding: "4px !important",
                    }}
                      onClick={(e) => {e.stopPropagation(); 
                      // setIsFilePreview(true)
                      const officeViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(fileUrl)}`;
                      window.open(fileUrl?.includes(".pdf") ? fileUrl : officeViewerUrl, '_blank');
                    }}>
                        <FindInPageIcon fontSize="small" 
                        color={darkMode ? "white !important" : "black !important"} 
                        sx={{ height: "15px", width: "15px" }} />
                        Preview File
                  </MDButton>

                  <FaRegWindowClose
                      onClick={(e) => setIsPreview(false)}
                      style={{ marginTop: "5px" }}
                      className={`font-semibold text-xl ${darkMode ? "text-white" : "text-red-500"} cursor-pointer`}
                  />
                </div>

                <div className="mb-10 mt-4 flex w-full items-center justify-center">
                  <div className="mr-2">
                  <FileIcon fileName={"hmz.pdf"} />
                  </div>
                  <div className="text-xl mt-1 font-semibold" 
                      style={{ color: darkMode ? "white" : "black"}}>
                    File Name
                  </div>
                </div>


                <div className="flex justify-center w-full flex-row flex-wrap">
                    {["Tag 1","Tag 2","Tag 3","Tag 4"].map((afl) => (
                        <Tag
                          key={afl}
                          name={afl}
                          onClick={() => console.log("")}
                          isDelete={true}
                          onDelete={(e) => {
                            e.stopPropagation();
                            setModalDelOpen({ flag: true, document: afl });
                          }}
                          className="mt-4 max-w-max"
                          isLogo={false}
                          isWebToolTrain={true}
                        />
                      ))
                    }
                  </div>

                {isInputVisible && (
                  <div className="flex mt-4 w-full justify-center">
                  <TextField
                    variant="standard"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => console.log("Wah")}
                    autoFocus
                    sx={{ minWidth: "250px",
                      "& .MuiInput-underline:after": {
                          borderBottomColor: `${getTooltipBackgroundColor()}` , // Focused underline color
                        },
                     }}
                    placeholder="Enter tag..."
                  />
                  <FaRegWindowClose
                      onClick={(e) => {e.stopPropagation(); setIsInputVisible(false)}}
                      className={`font-semibold text-xl ${darkMode ? "text-white" : "text-red-500"} cursor-pointer`}
                  />
                  </div>
                )}

                
              </div> 
          </div>
          </Box>
        </Grid>
        }
        </>
        }
      </Grid>
      </div>
      </>
      }


      {/*   ========== Button =============== */}
      {!isFilePreview &&
      <div className="flex justify-center w-full mt-auto">
        <MDBox mt={windowSize.innerWidth > 600 ? 4 : 1} mb={windowSize.innerWidth > 600 && 1} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
          <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={trainingPopupLoader} 
                    isNoWidth={windowSize.innerWidth <= 600 && true}
                    onClick={() => { previousBtn() }} variant="outlined" 
                    color={darkMode ? "white" : themeColor}>
            Previous
          </MDButton>
          <MDButton style={{ width: "max-content", minWidth: "125px" }}
            disabled={trainingPopupLoader}
            onClick={() => { if (!isFeedbackPopupHandle) { TrainXAgent(false) } else { setModalOpen(true) } }}
            variant="gradient" color={themeColor} fullWidth>
            {trainingPopupLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'train'}
          </MDButton>
          {
            location.state?.page === 4 &&
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={trainingPopupLoader} onClick={() => { ChatXAgent() }} variant="gradient" color={themeColor} fullWidth>
              Chatbot
            </MDButton>
          }

        </MDBox>
      </div>
      }


      {modalDelOpen?.flag && (
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: modalDelOpen.document })}>
          <div style={{ textAlign: "center" }} className="flex flex-row w-full justify-center items-center text-xl font-semibold mb-4">
            Are you sure you want to delete "{modalDelOpen?.document?.fileName}"? Deleted document will be lost
          </div>

          <div className="mt-6 flex flex-row w-full justify-center items-center text-xl font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: {} })} variant="gradient" color={"error"} fullWidth>
              No
            </MDButton>
            <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false} onClick={deleteDocument} variant="gradient" color={themeColor} fullWidth>
              Yes
            </MDButton>
          </div>
        </Modal>
      )}


      {modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
          <div className="flex flex-row w-full justify-center items-center mb-4" >
            LLM will be retrained. Do you wish to keep manual feedback previously provided?
          </div>

          <div className="mt-6 flex flex-row w-full justify-center items-center font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={trainingLoader}
              onClick={() => { TrainXAgent(false) }} variant="gradient" color={"error"} fullWidth>
              {trainingLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "No"}
            </MDButton>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={feedbackLoader} onClick={() => { TrainXAgent(true) }}
              variant="gradient" color={themeColor} fullWidth>
              {feedbackLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </div>
        </Modal>
      }
    </div>
  );
}

export default ChatbotTrain;