/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDStepperTwo from "examples/Stepper/Stepper";


// Dashboard components
import { useEffect, useState } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { Facebook, Instagram , List, BulletList} from 'react-content-loader'
import { Images } from "libraries/Images";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import MDStepper from "components/MDStepper";
import ChatbotConfig from "../chatbot/config";
import ChatbotProtect from "../chatbot/protect";
import ChatbotDeployment from "../chatbot/deployment";
import ChatbotTrain from "../chatbot/train";

import FeatureExtractionConfig from "../featureExtraction/config";
import FeatureExtractionDeployment from "../featureExtraction/deployment";
import FeatureExtractionModel from "../featureExtraction/model";
import FeatureExtractionTrain from "../featureExtraction/train";
import FeatureExtractionResult from "../featureExtraction/result";

import PredictiveAnalysisConfig from "../predictiveAnalysis/config";
import PredictiveAnalysisDeployment from "../predictiveAnalysis/deployment";
import PredictiveAnalysisModel from "../predictiveAnalysis/model";
import PredictiveAnalysisTrain from "../predictiveAnalysis/train";
import PredictiveAnalysisResult from "../predictiveAnalysis/result";

import RecommendationConfig from "../recommendation/config";
import RecommendationDeployment from "../recommendation/deployment";
import RecommendationModel from "../recommendation/model";
import RecommendationTrain from "../recommendation/train";

import ImageToTextConfig from "../imageToText/config";
import ImageToTextInference from "../ImageToTextXAgent";

import TextToSpeechConfig from "../textToSpeech/config";
import TextToSpeechInference from "../TextToSpeechXAgent";
import SpeechToTextConfig from "../SpeechToText/config";
import SpeechToTextInference from "../SpeechToTextXAgent";

import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import MDTypography from "components/MDTypography";

import Constants from 'libraries/Constants';
import useScreenWidth from "libraries/ScreenSizeHook";
import ChatbotTool from "../chatbot/sqlTool";
import WorkForceStrategy from "layouts/workforce-strategy/business-configuration/workForce";


function CreationFlowWrapper() {
  const navigate = useNavigate();
  const location = useLocation();
  const {id, name} = useParams();
  const [windowSize, getWindowSize] = useScreenWidth();

  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  const isProUser = false;

  const stepsChatbot = isProUser ? ['Config', 'Deployment', 'Train'] : ['Config','Tools', 'Protect', 'Train'];
  const stepsFive = isProUser ? ['Config', windowSize.innerWidth <= 600 ? 'Deploy' : 'Deployment', 'Model', 'Train', 'Result'] 
      : ['Config', 'Model', 'Train', 'Result'];
  const stepsFour = isProUser ? [ 'Config', windowSize.innerWidth <= 600 ? 'Deploy' : 'Deployment', 'Model', 'Train'] 
      : ['Config', 'Model', 'Train'];    
  const stepsTwo = isProUser ? [ 'Config', windowSize.innerWidth <= 600 ? 'Deploy' : 'Deployment', 'Inference']
      : ['Config', 'Inference']; 
  
  const ChatbotIcons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  const FiveStageRoutes = Constants.featureExtractionRoute === name || Constants.predictiveAnalysisRoute === name;
  const FourStageRoutes = Constants.recommendationRoute === name;
  const TwoStageRoutes = Constants.imageToTextRoute === name || Constants.textToSpeechRoute === name || Constants.speechToTextRoute === name;

  const formatString = (input) => {
    return input
        .split('-')              // Split the string by hyphens
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))  // Capitalize the first letter of each word
        .join(' ');              // Join the words with spaces
  }

  return (
    <div  style={{ 
      background: darkMode ? "rgba(46, 52, 73, 1)" : "white",
      overflowY: "hidden",
      height: "calc(100vh)"
     }}>
    <DashboardLayout>
    <DashboardNavbar />

    <div style={{ 
      backgroundImage: `url(${darkMode ? Images.flowBgDark : Images.flowXAgents})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRadius: "10px",
      boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.3)",
      overflowY: "auto",
      height: "calc(100vh - 115px)"
     }}>
      
      <div style={{
        // borderRadius: "10px",
        // boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.3)",
      }}>
    {name?.length ? 
    <MDTypography sx={{ fontSize: "22px", fontWeight: "600",padding:"15px 15px 0px 35px" }}>
                {name === "llm" ? "LLM" : formatString(name) }
    </MDTypography> : null}
      <Grid container spacing={2} style={{ overflowY: "auto", height: "calc(100vh - 150px)" }}>
        <MDStepperTwo 
          steps={FiveStageRoutes ? stepsFive 
                    : FourStageRoutes ? stepsFour 
                    : TwoStageRoutes ? stepsTwo 
                    : stepsChatbot
                  } 
          isBigTable={location?.state?.step === 1 && Constants.chatbotRoute === name}        
          activeStep={location?.state?.step ?? 0}
          icons = {ChatbotIcons}
          
          >

                {(location?.state?.step === 0 && Constants.featureExtractionRoute === name) ? <FeatureExtractionConfig />
                // : (location?.state?.step === 1 && Constants.featureExtractionRoute === name) ? <FeatureExtractionDeployment />
                : (location?.state?.step === 1 && Constants.featureExtractionRoute === name) ? <FeatureExtractionModel />
                : (location?.state?.step === 2 && Constants.featureExtractionRoute === name) ? <FeatureExtractionTrain />
                : (location?.state?.step === 3 && Constants.featureExtractionRoute === name) ? <FeatureExtractionResult />

                : (location?.state?.step === 0 && Constants.predictiveAnalysisRoute === name) ? <PredictiveAnalysisConfig />
                // : (location?.state?.step === 1 && Constants.predictiveAnalysisRoute === name) ? <PredictiveAnalysisDeployment />
                : (location?.state?.step === 1 && Constants.predictiveAnalysisRoute === name) ? <PredictiveAnalysisModel />
                : (location?.state?.step === 2 && Constants.predictiveAnalysisRoute === name) ? <PredictiveAnalysisTrain />
                : (location?.state?.step === 3 && Constants.predictiveAnalysisRoute === name) ? <PredictiveAnalysisResult />

                : (location?.state?.step === 0 && Constants.chatbotRoute === name) ? <ChatbotConfig />
                : (location?.state?.step === 2 && Constants.chatbotRoute === name) ? <ChatbotProtect />
                // : (location?.state?.step === 1 && Constants.chatbotRoute === name) ? <ChatbotDeployment />
                : (location?.state?.step === 1 && Constants.chatbotRoute === name) ? <ChatbotTool />
                : (location?.state?.step === 3 && Constants.chatbotRoute === name) ? <ChatbotTrain />

                : (location?.state?.step === 0 && Constants.recommendationRoute === name) ? <RecommendationConfig />
                // : (location?.state?.step === 1 && Constants.recommendationRoute === name) ? <RecommendationDeployment />
                : (location?.state?.step === 1 && Constants.recommendationRoute === name) ? <RecommendationModel />
                : (location?.state?.step === 2 && Constants.recommendationRoute === name) ? <RecommendationTrain />

                : (location?.state?.step === 0 && Constants.imageToTextRoute === name) ? <ImageToTextConfig />
                : (location?.state?.step === 1 && Constants.imageToTextRoute === name) ? <ImageToTextInference />

                : (location?.state?.step === 0 && Constants.textToSpeechRoute === name) ? <TextToSpeechConfig />
                : (location?.state?.step === 1 && Constants.textToSpeechRoute === name) ? <TextToSpeechInference />
                
                : (location?.state?.step === 0 && Constants.speechToTextRoute === name) ? <SpeechToTextConfig />
                : (location?.state?.step === 1 && Constants.speechToTextRoute === name) ? <SpeechToTextInference />

                : null
                }
        </MDStepperTwo>


      </Grid>  
      </div>
      </div>
    </DashboardLayout>
    </div>

  );
}

export default CreationFlowWrapper;
