const popupMessageEnum ={
    success: 'success',
    error: 'error',
    info: 'info'
}

// const modalScreensEnum ={
//     password,
//     profile
// }

const patternTitle ={
    speech: 'Speech to Text Pattern',
    predictive: 'Predictive Analysis Pattern',
    recommendation: 'Recommendation System Pattern',
    chatbot: 'Chatbot Pattern',
    featureExtraction: 'Feature Extraction Pattern',
    language: 'Language Translation Pattern',
};

const patternTitleWithoutPattern ={
    speech: 'Speech to Text',
    predictive: 'Predictive Analysis',
    recommendation: 'Recommendation System',
    chatbot: 'Chatbot',
    featureExtraction: 'Feature Extraction',
    language: 'Language Translation',
};

const predictiveModel ={
    Regression: 'Regression',
    Classification: 'Classification',
};

const chatbotModel ={
    openai: 'OpenAI',
    druidai: 'DruidAI',
    gptinstruct: 'gpt-3.5-turbo-instruct',
    gptturbo: 'gpt-3.5-turbo',
    falcon: 'falcon',
    vicuna: 'vicuna',
    custom: 'custom',
    ner: 'ner',
}

const plans = {
    Free: "Free",
    Basic: "Basic",
    Premium: "Premium",
    Enterprise: "Enterprise",
}

const chatbotAgentStatuses ={
    CONFIGURED: 'CONFIGURED',
    DEPLOYED: 'DEPLOYED',
    MODIFIED: 'MODIFIED',
    TRAINING: 'TRAINING',
    DEPLOYING: 'DEPLOYING',
    READY: 'READY',
}

const featureAgentStatuses ={
    TRAINED: 'TRAINED',
    TRAINING: 'TRAINING',
    CONFIGURED: 'CONFIGURED',
    MODIFIED: 'MODIFIED',
    READY: 'READY',
}

const uploadPreview ={
    source: 'source',
    feature: 'feature',
}
const barChartFilter ={
    storage: 'storage',
    requests: 'requests',
    tokenConsumed: 'tokenConsumed',

}
const gptModelName="gpt-3.5-turbo-instruct"
const pythonVariableNameRegex=/^[a-zA-Z_][a-zA-Z0-9_]*$/
export {
    patternTitleWithoutPattern,
    uploadPreview,
    chatbotModel,
    predictiveModel,
    barChartFilter,
    gptModelName,
    pythonVariableNameRegex
} 
