import { Paths } from "./Route";
import { Images } from "./Images";
import { predictiveModel, chatbotModel } from "./Enums";

// React Icons
import { FaRegUserCircle } from "react-icons/fa";
import { CiVideoOn } from "react-icons/ci";
import { MdOutlinePattern } from "react-icons/md";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoVideocamOutline } from "react-icons/io5";
import { MdOutlineSupportAgent } from "react-icons/md";

class CommonConstants {
   ccBlue = "rgb(8, 33, 82)"
   ccBlueHover = "rgba(8, 33, 82,0.1)"

  //  navigation = [
  //   {
  //     name: "Profile",
  //     path: Paths.dashboard,
  //     icon: <FaRegUserCircle
  //       className={`my-4 text-xl text-white cursor-pointer`}
  //     />,
  //     //  Images.profile
  //     current: true,
  //   },
  //   {
  //     name: "Patterns",
  //     path: Paths.pattern,
  //     icon: <MdOutlinePattern
  //       className={`my-4 text-xl text-white cursor-pointer`}
  //     />,
  //     current: true,
  //   },
  //   {
  //     name: "XAgents", path: Paths.xAgent, icon: <MdOutlineSupportAgent
  //       className={`my-4 text-xl text-white cursor-pointer`}
  //     />, current: true
  //   },
  //   {
  //     name: "How to Tutorials",
  //     path: Paths.tutorial,
  //     icon: <IoVideocamOutline
  //       className={`my-4 text-xl text-white cursor-pointer`}
  //     />,
  //     current: true,
  //   },
  //   {
  //     name: "Help", path: Paths.help, icon: <IoMdHelpCircleOutline
  //       className={`my-4 text-xl text-white cursor-pointer`}
  //     />, current: true
  //   },
  // ];

   xAgents = [
    {
      title: "Behavioral Analytics Pattern",
      description:
        "The Behavioral Analytics Pattern analyzes user interactions in digital platforms using data analytics and machine learning, providing insights for enhancing user experiences and optimizing offerings.",
      icon: Images.behavorial,
      isActive: true,
    },
  ];


  //  Models ----------------

   modelsNormal = [
    { title: "Logistic Regression" },
    { title: "Random Forest" },
    { title: "Decision Tree" },
    { title: "K Nearest Neighbours" },
    { title: "Support Vector Machine" },
    { title: "Linear Regression" },
    { title: "XGBoost Regression" },
    { title: "K-Means Regression" },
    { title: "Random Forest Regression" },
    { title: "Decision Tree Regression" },
  ];

   modelsPredictive = [
    { title: "Linear Regression" },
    { title: "Random Forest Regression" },
    { title: "Decision Tree Regression" },
    { title: "XGBoost Regression" },
    { title: "K-Means Regression" },
    { title: "Logistic Regression" },
    { title: "Random Forest" },
    { title: "K-Nearest Neighbors" },
    { title: "Decision Tree" },
    { title: "Support Vector Machine" },
  ];

   modelsRecommendation = [
    { title: "Collaborative Filtering" },
    { title: "Content-Based Filtering" },
    { title: "Hybrid Filtering" },
  ];


   modelsSpeech = [
    { title: "Whisper" },
    { title: "Hugging Face" },
    { title: "Sentiment-Analysis Bert" },
  ];

  //  UseCases ----------------

   useCasesNormal = [
    {
      icons: Images.forcast,
      title:
        "Predicting Customer behaviour and prefrences to optimise marketing campaign",
    },
    {
      icons: Images.predictCustomer,
      title: "Forecasting energy demand to optimise consumption and cost",
    },
    {
      icons: Images.predictEmplyoee,
      title: "Predicting employee turnover and optimising workforce planning",
    },
  ];

   useCasesPredictive = [
    {
      icons: Images.forcast,
      title: "Predicting customer behaviour and preferences to optimise marketing campaigns",
    },
    {
      icons: Images.predictCustomer,
      title: "Forecasting energy demand to optimise consumption and cost",
    },
    {
      icons: Images.predictEmplyoee,
      title: "Predicting employee turnover and optimising workforce planning",
    },
  ];

   useCasesRecommendation = [
    { icons: Images.recommendation1, title: "E-Commerce Product Recommendations: Recommending related or complementary products to shoppers based on their purchase history and browsing behaviour." },
    { icons: Images.recommendation2, title: "Streaming Services Content Suggestions: Providing personalised movie, TV show, or music recommendations to users based on their viewing and listening history." },
    { icons: Images.recommendation3, title: "News and Content Recommendations: Offering users articles, blog posts, or videos relevant to their interests and previous content consumption." },
  ];

   useCasesChatbot = [
    {
      icons: Images.forcast,
      title:
        "Customer Support and Assistance: Providing instant responses and personalized assistance to customer queries, enhancing user satisfaction",
    },
    {
      icons: Images.predictCustomer,
      title: "E-Commerce Sales and Recommendations: Guiding users through product selections, offering recommendations, and driving sales conversions",
    },
    {
      icons: Images.predictEmplyoee,
      title: "Healthcare Information and Advice: Offering medical information, symptom checking, and health advice to users, improving patient engagement",
    },
  ];

   useCasesSpeech = [
    { icons: Images.textToSpeech1, title: "Converting recorded interviews, meetings, and speeches into written transcripts." },
    { icons: Images.textToSpeech2, title: "Adding captions or subtitles to videos for accessibility and engagement." },
    { icons: Images.textToSpeech3, title: "Analysing customer support calls to extract insights and improve service quality." },
    { icons: Images.textToSpeech4, title: "Translating spoken content in real-time to facilitate multi-lingual communication." },
    { icons: Images.textToSpeech5, title: "Converting spoken content into written form for blog posts, articles, and social media." },
  ];

   patternsConfig = [
    { title: "Config Detail 1" },
    { title: "Config Detail 2" },
    // { title: "Config Detail 3" },
  ];

   tabs = [
    { id: 1, name: "Configure", isDisable: false },
    { id: 2, name: "Deployment", isDisable: true },
    { id: 3, name: "Train", isDisable: true },
  ];

   tabsFeatureExtraction = [
    { id: 1, name: "Configure", isDisable: false },
    { id: 2, name: "Deployment", isDisable: false },
    { id: 3, name: "Model", isDisable: false },
    { id: 4, name: "Train", isDisable: false },
    { id: 5, name: "Results", isDisable: false },
  ];

   tabsWithoutTrain = [
    { id: 1, name: "Configure", isDisable: false },
    { id: 2, name: "Deployment", isDisable: true },
  ];

   tabsChat = [
    { id: 1, name: "Upload File", isDisable: false },
    { id: 2, name: "Form", isDisable: false },
    // { id: 3, name: "Deployment" },
  ];

   userNavigation = [
    { name: "Sign out", id: 3 },
  ];

  // Chatbot Model
   modelsChatbot = [
    { title: chatbotModel.openai, apiName: "OpenAI", id: 1 },
    // { title: chatbotModel.druidai, apiName: "DruidAI", id: 2 },
    // { title: chatbotModel.vicuna, apiName: "vicuna", id: 3 },
  ];

  //  Predictive Model
   predictiveType = [
    { title: predictiveModel.Regression, apiName: "regression", id: 1 },
    { title: predictiveModel.Classification, apiName: "classification", id: 2 },
  ];

   predictiveModelNamesOnRegression = [
    { id: 1, title: "Linear Regression", apiName: "linear_regression" },
    { id: 2, title: "Random Forest Regression", apiName: "random_forest_regression" },
    { id: 3, title: "Decision Tree Regression", apiName: "decision_tree_regression" },
    { id: 4, title: "XGBoost Regression", apiName: "xgboost_regression" },
    { id: 5, title: "K-Means Regression", apiName: "kmeans_regression" },
  ];

   predictiveEvaluationNamesOnRegression = [
    { id: 1, title: "Mean Absolute Error", apiName: "mae" },
    { id: 2, title: "Mean Squared Error", apiName: "mse" },
    { id: 3, title: "R2 Score", apiName: "r2" },
    { id: 4, title: "Root Mean Squared Error", apiName: "rmse" },
  ];

   predictiveModelNamesOnClassification = [
    { id: 1, title: "Logistic Regression", apiName: "logistic_regression" },
    { id: 2, title: "Random Forest", apiName: "random_forest" },
    { id: 3, title: "Decision Tree", apiName: " decision_tree" },
    { id: 4, title: "K-Nearest Neighbors", apiName: " knn" },
    { id: 5, title: "Support Vector Machine", apiName: "svm" },
  ];

   predictiveEvaluationNamesOnClassification = [
    { id: 1, title: "Accuracy", apiName: "accuracy" },
    { id: 2, title: "Precision Score", apiName: "precision" },
    { id: 3, title: "Recall", apiName: "recall" },
    { id: 4, title: "F1 Score", apiName: "f1" },
    { id: 5, title: "Confusion Matrix", apiName: "confusion_matrix" },
    { id: 6, title: "Classification Report", apiName: "classification_report" },
  ];

   recommendationModels = [
    { id: 1, title: "Collaborative Filtering", apiName: "collaborative_filter" },
    { id: 2, title: "Content-Based Filtering", apiName: "content_filter" },
    { id: 3, title: "Hybrid Filtering", apiName: "hybrid_filter" },
  ];

   calMethod = [
    { id: 1, title: "Pearson Correlation", apiName: "pearson" },
    { id: 2, title: "Cosine Similarity", apiName: "cosine" },
  ];


   labelInput = `flex w-full h-full select-none pointer-events-none absolute left-0 font-normal 
  !overflow-visible truncate peer-placeholder-shown:text-primary-blue leading-tight 
  peer-focus:leading-tight peer-disabled:text-transparent 
  peer-disabled:peer-placeholder-shown:text-primary-blue transition-all 
  -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] 
  before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 
  before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent 
  before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l 
  peer-focus:before:border-l-2 before:pointer-events-none before:transition-all 
  peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow 
  after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 
  peer-placeholder-shown:after:border-transparent after:rounded-tr-md 
  after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 
  after:pointer-events-none after:transition-all peer-disabled:after:border-transparent 
  peer-placeholder-shown:leading-[3.75] text-primary-blue peer-focus:text-primary-blue
  before:border-primary-blue peer-focus:before:!border-primary-blue after:border-primary-blue
  peer-focus:after:!border-primary-blue`


   classInput = `peer w-full h-full bg-transparent text-primary-blue font-sans font-normal outline outline-0 focus:outline-0 
   transition-all placeholder-shown:border placeholder-shown:border-primary-blue
  placeholder-shown:border-t-primary-blue border focus:border-2 
  focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-primary-blue
  focus:border-primary-blue`

   classTextArea = `peer h-full min-h-[100px] w-full resize-none rounded-[7px] border 
  border-primary-blue border-t-transparent bg-transparent 
  px-3 py-2.5 font-sans text-sm font-normal text-primary-blue outline outline-0 
  transition-all placeholder-shown:border placeholder-shown:border-primary-blue 
  placeholder-shown:border-t-primary-blue focus:border-2 focus:border-primary-blue
  focus:border-t-transparent focus:outline-0 disabled:resize-none disabled:border-0 
  disabled:bg-primary-blue`

   labelTextArea = `before:content[' '] after:content[' '] pointer-events-none absolute left-0 
  -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight 
  text-primary-blue transition-all before:pointer-events-none before:mt-[6.5px] 
  before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md 
  before:border-t before:border-l before:border-primary-blue before:transition-all 
  after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block 
  after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r 
  after:border-primary-blue after:transition-all peer-placeholder-shown:text-sm 
  peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-primary-blue 
  peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent
   peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-primary-blue
   peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-primary-blue
   peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-primary-blue
   peer-disabled:text-transparent peer-disabled:before:border-transparent 
   peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-primary-blue`

   classDropdown = `peer h-full w-full rounded-[7px] border border-primary-blue 
   border-t-transparent focus:border-2 focus:border-primary-blue focus:border-t-transparent
   bg-transparent font-sans text-sm font-normal text-primary-blue outline 
   outline-0 transition-all placeholder-shown:border placeholder-shown:border-primary-blue
   placeholder-shown:border-t-primary-blue empty:!bg-primary-blue  focus:outline-0 disabled:border-0 
   disabled:bg-primary-blue`

   labelDropdown = `before:content[' '] after:content[' '] pointer-events-none absolute left-0 
  -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight 
  text-primary-blue transition-all before:pointer-events-none before:mt-[6.5px] 
  before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 
  before:rounded-tl-md before:border-t before:border-l before:border-primary-blue
  before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 
  after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md 
  after:border-t after:border-r after:border-primary-blue after:transition-all 
  peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] 
  peer-placeholder-shown:text-primary-blue peer-placeholder-shown:before:border-transparent 
  peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] 
  peer-focus:leading-tight peer-focus:text-primary-blue peer-focus:before:border-t-2 
  peer-focus:before:border-l-2 peer-focus:before:border-primary-blue peer-focus:after:border-t-2 
  peer-focus:after:border-r-2 peer-focus:after:border-primary-blue peer-disabled:text-transparent 
  peer-disabled:before:border-transparent peer-disabled:after:border-transparent 
  peer-disabled:peer-placeholder-shown:text-primary-blue`

   FeatureExtractionArr = (label, isApiName) => {
    const arr = [
      { id: 1, title: 'OpenAI', apiName: "custom", information: '' },
      { id: 2, title: 'Spacy NER', apiName: "ner", information: '' },
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr;
    }
  }

   modelTypeArr = (label, isApiName) => {
    const arr = [
      { id: 1, title: 'Classification', apiName: "classification", information: '' },
      { id: 2, title: 'Regression', apiName: "regression", information: '' }
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr;
    }
  }

   chatbotModelArr = (label, isApiName, patternType) => {
    const arr = patternType === "chatbot"?
    [
      { id: 1, title: 'GPT 3.5 Turbo Instruct', apiName: "gpt-3.5-turbo-instruct", information: '' },
      { id: 2, title: 'GPT 3.5 Turbo', apiName: "gpt-3.5-turbo", information: '' },
      { id: 3, title: 'GPT 4', apiName: "gpt-4", information: '' },
      { id: 4, title: 'GPT 4o', apiName: "gpt-4o", information: '' },
      { id: 5, title: 'GPT 4 Turbo', apiName: "gpt-4-turbo", information: '' },
    ]
    :
    [
      { id: 1, title: 'GPT 3.5 Turbo Instruct', apiName: "gpt-3.5-turbo-instruct", information: '' },
      { id: 2, title: 'GPT 3.5 Turbo', apiName: "gpt-3.5-turbo", information: '' },
      { id: 3, title: 'Google Gemini', apiName: "google-gemini", information: '' }
    ];

    

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr;
    }
  }

   MetricsClassificationArr = (label, isApiName) => {
    const arr = [
      { id: 1, title: 'Accuracy', apiName: "accuracy", information: '' },
      { id: 2, title: 'Precision', apiName: "precision", information: '' },
      { id: 3, title: 'Recall', apiName: "recall", information: '' }
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr;
    }
  }

   MetricsRegressionArr = (label, isApiName) => {
    const arr = [
      { id: 1, title: 'R2', apiName: "r2", information: '' },
      { id: 2, title: 'RMSE', apiName: "rmse", information: '' },
      { id: 3, title: 'MSE', apiName: "mse", information: '' }
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr;
    }
  }

   predictiveModelLabels = (label, isApiName) => {
    const arr = [
      { id: 1, title: "Logistic Regression (Classification)", apiName: "logisticRegression" },
      { id: 2, title: "Random Forest (Classification)", apiName: "randomForest" },
      { id: 3, title: "Decision Tree (Classification)", apiName: "decisionTree" },
      { id: 4, title: "K Nearest Neighbours (Classification)", apiName: "knn" },
      { id: 5, title: "Support Vector Machine (Classification)", apiName: "svm" },
      { id: 6, title: "Linear Regression (Regression)", apiName: "linearRegression" },
      { id: 7, title: "Random Forest Regression (Regression)", apiName: "randomForestRegression" },
      { id: 8, title: "Decision Tree Regression (Regression)", apiName: "decisionTreeRegression" },
      { id: 9, title: "XGBoost Regression (Regression)", apiName: "xgboost" },
      { id: 10, title: "K-Means Regression (Regression)", apiName: "kmeans" },
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr?.filter((lb) => lb?.title === label)?.[0]?.apiName;
    }
  }


  allDynamicDropdownLabels = (backendArr, label, isApiName) => {
    if (isApiName) {
      return backendArr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return backendArr?.filter((lb) => lb?.title === label)?.[0]?.apiName;
    }
  };

  makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  allDropdownLabels = (label, isApiName) => {
    const arr = [

      { title: 'OCR', apiName: 'ocr'},
      { title: 'OpenAI', apiName: 'openAI'},
      
      { title: 'Open Voice', apiName: 'openVoice'},
      { title: 'Google Cloud Platform', apiName: 'gcp'},

      { title: 'VISA', apiName: 'visa'},
      { title: 'Mastercard', apiName: 'mastercard'},
      { title: 'Applepay', apiName: 'applepay'},
      { title: 'Paypal', apiName: 'paypal'},

      { title: 'Classification', apiName: 'classification'},
      { title: 'Regression', apiName: 'regression'},

      { title: 'Accuracy', apiName: "accuracy" },
      { title: 'Precision', apiName: "precision" },
      { title: 'Recall', apiName: "recall" },

      { title: 'R2', apiName: "r2"},
      { title: 'RMSE', apiName: "rmse"},
      { title: 'MSE', apiName: "mse" },
      
      { title: "Logistic Regression", apiName: "logisticRegression" },
      { title: "Random Forest", apiName: "randomForest" },
      { title: "Decision Tree", apiName: "decisionTree" },
      { title: "K Nearest Neighbours", apiName: "knn" },
      { title: "Support Vector Machine", apiName: "svm" },
      { title: "Linear Regression", apiName: "linearRegression" },
      { title: "Random Forest Regression", apiName: "randomForestRegression" },
      { title: "Decision Tree Regression", apiName: "decisionTreeRegression" },
      { title: "XGBoost Regression", apiName: "xgboost" },
      { title: "K-Means Regression", apiName: "kmeans" },

      { title: "Exception", apiName: "exception" },
      { title: "Fix", apiName: "fix" },
      { title: "Filter", apiName: "filter" },

      { title: "Cosine Similarity", apiName: "CosineSimilarity" },
      { title: "Pearson Correlation", apiName: "PearsonCorrelation" },

      { title: "Collaborative", apiName: "Collaborative" },
      { title: "Content Based", apiName: "ContentBased" },

      { title: 'Email Address', apiName: "EMAIL_ADDRESS" },
      { title: 'Domain Name', apiName: "DOMAIN_NAME" },
      { title: 'Date Time', apiName: "DATE_TIME" },
      { title: 'Person', apiName: "PERSON" },
      { title: 'Phone Number', apiName: "PHONE_NUMBER" },
      { title: 'IP Address', apiName: "IP_ADDRESS" },
      { title: 'Location', apiName: "LOCATION" },
      { title: 'API Key', apiName: "API_KEY" },
      { title: 'Password', apiName: "PASSWORD" },

      { title: 'Yes', apiName: true },
      { title: 'No', apiName: false },

      { title: 'Full Page Segmentation' , apiName: 'FULL_PAGE_SEGMENTATION'}, 
      { title: 'Single Column of Text' , apiName: 'SINGLE_COLUMN_OF_TEXT'}, 
      { title: 'Block of Vertically Aligned Text' , apiName: 'BLOCK_OF_VERTICALLY_ALIGNED_TEXT'}, 
      { title: 'Uniform Block of Text' , apiName: 'UNIFORM_BLOCK_OF_TEXT'}, 
      { title: 'Single Text Line' , apiName: 'SINGLE_TEXT_LINE'}, 
      { title: 'Sparse Text' , apiName: 'SPARSE_TEXT'}
    ];

    if (isApiName) {
      return arr?.filter((lb) => lb?.apiName === label)?.[0]?.title;
    }
    else {
      return arr?.filter((lb) => lb?.title === label)?.[0]?.apiName;
    }
  };

  isValidate = (fields) => {
    let validate = true;
    fields.map((field, _) => {
        if((field?.type === "text" || field?.type === "textarea") && field?.mendatory && config[`${field?.name}`]?.replace(/\s+/g, '')?.length === 0){
            validate = false
        }
    })
    return validate
  }

  Icons = (icon) => {
    return (
        <div style={{ width: "30px", marginRight: "15px" }}>
            <MDBox bgColor={themeColor}
                style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
                <MDBox component="img" textAlign="center"
                    src={icon}
                    alt="Brand" width="70%"
                    style={{ height: "14px", width: "14px" }}
                />
            </MDBox>
        </div>
    )
}

  chatbotModelCustom = 'custom';
  chatbotModelNer = 'ner';

  imageToTextCustom = 'openAI';
  imageToTextOcr = 'ocr';

  textToSpeechGoogle = 'gcp';
  textTospeechOpen = 'openVoice';

  predictiveRegression = 'Regression';
  predictiveClassification = 'Classification';

  recommendationContentBased = 'ContentBased';
  recommendationCollaborative = 'Collaborative';


  TRAINED = 'TRAINED';
  DEPLOYED = 'DEPLOYED';
  TRAINING = 'TRAINING';
  CONFIGURED = 'CONFIGURED';
  MODIFIED = 'MODIFIED';
  READY = 'READY';

  speech = 'Speech to Text';
  predictive = 'Predictive Analysis';
  recommendation = 'Recommendation System';
  imageToText = 'Image to Text';
  textToSpeech = 'Text to Speech';
  chatbot = 'Chatbot';
  llm = 'llm';
  featureExtraction = 'Feature Extraction';
  speechToText = 'Speech to Text';
  language = 'Language Translation';

  chatbotRoute = 'llm';
  featureExtractionRoute = 'feature-extraction';
  predictiveAnalysisRoute = 'predictive-analysis';
  recommendationRoute = 'recommendation-system';
  imageToTextRoute = 'image-to-text';
  textToSpeechRoute = 'text-to-speech';
  speechToTextRoute = 'speech-to-text';


  languages = [
    "assembly",
    "ada",
    "algol",
    "apl",
    "awk",
    "basic",
    "c",
    "c++",
    "c#",
    "cobol",
    "caml",
    "clojure",
    "crystal",
    "d",
    "dart",
    "delphi",
    "dylan",
    "eiffel",
    "elixir",
    "elm",
    "erlang",
    "f#",
    "forth",
    "fortran",
    "go",
    "groovy",
    "haskell",
    "html",
    "idl",
    "icon",
    "inform",
    "io",
    "j",
    "java",
    "javascript",
    "julia",
    "kotlin",
    "labview",
    "lisp",
    "logo",
    "lua",
    "matlab",
    "ml",
    "modula-2",
    "nim",
    "objective-c",
    "ocaml",
    "pascal",
    "perl",
    "php",
    "pl/i",
    "pl/sql",
    "prolog",
    "python",
    "r",
    "racket",
    "rexx",
    "ruby",
    "rust",
    "sas",
    "scala",
    "scheme",
    "shell",
    "simula",
    "smalltalk",
    "snobol",
    "sql",
    "swift",
    "tcl",
    "typescript",
    "vbscript",
    "vhdl",
    "visual basic",
    "wolfram",
    "x10",
    "xml",
    "xslt",
    "zig"
  ];

  // Function to escape special characters for use in a regular expression
  escapeRegExp = (string) => {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric',day: '2-digit', month: '2-digit' };
    const formattedDate = date.toLocaleDateString('en-CA', options);
    return formattedDate.replace(/-/g, '/');
  };

  formatDashDates = (originalDate, isTime) => {

      const date = new Date(originalDate);

      // Extract year, month, and date
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based, so add 1
      const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed

      // Extract hours, minutes, and seconds
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      if(isTime){
        // Construct the new date and time format
        return `${year}-${month}-${day} / ${hours}:${minutes}:${seconds}`;
      }else{
        // Construct the new date format
        return`${year}-${month}-${day}`;
      }
      
  }

  parseDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return new Date(year, month - 1, day); // Month is zero-based in Date constructor
  }

  convertToUTC = (dateString) => {
    // Create a new Date object from the input date string
    const date = new Date(dateString);
    
    // Convert the date to a UTC string
    const utcDate = date.toUTCString();
    
    return utcDate;
  }

  uniqueElements = (array) => {
    return [...new Set(array)];
  }

  planChange = (currentPlan, requestedPlan) => {
    const rank = {
        "Free":0,
        "Basic": 1,
        "Premium": 2,
        "Enterprise": 3,
    };

    const currentRank = rank[currentPlan];
    const requestedRank = rank[requestedPlan];

    if (currentRank === undefined || requestedRank === undefined) {
        return "Invalid Plan";
    }

    if (currentRank < requestedRank) {
        return "Upgrade";
    } else if (currentRank > requestedRank) {
        return "Downgrade";
    } else {
        return "No Change";
    }
  }


  convertToFormData = (data, parentKey = '') => {
    const formData = new FormData();
  
    const appendToFormData = (key, value) => {
      if (value !== null && value !== undefined) {
        // Check if the value is an array
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));  // Serialize the array to a string
        } else if (typeof value === 'object') {
          // If the value is an object, recurse through its properties
          for (const nestedKey in value) {
            if (value.hasOwnProperty(nestedKey)) {
              appendToFormData(`${key}[${nestedKey}]`, value[nestedKey]);
            }
          }
        } else {
          formData.append(key, value);
        }
      }
    };
  
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        appendToFormData(parentKey ? `${parentKey}[${key}]` : key, data[key]);
      }
    }
  
    return formData;
  };

  validateUrls = (urlsArray) => {
    // Updated regex to require at least two characters in the subdomain
    const urlPattern = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    
    return urlsArray.every((url) => {
      // Remove leading/trailing whitespace
      const trimmedUrl = url.trim();
      
      // If the URL starts with http:// or https://, test it directly
      if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
        return urlPattern.test(trimmedUrl);
      }
      
      // If not, prepend 'https://' and test
      return urlPattern.test(`https://${trimmedUrl}`);
    });
  };

}
const Constants = new CommonConstants();

export default Constants;
