import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useMaterialUIController } from 'context';
import useScreenWidth from "libraries/ScreenSizeHook";

export const TabsCustomized = React.memo(({ activeTab, backActiveTab, arr }) => {  // React.memo to prevent rerenders
  const [value, setValue] = React.useState(0);
  const [controller] = useMaterialUIController();
  const [windowSize] = useScreenWidth();  // No need to re-fetch window size unnecessarily
  const { darkMode, themeColor } = controller;

  const handleChange = (newValue) => {
    setValue(newValue);
    backActiveTab(newValue);
  };  // Memoize the handler to prevent rerenders

  const getBackgroundColor = React.useMemo(() => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  }, [themeColor]);


  return (
    <div className="flex w-full justify-center" style={{ marginBottom: "6px" }}>
      <Box sx={{ width: windowSize.innerWidth > 600 ? '80%' : "100%" }}>
        <Box style={{ width: "100%" }}>
          {/* {renderedTabs} */}
            <div style={{ width: "100%" }}
                 className="flex items-center justify-center cursor-pointer" >

              {arr.map((pl, index) => (
                <div key={index} onClick={()=> handleChange(index)}
                      className="flex items-center justify-center"
                      style={{ border: "1px solid #D9D3D3" ,
                                color: (index === activeTab || darkMode) ? "white" : "#262626",
                                padding: "7px",
                                borderRadius: index === 0 ? "10px 0 0 10px" : (index === (arr?.length-1) ) ? "0 10px 10px 0" : "0px" ,
                                fontSize: windowSize.innerWidth <= 600 ? "8px"  : "16px",
                                fontWeight: 600,
                                backgroundColor: (index === activeTab) ? getBackgroundColor 
                                                  : darkMode ? "#666666" : "#F0F0F0", 
                                width: "100%" }}
                >
                    {pl}
                </div>
              ))}

            </div>
        </Box>
      </Box>
    </div>
  );
});
