import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, Box } from '@mui/material';

export const CustomRadioButtons = ({ options, defaultValue, onSelectionChange }) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || options[0].value);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (onSelectionChange) {
      onSelectionChange(value);  // Notify parent component
    }
  };

  // Update the selection if the defaultValue changes
  useEffect(() => {
    if (defaultValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      border: '1px solid #E0E0E0',
      borderRadius: '8px',
      padding: '1px 8px',
      width: '100%'
    }}>
      <RadioGroup
        row
        value={selectedValue}
        onChange={handleChange}
        sx={{ display: 'flex',width: '100%',}}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio sx={{
              '&.Mui-checked': {
                color: '#3C57E6'  // Selected radio button color
              }
            }} />}
            label={option.label}
            sx={{
              marginRight: '16px',  // Adjust spacing
              width:"40%",
              marginLeft: '0px',
              '& .MuiFormControlLabel-label': {
                fontWeight: selectedValue === option.value ? 'bold' : 'normal',
              }
            }}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
