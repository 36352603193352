import { FaFilePdf, FaFileWord, FaFileExcel, FaFileImage, FaFileAlt, FaFileArchive } from 'react-icons/fa';
import { AiOutlineFile } from 'react-icons/ai';
import { GrDocumentCsv } from "react-icons/gr";
import { useMaterialUIController } from "context";
import {ReactComponent as CSVIcon} from "assets/images/flow-icons/csv.svg"
import { Images } from "libraries/Images";

export const FileIcon = ({ fileName }) => {
  const extension = fileName?.split('.')?.pop()?.toLowerCase();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  switch (extension) {
    case 'pdf':
      return <img width={"35px"} src={Images.pdfIcon} />
      // return <FaFilePdf style={{ color: darkMode ? "white" : "black" }} />;
    case 'csv':
      return <img width={"35px"} src={Images.csvIcon} />
      // return <CSVIcon style={{ height:"40px",width:"40px" }} />;
    case 'doc':
    case 'docx':
      return <img width={"35px"} src={Images.docIcon} />
      // return <FaFileWord style={{ color: darkMode ? "white" : "black" }} />;
    case 'xls':
    case 'xlsx':
      return <FaFileExcel style={{ color: darkMode ? "white" : "black" }} />;
    case 'jpg':
      return <img width={"35px"} src={Images.jpgIcon} />
    case 'jpeg':
      return <img width={"35px"} src={Images.jpegIcon} />
    case 'png':
      return <img width={"35px"} src={Images.pngIcon} />
    case 'gif':
      return <FaFileImage style={{ color: darkMode ? "white" : "black" }} />;
    case 'zip':
    case 'rar':
      return <FaFileArchive style={{ color: darkMode ? "white" : "black" }} />;
    case 'txt':
      return <img width={"35px"} src={Images.txtIcon} />
    case 'md':
      return <FaFileAlt style={{ width:"35px", color: darkMode ? "white" : "black" }} />;
    default:
      return <AiOutlineFile style={{ color: darkMode ? "white" : "black" }} />;
  }
}
