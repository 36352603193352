import { INTERVAL_SHOULD_START, INTERVAL_START_TIME, IS_USER_LOGIN_STATUS,IS_TIME_INTERVAL } from "redux/Types";

const initialState = {
    intervalShouldStart: false,
    intervalStartTime: null,
    userLoginStatus: false,
    isTimeInterval:false
};

export const RefreshTokenReducer = (state = initialState, action) => {

    const { payload, type } = action;

    switch (type) {

        case INTERVAL_SHOULD_START: {
            return {
                ...state,
                intervalShouldStart: payload
            };
        }
        case INTERVAL_START_TIME: {
            return {
                ...state,
                intervalStartTime: payload
            };
        }
        case IS_USER_LOGIN_STATUS: {
            return {
                ...state,
                userLoginStatus: payload
            };
        }
        case IS_TIME_INTERVAL: {
            return {
                ...state,
                isTimeInterval: payload
            };
        }

        default:
            return state;

    }

};
