import React, { FC } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { NavLink } from "react-router-dom";
import {useMaterialUIController} from "context";
import MDBox from "components/MDBox";
import { FaBoxArchive } from "react-icons/fa6";
import { useMediaQuery } from '@mui/material';
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import PreviewIcon from '@mui/icons-material/Preview';
import { useResponsive, breakpoints } from 'services/Utils';

export const Tag = ({ name, onClick, isDelete, isLogo, onDelete, isPreview, onPreview, backgroundColor, fontColor, className,isWebToolTrain,isFunctionAsToolTag }) => {
    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor } = controller;
    const mediaQueries = useResponsive(breakpoints);
    const { isNestHub, isMdLoptop, isloptop, isTablet, isLargeMobile, isSmallMobile } = mediaQueries;


    const getTooltipBackgroundColor = (isTransparent) => {
        switch (themeColor) {
          case "info":
            return isTransparent ? `rgba(49, 75, 135, 0.8)` :  "#314B87";
          case "success":
            return isTransparent ? `rgba(87, 168, 70, 0.8)` :  "#57A846";
          default:
            return themeColor;
        }
      };

    return (
        <MDBox
            onClick={(e) => onClick(e)}
            bgColor={getTooltipBackgroundColor(true)}
            color={"white"}
            style={{ fontSize: isWebToolTrain?`${isSmallMobile ? "7px" :isLargeMobile ? "8px" : (isTablet ? "10px" : "14px")}`:"14px",
             fontWeight: "450", borderRadius: "10px", border: `3px solid ${darkMode ? "white" : getTooltipBackgroundColor(false)}`
            
            }}
            className={` ${!isFunctionAsToolTag && "mx-2"}  ${className} relative cursor-pointer inline-flex items-center leading-sm uppercase px-3 py-1`}
        >
            {isLogo &&
                   <FaBoxArchive
                    style={{ color: "white", marginRight: "10px", fontSize: "14px" }}
                    // onClick={(e) => editAgent(e,row?.patternId, row?._id, row?.patternDetails?.[0]?.patternName, '', false)}
                    className=" cursor-pointer"
                    />
                // <svg
                //     xmlns="http://www.w3.org/2000/svg"
                //     width="20"
                //     height="20"
                //     viewBox="0 0 24 24"
                //     fill="none"
                //     stroke="currentColor"
                //     strokeWidth="2"
                //     strokeLinecap="round"
                //     strokeLinejoin="round"
                //     className="feather feather-archive mr-2"
                // >
                //     <polyline points="21 8 21 21 3 21 3 8"></polyline>
                //     <rect x="1" y="3" width="22" height="5"></rect>
                //     <line x1="10" y1="12" x2="14" y2="12"></line>
                // </svg>
                }
                <div style={{ paddingTop: "3px",fontSize:isFunctionAsToolTag ? "10px" :"14px" }}>
                {name}
                </div>

            {isDelete &&
                <DisabledByDefaultIcon
                    onClick={(e) => onDelete(e)}
                    sx={{ position: 'absolute', top: '-19px', right: '-10px',marginRight: "20px",
                        fontSize: "20px !important", color: darkMode ? "white !important" : "red !important" 
                     }}
                    className={` font-semibold  cursor-pointer`}
                />
            }

            {isPreview && 
                
                <PreviewIcon sx={{ fontSize: "20px !important", position: 'absolute', 
                            top: '-19px', right: '-10px',marginRight: "42px", cursor: "pointer", 
                            color: darkMode ? "white !important" : "black !important" }} 
                             fontSize="small" 
                             onClick={(e) => onPreview(e)} />
                   
            }
        </MDBox>
    );

};
