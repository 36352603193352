import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { useResponsive,breakpoints } from 'services/Utils';

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,  
  fontWeight:500,
}));

function PieCenterLabel({ children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { width, height, left, top } = useDrawingArea();
  const mediaQueries = useResponsive(breakpoints);
  const { isNestHub } = mediaQueries;
  return (
    <StyledText style={{fontSize: isNestHub && "10px" ,fill:darkMode ? "white" : "#495361"}} x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function DashboardDynamicPieChart({ value, total, title, }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const mediaQueries = useResponsive(breakpoints);
  const { isMdLoptop, isloptop, isLargeMobile, isSmallMobile,isNestHub,isZoomLoptopScreen } = mediaQueries;
  
  // Calculate the percentage
  const percentage = (value / total) * 100;

  // Determine the color based on percentage
  let filledColor;
  if (percentage <= 39) {
    filledColor = '#495361';  // Below 40%
  } else if (percentage < 70) {
    filledColor = '#1A73E8';  // 40% to below 70%
  } else {
    filledColor = '#D90000';  // 70% and above
  }

  // Data for the pie chart
  const data = [
    { value: value, color: filledColor },  // Filled portion
    { value: total - value, color: '#dddddd' },  // Unfilled portion
  ];

  const size = {
    width:isNestHub ? 180 :isMdLoptop ? 190 :isloptop || isZoomLoptopScreen? 215: 290,  // Reduced the overall width
    height: 130,  // Reduced the overall height
  };

  return (
    <div className={`flex flex-col gap-[4px] ${(isSmallMobile || isLargeMobile) && "max-w-[200px] pt-[20px]"}`}>
      <PieChart
        series={[{ data, innerRadius:isNestHub ?20 : isMdLoptop ? 25 : isloptop || isZoomLoptopScreen? 37 : 45, outerRadius: isNestHub ? 40 :isMdLoptop  ? 45 :isloptop || isZoomLoptopScreen ? 57 :65, cornerRadius: 5 }]}  // Adjusted inner and outer radius
        {...size}
        tooltip={false}
      >
        <PieCenterLabel>{`${value}/${total}`}</PieCenterLabel>
      </PieChart>
      <MDTypography sx={{ fontSize:isNestHub ? "10px" :isMdLoptop ? "11px" :isloptop ? "11px": "16px", fontWeight: "500", color: darkMode ? "white" : "#0F0F0F", paddingLeft:isNestHub ? "7px" :isMdLoptop ? "5px":isloptop ? "6px":isZoomLoptopScreen ? "0px": "40px" }}>
        {title}
      </MDTypography>
    </div>
  );
}