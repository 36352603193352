import React, { useEffect, useState } from "react";
import { Code } from "react-content-loader";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SpinnerLoader } from "examples/Loader/Loader";
import { TabUpload } from "examples/TabUpload/TabUpload";
import { uploadPreview } from "libraries/Enums";
import { Tag } from "examples/Tag/Tag";
import ApiRequest from "services/ApiRequest";
import { validateAndProcessFile } from "services/Utils";
import { getFileType } from "services/Utils";
import MDButton from "components/MDButton";
import { useMaterialUIController } from "context";
import Modal from "examples/Modal/Modal";
import MDBox from "components/MDBox";
import ApiUrls from "services/ApiUrls";
import Dropdown from "examples/Dropdown/Dropdown";
import MDTypography from "components/MDTypography";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { FileIcon } from "examples/FileIcon/FileIcon";
import {MdDelete, MdOutlineVisibility } from "react-icons/md";
import useScreenWidth from "libraries/ScreenSizeHook";


export default function PredictiveAnalysisTrain(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, name } = useParams()
  const [loader, setLoader] = useState(false);
  const [uploadPreviewLoader, setUploadPreviewLoader] = useState(false);
  const [modelName, setModelName] = useState(localStorage.getItem("featureModelId"));
  const [uploadfilePreviewList, setUploadfilePreviewList] = useState({ columns: [], rows: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [previewUploadLoader, setPreviewUploadLoader] = useState(false);
  const [dropdownValues, setDropdownValues] = React.useState({});
  const [modalDelOpen, setModalDelOpen] = React.useState({ flag: false, document: {} });
  const [delLoader, setDelLoader] = React.useState(false);
  const [documentId, setDocumentId] = React.useState('');
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [trainingLoader, setTrainingLoader] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false); // State to track file upload status
  const [uploadedFileData, setUploadedFileData] = useState(null);
  const [windowSize, getWindowSize] = useScreenWidth();

  useEffect(() => {
    var dropval = {};
    // Source Dropdown
    if (location?.state?.beforeTrained?.sourceCol?.length) {
      dropval = { ...dropval, [location.state?.beforeTrained?.sourceCol]: `${location.state?.beforeTrained?.sourceCol}-source` }
    }

    // Features Dropdown List
    if (location?.state?.beforeTrained?.featureList) {
      if (location?.state?.modelType === 'custom') {
        Object.keys(location?.state?.beforeTrained?.featureList)?.map(
          (feat) => { return dropval = { ...dropval, [feat]: `${feat}-feature` }; }
        )
      } else {
        location?.state?.beforeTrained?.featureList?.map(
          (feat) => { return dropval = { ...dropval, [feat]: `${feat}-feature` }; }
        )
      }
    }

    setDropdownValues(dropval);
    if (location.state?.uploadedFile?.length > 0 && location.state?.uploadedFile?.filter((fl) =>
      fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) {
      setIsFileUploaded(true)
    }

  }, []);

  const navigation = (route, page, step, uploadedFile, beforeTrained, objectId, result) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        id: location?.state?.id,
        name: name,
        objectId: objectId || location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: uploadedFile,
        isTrained: false,
        beforeTrained: beforeTrained ?? location?.state?.beforeTrained,
        modelType: location?.state?.modelType,
        previousPath: location.state?.previousPath ?? '',
        result: result
      }
    });
  };

  const uploadedFile = (acceptedFiles) => {
    setLoader(true);

    const supportedFormats = {
      'text/csv': 'CSV',
      'application/vnd.ms-excel': 'XLSX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
      'application/octet-stream': 'PARQUET',
      '': 'PARQUET'
    };

    const afile = acceptedFiles?.[0];
    const validation = validateAndProcessFile(afile, supportedFormats)
    if (validation === true) {
      ApiRequest.uploadChatbotAgent({ file: afile, modelName: modelName ? JSON.parse(modelName)?.name : "", fileName: afile?.name, fileSize: afile?.size, fileExtension: getFileType(supportedFormats, afile?.type) }, location?.state?.objectId, (res) => {
        if (res.isSuccess) {
          toast.success(`"${res.data.fileName}" uploaded successfully.`);
          setUploadedFileData({
            fileName: afile.name,
            fileSize: afile.size,
            fileType: getFileType(supportedFormats, afile?.type),
          });
          setIsFileUploaded(true);
          navigation('/train', 4, 2, res?.data?.documentNames, false);
        } else {
          toast.error(res.error ? res?.error?.message : 'Something went wrong')
        }
        setLoader(false);
      });
    }
    else {
      toast.error(`${validation}`)
      setLoader(false);
    }
  };
  const previewFile = (fileData) => {
    setModalOpen(true); setUploadPreviewLoader(true);
    setDocumentId(fileData?._id ?? fileData?.documentId ?? '');
    const data = {
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      agentId: location?.state?.objectId,
      documentId: fileData?._id ?? fileData?.documentId ?? ''
    }

    ApiRequest.previewUploadFeatureAgent(data, `${location?.state?.objectId}`, (res) => {
      setUploadPreviewLoader(false);
      if (res.isSuccess) {
        setUploadfilePreviewList({
          columns: res.data?.columns,
          rows: res.data?.sampleRows
        })
      } else {
        toast.error(res.error.message ?? "Something went wrong")
      }
    });
  };

  const modal = (str) => {
    var output = [
      { title: "Feature", apiName: `${str}-feature`, id: 1 },
      { title: "Source", apiName: `${str}-source`, id: 2 },
    ]
    return output;
  };

  const returnedLabel = (columnName) => {
    return (
      <div> {dropdownValues[columnName]?.includes(uploadPreview?.source) ?
        <div style={{ padding: '2px' }} className="text-white bg-green-700 font-semibold text-xs rounded-sm border border-2 border-green-800">S</div>
        : dropdownValues[columnName]?.includes(uploadPreview?.feature) ?
          <div style={{ padding: '2px' }} className="text-white bg-primary-blue font-semibold text-xs rounded-sm border border-2 border-primary-blue">F</div>
          : <div style={{ height: "25px" }}></div>}</div>
    )
  };

  const InputNewFields = uploadfilePreviewList?.rows?.length ? Object?.keys(uploadfilePreviewList?.rows?.[0])?.map((cl, i) => {
    return {
      id: i + 1,
      // returnedLabel(cl)
      name: '',
      apiName: cl,
      type: "dropdown",
      arr: modal(cl),
    }
  }) : [];

  const deleteDocument = () => {
    setDelLoader(true);
    const data = {
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      documentId: modalDelOpen?.document?._id ?? modalDelOpen?.document?.documentId ?? ''
    }

    ApiRequest.documentDeleteFeatureAgent(data, location?.state?.objectId, (res) => {
      setModalDelOpen({ flag: false, document: {} }); setDelLoader(false);
      if (res.isSuccess) {
        toast.success(res?.data?.message);
        navigation('/train', 4, 2, res?.data?.documentNames, false);
        setDropdownValues({})
        setIsFileUploaded(false)
      }
      else toast.error(res?.error?.message ?? "Unexpected error occured")
    });
  }

  const saveModel = () => {
    const data = {
      sourceCol: dropdownValues ? Object.values(dropdownValues)?.filter((df) => df?.includes("source"))
        ?.map((sc) => sc?.split("-sour")?.[0])?.[0] : '',
      featureList: dropdownValues ? Object.values(dropdownValues)?.filter((df) => df?.includes("feature"))
        ?.map((sc) => sc?.split("-feat")?.[0]) : [],
      modelName: modelName ? JSON.parse(modelName)?.name : "",
      documentId: documentId ?? ''
    }

    if (data?.sourceCol && data?.featureList?.length) {
      toast.success("Column configuration saved successfully")
      navigation('/train', 4, 2, location?.state?.uploadedFile, data);
      setModalOpen(false);
    } else {
      toast.error(`Please select atleast one column as "source" and one as "feature".`)
    }

  }

  const trainXAgent = () => {
    if (location?.state?.beforeTrained?.sourceCol && location?.state?.beforeTrained?.featureList) {
      setTrainingLoader(true);
      ApiRequest.trainPredictiveAgent(location?.state?.beforeTrained, `${location?.state?.deployedURL}${ApiUrls.trainPredictiveAgent}/${location?.state?.objectId}`, (res) => {
        setTrainingLoader(false);
        if (res.isSuccess) {
          toast.success(res.data.message ? res.data.message : "xAgent trained successfully");
          navigation('/result', 5, 3, location?.state?.uploadedFile, location?.state?.beforeTrained, location?.state?.objectId, res?.data);
        } else {
          toast.error(res.error.message ? res.error.message : "Training failed")
        }
      });
    } else {
      toast.error(`Please click on the file and select atleast one "source" and "feature" column and save it.`)
    }
  }

  const modelSetup = () => {

    navigation('/model', 2, 1, location?.state?.uploadedFile, location?.state?.beforeTrained, location?.state?.objectId, location?.state?.result);
  }

  const reconfigBtn = () => {
    navigation('/config', 1, 0, location?.state?.uploadedFile, location?.state?.objectId, location?.state?.result);
  }

  return (
    <div className="flex flex-col" style={{padding: windowSize.innerWidth <= 600 ? "0px" : "0px 30px", justifyContent: "space-between" }}>
      <div style={{ width:"100%", height: "calc(100vh - 437px)", display:"flex", flexDirection:"column", justifyContent:"center", overflowY:"auto" }}>
        {!isFileUploaded ? <>

          <MDTypography style={{ color: darkMode ? "white" : "#5B5B5B", fontSize: "14px", fontWeight: "600" }}>Upload File</MDTypography>
          <div style={{ border:darkMode ? "1px dashed white" : "1px dashed #00000040", borderRadius: "10px", marginTop: "10px" }}>
            <form autoComplete="off">
              <div className="flex flex-row w-full flex-wrap justify-between mt-4">
                <div className="flex flex-row my-2 w-full" style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                  <div className="text-primary-blue w-full flex flex-col" style={{ justifyContent: "center", alignItems: "center" }}>
                    {location?.state?.isTrained ?
                      <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                        <div>Agent trained successfully. Please go to inference.</div>
                        {location?.state?.deployedURL && <div className="mt-2"><span className="font-semibold"> Deployed URL: </span>{location.state?.deployedURL}</div>}
                      </div>
                      : <div style={{ color: darkMode ? "white" : "#5B5B5B", fontSize: "14px", fontWeight: "400" }}>Training has not started yet. Please upload file and click file to preview.</div>
                    }
                    {/* {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
                      fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
                      <div style={{ color: darkMode ? "white" : "black", fontSize: "16px" }}>
                        Only one document can be stored in a model. Please delete already uploaded item to save new file.
                      </div> : null} */}
                  </div>
                </div>
              </div>


            </form>

            {(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
              fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
              <div></div>
              :
              <>
                <div>
                  <TabUpload supportedFileTypes="CSV, XLSX, PARQUET" isSingleFile={true} uploadedFile={(fl) => uploadedFile(fl)} loader={loader} />
                </div>
              </>
            }
          </div>
        </> :
          <>
          <div style={{width:"100%", height: "calc(100vh - 437px)", display:"flex", gap:"50px", flexDirection:"column", justifyContent:"center", padding: windowSize.innerWidth <= 600 ? "0px" : "0px 30px",overflowY:"auto"}}>
            <div style={{ borderRadius: "8px", border: "1px solid #CCCCCC",width:"100%",  }}>
              
              <div style={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                <MDTypography sx={{ width: "100%", fontSize: "14px", fontWeight: "600", color: darkMode ? "white" : "#676767", borderBottom: "1px solid #CCCCCC", paddingBottom: "10px" }}>Uploaded Document</MDTypography>
                <div></div>
              </div>
              <div className=" flex flex-grow justify-between w-full flex-wrap items-center py-[20px] px-[10px]">
                {location.state?.uploadedFile?.length ?
                  location.state?.uploadedFile
                    ?.filter((fl) => fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)
                    ?.map((afl, ind, arr) => (
                      <>
                      <div key={ind} className="flex items-center gap-[10px]">
                        <div className="flex gap-[10px] flex-wrap">
                          <FileIcon fileName={afl?.fileName} />
                          <div className="flex flex-col">
                            <MDTypography sx={{ fontSize:windowSize.innerWidth > 600? "12px" : "9px", fontWeight: "500", color: darkMode ? "white" : "#101828" }}>
                              {afl?.fileName}
                            </MDTypography>
                            <MDTypography sx={{ fontSize: "12px", fontWeight: "400", color: darkMode ? "white" : "#475467" }}>
                              {(uploadedFileData?.fileSize / 1024).toFixed(2)} KB
                            </MDTypography>
                          </div>
                        </div>
                        </div>
                        <div className="flex items-center gap-[10px]">
                          <MdOutlineVisibility
                            style={{ color: darkMode ? "white" : "#314B87", height: "24px", width: "24px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              previewFile(afl); 
                            }}
                          />
                          <MdDelete
                            style={{ color: darkMode ? "white" : "#314B87", height: "24px", width: "24px", cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation(); 
                              setModalDelOpen({ flag: true, document: afl }); 
                            }}
                          />
                        </div>
                        </>
                    ))
                  : null}

              </div>
            </div>
            
            <div style={{ borderRadius: "8px", border:darkMode ? "1px solid white" : "1px solid #CCCCCC", width:"100%",  }}>
              <MDBox sx={{padding:"20px"}}>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <ReportGmailerrorredIcon style={{ width: "20px", height: "20px", marginRight: "5px", color: "#2196F3" }} />
                <MDTypography style={{ fontSize: "14px", fontWeight: "600", color: darkMode ? "white" : "#2196F3" }}>Hint Message</MDTypography>
              </div>
              <MDTypography>{(location.state?.uploadedFile?.length && location.state?.uploadedFile?.filter((fl) =>
                fl?.fileName?.split('_')?.[0] === JSON.parse(modelName || "")?.name)?.length) ?
                <div style={{ paddingLeft: "35px", color: darkMode ? "white" : "#2196F3", fontSize: "14px", fontWeight: "400" }}>
                  Only one document can be stored in a model. Please delete already uploaded item to save new file.
                </div> : null}</MDTypography>
                </MDBox>
            </div>
            </div>
          </>
        }
      </div>
      <MDBox mt={2} mb={6} style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
        <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={trainingLoader} onClick={() => { modelSetup() }} variant="outlined" color={darkMode ? "white" : themeColor} fullWidth>
          Previous
        </MDButton>
        {/* <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={trainingLoader} onClick={()=> {modelSetup()}} variant="gradient" color={themeColor} fullWidth>
              Model Setup
            </MDButton> */}
        <MDButton style={{ width: "max-content", minWidth: "140px" }}
          disabled={!(location?.state?.beforeTrained?.sourceCol && location?.state?.beforeTrained?.featureList) || trainingLoader} onClick={() => { trainXAgent() }} variant="gradient" color={themeColor} fullWidth>
          {trainingLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'train'}
        </MDButton>


      </MDBox>

      {/* ========= Modal =============== */}

      {modalOpen &&
        <Modal open={modalOpen} setOpen={(fl) => setModalOpen(fl)}>
          <div className="flex flex-row w-full justify-between items-center text-xl font-semibold mb-4 " >
            <div>Select Columns</div>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => saveModel()} variant="gradient" color={themeColor} fullWidth>
              {previewUploadLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Save"}
            </MDButton>
          </div>

          {/* ====== Tags */}

          <div className="flex flex-row flex-wrap items-center w-full">
            <div className="font-semibold" style={{ fontSize: "18px" }}>Source:</div>
            {Object.values(dropdownValues)?.length ? Object.values(dropdownValues)?.filter((df) => df?.includes("-sourc"))?.map((df) => (
              <div className="mr-2 my-4">
                <Tag
                  name={df?.split("-sourc")?.[0]}
                  onClick={(e) => console.log("")}
                  isDelete={true}
                  onDelete={(e) => {
                    e.stopPropagation();
                    delete dropdownValues[df?.split("-sourc")?.[0]]
                    setDropdownValues({ ...dropdownValues })
                  }}
                  fontColor={"white"}
                  backgroundColor={df?.includes("source") ? "#006b3c" : "#E3E4E5"}
                  isLogo={false}
                />
              </div>
            )) : null}
          </div>

          <div className="flex flex-row flex-wrap items-center w-full">
            <div className="font-semibold" style={{ fontSize: "18px" }}>Features:</div>
            {Object.values(dropdownValues)?.length ? Object.values(dropdownValues)?.filter((df) => df?.includes("-featur"))?.map((df) => (
              <div className="mr-2 my-4">
                <Tag
                  name={df?.split("-featur")?.[0]}
                  onClick={(e) => console.log("")}
                  isDelete={true}
                  onDelete={(e) => {
                    e.stopPropagation();
                    delete dropdownValues[df?.split("-featur")?.[0]]
                    setDropdownValues({ ...dropdownValues })
                  }}
                  fontColor={"white"}
                  backgroundColor={df?.includes("feature") ? "#0d98ba" : "#E3E4E5"}
                  isLogo={false}
                  isWebToolTrain={true}
                />
              </div>
            )) : null}
          </div>

          {/* ====== Header & Dropdowns */}
          <div style={{ overflowX: 'auto' }}>
            <table className="w-full min-w-max table-fixed text-left">
              <thead>
                <tr className="flex flex-row border-transparent items-end w-full"
                  style={{
                    borderBottom: darkMode ? "2px solid white" : "2px solid black",
                    borderColor: darkMode ? "white" : "black",
                    width: "100%",
                    fontSize: "16px",
                    marginTop: "30px",
                    // height: "100px"
                  }}
                >
                  {uploadfilePreviewList?.rows?.length ? Object?.keys(uploadfilePreviewList?.rows?.[0])?.map((aObj, i) => (
                    <th key={i}
                      style={{
                        // width: `${100 / uploadfilePreviewList?.columns?.length}%`,
                        width: '200px',
                        // width: 'max-content'
                      }}
                      className="flex flex-row items-end font-bold">
                      <div className="mb-1 tableCellWrap" style={{ maxWidth: '190px' }}>
                        {aObj}
                      </div>
                      <div className="">
                        {InputNewFields?.length ? InputNewFields.filter((fl) => (fl.apiName === aObj)).map((item, index) => {
                          return (
                            <div style={{ width: "90px" }}>
                              <Dropdown options={item?.arr}
                                onSelection={(selectioedOpion) => {
                                  if (selectioedOpion?.apiName?.includes('source') && Object.values(dropdownValues)?.some((it) => it.toLowerCase().includes('source'))) {
                                    toast.error("Source already assigned. Only one column can be used as a source")
                                  } else {
                                    setDropdownValues({ ...dropdownValues, [item.apiName]: selectioedOpion?.apiName });
                                  }
                                }} />
                            </div>
                          )
                        }) : []}
                      </div>
                    </th>
                  )) : ''}
                </tr>
              </thead>

              {/* ============== List  ================ */}

              <tbody>
                {uploadPreviewLoader ?
                  <tr className="w-full flex mt-2">
                    {[1, 2, 3].map(() => (
                      <td className="w-56 mr-8">
                        <Code backgroundColor="grey" foregroundColor="darkgrey" />
                      </td>
                    ))}
                  </tr>
                  :
                  <tr style={{ height: "60vh", overflow: 'auto', scrollBehavior: 'smooth' }}>
                    {uploadfilePreviewList?.rows?.length ? uploadfilePreviewList?.rows?.map((aTable, i) => (
                      <div className={`flex flex-row pt-4 border-0 items-center w-full`}
                        style={{
                          overflowX: 'auto',
                          borderBottom: darkMode ? "1px solid white" : "1px solid black",
                          borderColor: darkMode ? "white" : "black",
                        }}>
                        {Object?.values(aTable)?.map((aObj) => (
                          <td className="pr-2"
                            style={{
                              fontSize: "14px",
                              wordWrap: "break-word",
                              overflowWrap: "break-word",
                              // width: `${100 / uploadfilePreviewList?.columns?.length}%`,
                              width: '200px',
                            }}>
                            {aObj}
                          </td>
                        ))}
                      </div>
                    )) : <td className="text-red-500 pt-4">No model found</td>
                    }
                  </tr>
                }
              </tbody>
            </table>
          </div>


        </Modal>
      }

      {
        modalDelOpen?.flag &&
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, document: modalDelOpen.document })}>
          <div className="flex flex-row w-full justify-center items-center  font-semibold mb-4" >
            Are you sure you want to delete "{modalDelOpen?.document?.fileName}"? Deleted document will be lost
          </div >

          <div className="mt-6 flex flex-row w-full justify-center items-center font-semibold" style={{ gap: "20px" }}>
            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => setModalDelOpen({ flag: false, document: {} })} variant="gradient" color={"error"} fullWidth>
              {"No"}
            </MDButton>

            <MDButton style={{ width: "max-content", minWidth: "140px" }} disabled={false} onClick={() => { deleteDocument() }} variant="gradient" color={themeColor} fullWidth>
              {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
            </MDButton>
          </div>
        </Modal>
      }

    </div >
  );
};
