import { TokenService } from "libraries/TokenService";
import axios from "axios";
import ApiUrls from "./ApiUrls"; // Assuming ApiUrls contains necessary API endpoints
import { Paths } from "libraries/Route";

class Service {
  constructor() {
    this.api = axios.create({
      baseURL: ApiUrls.baseUrl, // Update with your actual API base URL
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // this.navigate = useNavigate(); // Assuming this is needed for navigation

    // Request Interceptor to add token to every request
    this.api.interceptors.request.use(
      (config) => {
        console.log("Making request to:", config.url);

        const accessToken = TokenService.getAccessToken();
        if (accessToken) {
          config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Response Interceptor to handle 401 errors and token refresh
    this.api.interceptors.response.use(
      (response) => response, // Return the response if no error
      async (error) => {
        const originalRequest = error.config;
        // Check if the error is 401 and it is not the refresh token request itself
        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true; // Mark request as retried

          try {
            // Call the refresh token function
            const result = await this.callRefreshTokenApi();

            // If refresh token API is successful, retry the original request with the new token
            if (result?.isSuccess) {
              originalRequest.headers['Authorization'] = `Bearer ${TokenService.getAccessToken()}`;
              return this.api(originalRequest); // Retry the original request
            }
            // else {
            //   window.location.replace(Paths.login); // If refresh failed, redirect to login
            // }
          } catch (refreshError) {
            // Token refresh failed, clear tokens and redirect to login
            TokenService.clearTokens(true);
            window.location.replace(Paths.login);
            return Promise.reject(refreshError);
          }
        }

        if (error.response?.status === 401) return null
        else return Promise.reject(error?.response?.data); // If the error is not 401 or refresh fails, reject it
      }
    );
  }

  // Call Refresh Token API
  async callRefreshTokenApi() {
    const refreshToken = TokenService.getRefreshToken(); // Get the current refresh token

    // Define the URL for refresh token
    // const refreshUrl = `${this.baseUrl}/auth/login/refresh-token`;

    try {
      // Make the API call to refresh the token
      const response = await axios.get(ApiUrls.refreshToken, {
        headers: {
          Authorization: `Bearer ${refreshToken}` // Use refresh token in the Authorization header
        }
      });

      // If the API call is successful and the response contains tokens
      if (response?.status === 200) {
        const { accessToken, refreshToken } = response.data;

        // Update the TokenService with new tokens
        TokenService.setAccessToken(accessToken);
        TokenService.setRefreshToken(refreshToken);

        return { isSuccess: true };
      } else {
        window.location.replace(Paths.login)
        // return { isSuccess: false }; // Handle failure case
      }
    } catch (error) {
      window.location.replace(Paths.login)
      // return { isSuccess: false }; // Return failure in case of any error
    }
  }


  // GET request with token
  async getRequest(url, data) {
    return this.api.get(url, { params: data });
  }

  // GET request with custom token
  async getParamTokenRequest(url, data, token) {
    return this.api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: data,
    });
  }

  // GET request without token
  async getRequestWithoutToken(url, data) {
    return this.api.get(url, { params: data });
  }

  // POST request (JSON or FormData)
  async postRequest(url, body, param, isForm) {
    var formData = new FormData();

    if (isForm) {
      return this.api.post(url, body, {
        headers: { "Content-Type": "multipart/form-data" },
        params: param,
        data: formData
      });
    }
    return this.api.post(url, body, { params: param });
  }

  // POST request for uploading images
  async postRequestImage(url, body, param) {
    return this.api.post(url, body, {
      responseType: "arraybuffer",
      params: param,
    });
  }

  // POST request for stream with FormData
  async postRequestStream(url, body, param) {
    return this.api.post(url, body, {
      headers: { "Content-Type": "multipart/form-data" },
      responseType: "arraybuffer",
      params: param,
    });
  }

  // POST request with stream and JSON
  async postRequestStreamWithJSON(url, body, param) {
    return this.api.post(url, body, {
      headers: { "Content-Type": "application/json" },
      responseType: "arraybuffer",
      params: param,
    });
  }

  // POST request for stream without FormData
  async postRequestStreamWithoutFormData(url, body, param) {
    return this.api.post(url, body, {
      responseType: "arraybuffer",
      params: param,
    });
  }

  // PUT request with token
  async putRequest(url, body, param, isForm) {
    var formData = new FormData();

    if (isForm) {
      return this.api.put(url, body, {
        headers: { "Content-Type": "multipart/form-data" },
        params: param,
        data: formData
      });
    }
    return this.api.put(url, body, { params: param });
  }

  // PATCH request with token
  async patchRequest(url, id, body) {
    return this.api.patch(`${url}${id}/`, body);
  }

  // Upload file to GCP (Google Cloud Platform)
  async uploadFileToGCP(url, headers, body) {
    return this.api.put(url, body, { headers });
  }

  // POST request for text-to-speech conversion and return blob
  async postBlobTextToSpeech(url, body, param, isForm) {
    try {
      // If it's form data, the body will be passed as FormData directly
      const dataToSend = isForm ? body : body;
  
      const response = await this.api.post(url, dataToSend, {
        responseType: 'blob', // To handle blob response
        params: param,
        headers: isForm ? { "Content-Type": "multipart/form-data" } : {}
      });
      return response;
    } catch (error) {
      throw error; // Forward the error
    }
  }

  //POST Chatbot
  //Add this function to the ApiService class
  async postChatbotAgent(url, data) {
    try {
        const response = await this.api.post(url, data, {
            responseType: 'blob',
            params: {},
            headers: { 'Content-Type': 'application/json'}
        });

        return response;
      } catch (error) {
        throw error; // Forward the error
      }
  }

}

const ApiService = new Service();
export default ApiService;