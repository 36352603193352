/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import ChatHistoryNavRoot from "examples/Sidenav/ChatHistoryNavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {  useMaterialUIController,setMiniChatbotSidenav, setTransparentSidenav, setWhiteSidenav, setChatbotHistoryUrl } from "context";
import { Images } from "libraries/Images";
import ApiRequest from "services/ApiRequest";
import Modal from "examples/Modal/Modal";
import { toast } from "react-toastify";
import { SpinnerLoader } from "examples/Loader/Loader";
import { useParams } from "react-router-dom";
import { RiChatNewLine } from "react-icons/ri";


function ChatHistoryNav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniChatbotSidenav, transparentSidenav, whiteSidenav, darkMode, themeColor, chatbotInitial, chatbotHistoryUrl } = controller;
  const [conversationHistory, setConversationHistory] = useState([]);
  const location = useLocation();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const collapseName = pathname.replace("/", "");
  const [modalDelOpen, setModalDelOpen] = useState({flag: false, id: ""});
  const [historyLoader, setHistoryLoader] = useState(true);
  const [delLoader, setDelLoader] = useState(false);
  const param = useParams()


  let textColor = "white";

  // if (transparentSidenav || (whiteSidenav && !darkMode)) {
  //   textColor = "dark";
  // } else if (whiteSidenav && darkMode) {
  //   textColor = "inherit";
  // }

  const closeSidenav = () => setMiniChatbotSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200);
      setMiniChatbotSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const colorTheme = themeColor === "info" ? "#1E4585" : themeColor === "success" ? "#70B244" : "black" 

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = historyLoader ? 
  <SpinnerLoader style={{ color:  "white", fontSize: "20px" }}  adjustment={true} /> 
  : conversationHistory?.sort((a,b) => new Date(b?.dateModified).getTime() - new Date(a?.dateModified).getTime() )?.map(({ conversationId, conversationName, dateModified }) => {
    let returnValue;

    returnValue = (<div
        key={conversationId}
        onClick={()=> navigate(`/patterns/${location?.state?.id}/llm/agent-creation/infer/${conversationId}/llm`,
              { state: {...location?.state}}
          )}
        sx={{ textDecoration: "none" }}
        >
        <SidenavCollapse
          name={conversationName?.length > 20 ? `${conversationName?.substring(0,20)}...` : conversationName}
          id={conversationId}
          icon={""}
          delIcon={"delete"}
          noCollapse={false}
          dateModified={dateModified}
          clickDel={(e, id)=> {e.stopPropagation(); 
            if(window.innerWidth < 1200) closeSidenav() ;
            setModalDelOpen({flag: true, id: id}) }}
          active={pathname?.includes(`${conversationId}`)}
          // noCollapse={noCollapse}
        />
    </div>
    )

    return returnValue;
  });

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
        if(chatbotHistoryUrl) {
          allChatsHistoryList(false);
        }
  }, [ chatbotInitial, chatbotHistoryUrl]);

  // Get All Chats History
  const allChatsHistoryList = (isDelete) => {
    setHistoryLoader(true);
    if(chatbotHistoryUrl && pathname?.includes('llm/agent-creation/infer')){
      ApiRequest.listChatbotHistory('', `${chatbotHistoryUrl}`, (res) => {
        setHistoryLoader(false);

        if(res?.data){
          if(chatbotInitial && !isDelete) {
            const sortedConversation = res?.data?.sort((a,b) => new Date(b?.dateModified).getTime() - new Date(a?.dateModified).getTime() );
            navigate(`/patterns/${location?.state?.id}/llm/agent-creation/infer/${sortedConversation?.[0]?.conversationId}/llm`,
              { state: {...location?.state}})
          };
          setConversationHistory(res?.data?.filter(con => con.conversationName !== 'exit'));
        }else{
          setConversationHistory([])
        }
      });
    }
  };

  const deleteModal = (id) => {
    if(chatbotHistoryUrl){
      setDelLoader(true);
      // setChatbotHistoryUrl(dispatch, '');

      ApiRequest.deleteChatbotConversation('', 
      `${chatbotHistoryUrl?.split('/chatbot-agent/listpreviouschat/')?.[0]}/chatbot-agent/deleteconversation/${id}`, (res) => {
        setDelLoader(false);
        if(res?.data){
          toast.success(res?.data ? res?.data?.message : "Conversation deleted successfully");
          setModalDelOpen({flag: false, id: ""});
          allChatsHistoryList(true);
          navigate(`/patterns/${location?.state?.id}/llm/agent-creation/infer/llm`,
              { state: {...location?.state}}
          )
        }else{
          toast.error(res?.error ? res?.error?.message : "Something went wrong");
        }
      });
    }
  }

  return (
    <ChatHistoryNavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniChatbotSidenav, darkMode }}
    >
      {window.innerWidth <= 1200 && 
      <MDBox pt={1} pb={3} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold", color: "#FFFFFF" }}>close</Icon>
          </MDTypography>
        </MDBox>
        
      </MDBox>
      }
      
      <List>
        <div onClick={()=> navigate(`/patterns/${location?.state?.id}/llm/agent-creation/infer/llm`,
              { state: {...location?.state}}
          )} style={{ padding: "20px 30px 40px 30px", cursor: "pointer", font: "bold", 
                    display: "flex", alignItems: "center", 
                    // backgroundColor: colorTheme,
                    color: "white" }}>
         
         <RiChatNewLine className="mr-2 cursor-pointer" /> 
                        New Chat
          </div>
          {/* <div style={{ overflowY: "auto" }}> */}
          {renderRoutes}
          {/* </div> */}
      </List>
      

      {modalDelOpen?.flag &&
            <Modal open={modalDelOpen?.flag} setOpen={(fl) => {setModalDelOpen({flag: fl, id: ''}) }}>
              <div>
                <div className="flex flex-row w-full justify-center items-center mb-4" >
                  Are you sure you want to delete conversation? 
                </div >

                <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
              <MDButton style={{width: "140px"}} disabled={false} 
                            onClick={() => setModalDelOpen({flag: false, id: ""})}
                        variant="gradient" color={"error"} fullWidth>
                          No
              </MDButton>

              <MDButton style={{width: "140px"}} disabled={delLoader} 
                onClick={() => deleteModal(modalDelOpen?.id)}
                variant="gradient" color={themeColor} fullWidth>
                  {delLoader ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : "Yes"}
              </MDButton>
                </MDBox>
              </div>
              
            </Modal>
      }
    </ChatHistoryNavRoot>
  );
}

// // Setting default values for the props of Sidenav
// Sidenav.defaultProps = {
//   color: "info",
//   brand: "",
// };

// // Typechecking props for the Sidenav
// Sidenav.propTypes = {
//   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
//   brand: PropTypes.string,
//   brandName: PropTypes.string.isRequired,
//   routes: PropTypes.arrayOf(PropTypes.object).isRequired,
// };

export default ChatHistoryNav;
