import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {ReactComponent as Deploy} from "../../assets/images/flow-icons/deploy icon.svg"
import {ReactComponent as Protect} from "../../assets/images/flow-icons/protect.svg"
import {ReactComponent as Model} from "../../assets/images/flow-icons/model icon.svg"
import {ReactComponent as Result} from "../../assets/images/flow-icons/result icon (1).svg"
import {ReactComponent as Train} from "../../assets/images/flow-icons/Train icon.svg"
import {ReactComponent as Tool}  from "../../assets/images/flow-icons/tools.svg" 
import {ReactComponent as Design}  from "../../assets/images/flow-icons/interface icon.svg" 
import {ReactComponent as Inference}  from "../../assets/images/flow-icons/inference-doc.svg" 

import { useMaterialUIController } from "context";
import { getColorVlue } from 'services/Utils';
import useScreenWidth from "libraries/ScreenSizeHook";
import Constants from "libraries/Constants";


const ColorlibConnector = styled(StepConnector)(({ theme, windowSize }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: windowSize.innerWidth > 600 ? 18 : 15,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white"
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "white"
      // backgroundImage:
      //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:(stepConnectorClasses.completed || stepConnectorClasses.active) ? "white  " : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState, windowSize }) => ({
  backgroundColor: (ownerState.active || ownerState.completed) ? "white" : '#ccc',
  zIndex: 1,
  color: '#fff',
  // border: ownerState.active  && "6px solid #FCF6BA",
  // borderWidth: ownerState.active && "2px",
  // borderStyle: ownerState.active && "solid",
  // borderImage: ownerState.active && "linear-gradient(to right, #BF953F, #FCF6BA)",
  width: windowSize.innerWidth > 600 ? 40 : 30,
  height:  windowSize.innerWidth > 600 ? 40 : 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && { 
    // backgroundImage: "blue",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const [windowSize, getWindowSize] = useScreenWidth();
  const dynamicSize = windowSize.innerWidth > 600 ? "25px" : "20px";
  const { name, id } = useParams()
  const [icons, setIcons] = useState(null)

  const [controller, dispatch] = useMaterialUIController();
  const { themeColor, darkMode } = controller;
  const collapseName = location.pathname.replace("/", "");


  const ChatBotFlowIcons = {
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <Tool fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    3: <Protect fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    4: <Train fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
  };

  const FEFlowIcons = {
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <Deploy fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    3: <Model fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    4: <Train fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    5: <Result fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
  };

  const RecommendationFlowIcons = {
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <Deploy fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    3: <Model fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
    4: <Train fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
  };

  const ImageToTextFlowIcons = {
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <Inference fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
  };

  const WidgetFlowIcons = {
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <Design fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize}}/>,
  };

  const LLMFunctionsIcon ={
    1: <SettingsIcon color={themeColor} style={{width: dynamicSize, height: dynamicSize}}/>,
    2: <SearchIcon fill={getColorVlue(themeColor)} style={{width: dynamicSize, height: dynamicSize,color:getColorVlue(themeColor)}}/>,
  }
  

  useEffect(() => {
    if(name === Constants.chatbotRoute){
      setIcons(ChatBotFlowIcons)
    }
    else{
      if(name ===  Constants.featureExtractionRoute || name === Constants.predictiveAnalysisRoute){
        setIcons(FEFlowIcons)
      }else if(name === Constants.recommendationRoute){
        setIcons(RecommendationFlowIcons)
      }else if(name === Constants.imageToTextRoute){
        setIcons(ImageToTextFlowIcons)
      }else if (collapseName === "llm-function/llm-creation/config") {
        setIcons(LLMFunctionsIcon)
      }else{
        setIcons(WidgetFlowIcons)
      }
    }
  }, [name, themeColor])
  

  if(icons){
    return (<ColorlibStepIconRoot windowSize={windowSize} ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
    );
  }
};


export default function MDStepper({children, activeStep, steps}) {
  const [controller, dispatch] = useMaterialUIController();
  const [windowSize, getWindowSize] = useScreenWidth();
  const { themeColor, darkMode } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const { name, id } = useParams();

  const navigation = (route, page, step) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: location?.state?.objectId,
        page: page, step: step,
        configForm: location?.state?.configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        protect: location?.state?.protect,
        isDeploying: false,
        uploadedFile:  location?.state?.uploadedFile,
        isEditAgent: location?.state?.isEditAgent,
        isSqlTool:location?.state?.isSqlTool,
        isTrained: location?.state?.isTrained,
        result: location?.state?.result,
        beforeTrained: location?.state?.beforeTrained
      }
    });
  };

  const navigationWidget = (route, page, step) => {
    navigate(`/widgets/widget-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        objectId: location?.state?.objectId,
        page: page, step: step,
        widgetConfigForm: location?.state?.widgetConfigForm,
        widgetDesignForm: location.state?.widgetDesignForm,
      }
    });
  }

  const navigationLLMFunction = (route, page, step) => {
    navigate(`/llm-function/llm-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        objectId: location?.state?.objectId,
        page: page, step: step,
        llmFunctionsConfigForm: location?.state?.llmFunctionsConfigForm,
        llmFunctionTestForm: location?.state?.llmFunctionTestForm,
      }
    });
  };
  const connectTabs = (label) => {
    if(activeStep > steps.indexOf(label)){
      const updatedLabel = (label === "Configure" || "Config") ? "config" : label?.toLowerCase();
      if(location?.pathname?.includes("widget-creation")){
        navigationWidget(`/${updatedLabel}`, steps.indexOf(label)+1, steps.indexOf(label));
      }else if(location?.pathname?.includes("llm-creation")){
        navigationLLMFunction(`/${updatedLabel}`, steps.indexOf(label)+1, steps.indexOf(label));
      }else{
        navigation(`/${updatedLabel}`, steps.indexOf(label)+1, steps.indexOf(label));
      }

      // navigate(`/patterns/${id}/${name}/agent-creation/config`,{ state: {...location?.state, page: steps.indexOf(label)+1, step: steps.indexOf(label) }})
    }
  }

  return (
    <div className="flex flex-row justify-center w-full">
      <Grid container spacing={2} 
            style={{width: windowSize.innerWidth > 600 ? "80%" : "95%", 
            marginLeft: windowSize.innerWidth < 600 ? "18px" : "0px",
            paddingTop: "80px"}}>
        <Grid item xs={12}>
        <Card style={{width: "100%"}}>
          <MDBox
            variant="gradient"
            bgColor={themeColor}
            coloredShadow={themeColor}
            borderRadius="lg"
            mx={windowSize.innerWidth > 600 ? 10 : 2}
            mt={-8}
            mb={1}
            textAlign="center"
            className="sqlTools"
          >
            <Stack sx={{ width: '100%' }} spacing={4}>
                <Stepper alternativeLabel activeStep={activeStep || 0} sx={{background: getColorVlue(themeColor)}} 
                style={{boxShadow: "none"}}   connector={<ColorlibConnector windowSize={windowSize} />}>
                {steps.map((label) => (
                  <Step key={label} style={{ cursor: "pointer" }}  
                        onClick={(e)=> connectTabs(label) }>
                    <StepLabel   StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
          </MDBox>

          <MDBox pt={1} pb={1} px={3} className="flex" >
            <MDBox component="form" role="form" className="w-[100%] ">
                  <div >
                    {children}
                  </div>
            </MDBox>
          </MDBox>
        </Card>
      </Grid>         
      </Grid>
    </div>
      
  );
}
