/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// HorizontalBarChart configurations
import configs from "examples/Charts/BarCharts/HorizontalBarChart/configs";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import ApiRequest from "services/ApiRequest";

ChartJS.register(CategoryScale,ArcElement, LinearScale, BarElement, Title, Tooltip, Legend);

const HorizontalBarChart = React.memo(({ icon, title, description, height }) => {
  const [dashboardStats, setDashboardStats] = useState({
    noOfRequests: [],
  });
  const [isLoader, setIsLoader] = useState(true);
  useEffect(()=>{
    allDashboardStats()
  },[])
  const allDashboardStats = () => {
    setIsLoader(true)
    ApiRequest.dashboardChartStats('', '', (res) => {
      if (res.isSuccess) {
        const { agentsByNumberOfRequests } = res.data
        const { patternAPIRequests } = agentsByNumberOfRequests[0]
        setDashboardStats({
          noOfRequests: patternAPIRequests,
        });

        setIsLoader(false)
      } else {
        setIsLoader(false)
      }
    });
  };
  const sortedNoOfRequests = (dashboardStats?.noOfRequests || [])?.sort((a, b) => b?.totalAPIRequests - a?.totalAPIRequests);
  const ChartNoOfRequest = {
    labels: sortedNoOfRequests.map(item => item.patternName), 
    datasets: [{
      label: 'Total No Of Requests per Pattern',
      data: sortedNoOfRequests.map(item => item.totalAPIRequests || 0),
      backgroundColor: '#42A5F5', 
    }],
  };
  const getColorByValue = (value, maxValue, minValue) => {
    if (value === maxValue) return "#D90000";  
    if (value === minValue) return "#495361";  
    return "#1A73E8";                          
  };

  const chartDatasets = ChartNoOfRequest?.datasets?.length
    ? ChartNoOfRequest.datasets.map((dataset) => {
      const maxValue = Math.max(...dataset?.data);
      const minValue = Math.min(...dataset?.data);
      return {
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: dataset?.data?.map(value => getColorByValue(value, maxValue, minValue)) || "#1F77B4",
        hoverBackgroundColor: dataset?.data?.map(value => getColorByValue(value, maxValue, minValue)) || "#FF0000",
        fill: false,
        maxBarThickness: 35,
      }
      })
    : [];

  const { data, options } = configs(ChartNoOfRequest.labels || [], chartDatasets);

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "dark"}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height}>
            <Bar data={data} options={options} redraw />
          </MDBox>
        ),
        [ChartNoOfRequest, height]
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
})

// Setting default values for the props of HorizontalBarChart
HorizontalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
};

// Typechecking props for the HorizontalBarChart
HorizontalBarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default HorizontalBarChart;
