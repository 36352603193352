import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Box, Autocomplete, Checkbox, TextField } from '@mui/material';
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import MDDropdown from "components/MDDropdown";
import { useNavigate, useLocation, useParams } from "react-router-dom";

//images
import functionAsTool from "assets/images/flow-icons/function-as-tool.svg";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import FunctionAsToolsTable from "components/MDTable/MDTableFunctionAsTool";
import { useDispatch } from "react-redux";
import { isSqlToolsAction } from "redux/Action";
import ApiRequest from "services/ApiRequest";
import { Tag } from "examples/Tag/Tag";
import MDButton from "components/MDButton";
import { useResponsive, breakpoints } from 'services/Utils';
import { Toggle } from "examples/Toggle/Toggle";
import AddBoxIcon from '@mui/icons-material/AddBox';
import LinkIcon from '@mui/icons-material/Link';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Icon from "@mui/material/Icon";
import { IoIosSquareOutline } from "react-icons/io";
import { InputFieldComponent } from "examples/InputField/InputField";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';


export const GoogleSearchTool = ({ isGoogleSearchEnable, setGoogleSearchEnable, searchURLs, setSearchURLs, googleURLs, setGoogleURLs, selectedGoogleUrls, setSelectedGoogleUrls  }) => {
    const [controller] = useMaterialUIController();
    const mediaQueries = useResponsive(breakpoints);
    const { isNestHub, isMdLoptop, isloptop, isTablet, isLargeMobile, isSmallMobile, isZoomLoptopScreen } = mediaQueries;
    const { darkMode, themeColor } = controller;
    const dispatch = useDispatch();
    const location = useLocation();
    // const [googleURLs, setGoogleURLs] = useState([]);
    // const [selectedGoogleUrls, setSelectedGoogleUrls] = useState([]); // State for selected URLs

    

    const handleChangeBox = () => {
        setGoogleSearchEnable(!isGoogleSearchEnable);
    };

    const addUrlInput = () => {
        setGoogleURLs(["", ...googleURLs]);
      };

      const handleCheckboxToggle = (index) => {
        if (selectedGoogleUrls.includes(index)) {
          setSelectedGoogleUrls(selectedGoogleUrls.filter(i => i !== index)); // Deselect the URL
        } else {
          setSelectedGoogleUrls([...selectedGoogleUrls, index]); // Select the URL
        }
    };

    const handleSelectAll = () => {
      if (selectedGoogleUrls.length === googleURLs.length) {
        setSelectedGoogleUrls([]); // Deselect all
      } else {
        setSelectedGoogleUrls(googleURLs.map((url, index) => index)); // Select all by using the index correctly
      }
  };
  

  const handleDeleteSelected = () => {
    setGoogleURLs(googleURLs.filter((_, index) => !selectedGoogleUrls.includes(index))); // Remove selected URLs
    setSearchURLs(searchURLs.filter((_, index) => !selectedGoogleUrls.includes(index))); // Remove selected URLs
    setSelectedGoogleUrls([]); // Clear the selected URLs after deletion
  };


  const handleInputChange = (index, e, name) => {
    const { value } = e?.target;
    const updatedSearchURLs = [...searchURLs];
    updatedSearchURLs[index] = value;
    setSearchURLs(updatedSearchURLs);
  };

  return (
        <div>
            <div style={{ padding: "5px" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", 
                  justifyContent: "space-between",  fontSize: "14px", fontWeight: "450", 
                  margin: "0px", color: darkMode ? "white" : "black" }}>
                                Google Search
                    {/* <Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} /> */}
              </div>
              <div className="w-full flex flex-row items-center" 
                style={{ borderRadius: "7px",border: "1px solid #CCCCCC", padding: "10px" }}>
                <div style={{ color: darkMode ? "white" : "black"}} className=" text-sm mr-4">Enable</div>
                <Toggle enabled={isGoogleSearchEnable} setEnabled={(fl) => handleChangeBox()} />
              </div>

          {isGoogleSearchEnable &&
            <div>
              <div style={{ borderRadius: "8px", border: "1px solid #2196F3", background: "#E3F2FD", margin: "20px 0px" }}>
              <MDBox sx={{padding:"20px"}}>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <ReportGmailerrorredIcon style={{ width: "20px", height: "20px", marginRight: "5px", color: "#2196F3" }} />
                <MDTypography style={{ fontSize: "14px", fontWeight: "600", color: "#2196F3" }}>Hint Message</MDTypography>
              </div>
              <MDTypography>{
                <div style={{ paddingLeft: "35px", color: "#2196F3", fontSize: "14px", fontWeight: "400" }}>
                  Use this tool to enable agent to search for queries from Google in realtime
                </div> }</MDTypography>
                </MDBox>
            </div>


            <MDBox sx={{ marginTop: "30px",padding: "15px", marginTop: "15px", 
                      border: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, borderRadius: "7px" }}>
              {/* <Grid style={{ width: "100%" }}> */}
                <div className="flex w-full justify-between"  
                      style={{ borderBottom: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, paddingBottom: "5px", marginBottom: "5px" }}>
                  <div className="flex items-center">
                    {googleURLs.length ===0 ? (
                      <IoIosSquareOutline
                      style={{fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                      onClick={() => handleSelectAll()}
                    />
                    ) :
                    (selectedGoogleUrls.length === googleURLs.length) ? (
                          <MdCheckBox
                            style={{ fontSize: "30px", marginRight: "10px",  cursor: "pointer", color: darkMode ? "white" :  getColorVlue() }}
                            onClick={() => handleSelectAll()}
                          />
                        ) : (
                          <IoIosSquareOutline
                            style={{fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                            onClick={() => handleSelectAll()}
                          />
                    )}
                    <MDTypography sx={{ fontSize: "14px", fontWeight: "450" }} style={{ color: darkMode ? "white" : "black" }}>
                      Included Links (Optional)
                    </MDTypography>
                    {/* <Tooltip uniqueId={"sqlprompt&query"} text={`The Google Search tool allows users to find relevant information, websites, images, and more across the web by entering keywords or queries into the search engine`} color={getColorVlue(themeColor)} /> */}
                  </div>
                  <div className="flex items-center" style={{marginTop:"-4px"}}>
                    {/* <MDButton size="small" sx={{
                      color: darkMode ? "#ec1313 !important" : "#FF0000!important", fontSize: "10px", padding: "7px !important",
                      display: "flex", gap: "4px", padding: "7px !important", fontSize: "10px !important", backgroundColor: darkMode ? "transparent !important" : "", marginTop: "4px"
                    }}
                      onClick={deleteAllInputs}>Delete</MDButton> */}

                    <Icon
                        sx={{ marginLeft: "15px", cursor: "pointer", color: darkMode ? "white !important" : ""}}
                        fontSize="small"
                        onClick={() => handleDeleteSelected()}
                      >
                        delete
                      </Icon>
                    <AddBoxIcon color={darkMode ? "white" : themeColor} className="mr-[5px] mt-[2px] pl-[5px] cursor-pointer flex justify-center items-center" fontSize="medium" onClick={() => addUrlInput()} />
                  </div>
                </div>


                <div>
                
                {googleURLs?.map((query, index) => {

                const isSelected = selectedGoogleUrls.includes(index); // Check if the URL is selected
                  
                  return(
                      <MDBox key={index} className={"flex w-full flex-wrap justify-between"} sx={{ display: "flex", marginTop: "10px" }}>
                    {/* gap: (isLargeMobile || isSmallMobile) ? "20px" : isMdLoptop ? "138px" : "120px" */}
                    <div style={{ width: "100%", display: 'flex', alignItems: "center"  }}>

                        {isSelected ? (
                          <MdCheckBox
                            style={{ fontSize: "30px", marginRight: "10px",  cursor: "pointer", color: darkMode ? "white" : getColorVlue() }}
                            onClick={() => handleCheckboxToggle(index)}
                          />
                        ) : (
                          <IoIosSquareOutline
                            style={{  fontSize: "30px", marginRight: "10px", cursor: "pointer", color: darkMode ? "white" : "#CCCCCC" }}
                            onClick={() => handleCheckboxToggle(index)}
                          />
                        )}

                      <InputFieldComponent
                            fi={{type: 'text', name: "searchURLs", placeholder: "Search URL"}}
                            config={searchURLs[index] || ""}
                            isNoLabel={true}
                            setConfig={(e) => { handleInputChange(index, e, "searchURLs")  } }
                          />
                    </div>
                      </MDBox>
                  )    
              })}

                </div>

              {/* </Grid> */}
            </MDBox>
            </div>
            }
        </div>

            
        </div>
    );
};
