/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Images } from "libraries/Images";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import DashboardStatisticsCard from "examples/Cards/StatisticsCards/DashboardStatisticsCard";
import { List } from "react-content-loader";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import { TablePagination } from "@mui/material";
import { useMaterialUIController } from "context";
import PieChart from "examples/Charts/PieChart";
import HalfDonutChart from "examples/Charts/PieChart/dashboardPiePercentageChart/PieChartWithPercentage";
import { getColorVlue } from 'services/Utils';
import StorageIcon from "assets/images/flow-icons/storage-icon.svg"
import { useDispatch, useSelector } from "react-redux";
import { TokenService } from "libraries/TokenService";
import { isLoginAction, isPermissionAction } from 'redux/Action';
import { Divider } from "@mui/material";
import DashboardDynamicPieChart from "examples/Charts/PieChart/DashboardDynamicPieChart";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useResponsive, breakpoints } from "services/Utils";
//images

import BarChartIcon from "assets/images/flow-icons/bar-chart.svg"
import usedStorageIcon from "assets/images/flow-icons/sql-icon.svg"
import useScreenWidth from "libraries/ScreenSizeHook";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";


function Dashboard() {
  const mediaQueries = useResponsive(breakpoints);
  const { isNestHub,isLargeLoptop, isMdLoptop, isloptop, isLargeMobile, isSmallMobile, isZoomLoptopScreen } = mediaQueries;
  const [dashboardStats, setDashboardStats] = useState({
    noOfRequests: [],
    agentsByStatus: { labels: [], data: [] },
    agentsByPattern: { labels: [], data: [] }
  });
  const [storage, setStorage] = useState({ allowedStorage: "", totalOccupiedSize: "" })
  const [storagePercentage, setStoragePercentage] = useState()
  const [storageMbValue, setStorageMbValue] = useState({ allowedStorage: "", totalOccupiedSize: "" })
  const [tokens, setTokens] = useState({ allowedStorage: "", totalOccupiedSize: "" })
  const [tokensPercentage, setTokensPercentage] = useState()
  const [windowSize, getWindowSize] = useScreenWidth();
  const [isLoader, setIsLoader] = useState(true);
  const navigate = useNavigate();
  const [controller] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const LoginType = localStorage.getItem('loginType');
  const [patternsList, setPatternsList] = useState();
  const permissionUser = useSelector((state) => state?.loginReducer?.permission);
  const isUpgradePlan = new URLSearchParams(location?.search)?.get('isSubscriptionPlanChanged');
  const [permissionPlan, setPermissionPlan] = useState();
  const [planName, setPlanName] = useState('')
  const [usedAndTotalLimits, setUsedAndTotalLimits] = useState({ allowedAgents: 0, totalAgents: 0, allowedWidgets: 0, totalWidgets: 0, allowedFunctions: 0, totalFunctions: 0 })
  const dispatch = useDispatch();
  const [agentsByStorage, setAgentsByStorage] = useState([]);
  const [agentsByNumberOfRequestsPerDay, setAgentsByNumberOfRequestsPerDay] = useState([]);
  const [agentTokenConsumed, setAgentTokenConsumed] = useState([]);
  const [selectedData, setSelectedData] = useState("storage");
  const [currentChartIndex, setCurrentChartIndex] = useState(0);
  const [selectedTokenConsumedData, setSelectedTokenConsumedData] = useState("All Token Consumed"); // This state will store "day", "week", or "month"


  const pieChartsData = [
    { value: usedAndTotalLimits?.totalAgents, total: usedAndTotalLimits?.allowedAgents, title: 'Agents Created' },
    { value: usedAndTotalLimits?.totalWidgets, total: usedAndTotalLimits?.allowedWidgets, title: 'Widgets Created' },
    { value: usedAndTotalLimits?.totalFunctions, total: usedAndTotalLimits?.allowedFunctions, title: 'Functions Created' }
  ];

  const sortedAgentsByStorage = (agentsByStorage || [])?.sort((a, b) => b?.storageConsumed - a?.storageConsumed);
  const sortedAgentsByRequests = (agentsByNumberOfRequestsPerDay || [])?.sort((a, b) => b.requestCount - a.requestCount);
  const sortedAgentsByTokens = (agentTokenConsumed || [])?.sort((a, b) => b?.totalTokensConsumed - a?.totalTokensConsumed);
  
  const chartData = {
    storage: {
      labels: sortedAgentsByStorage.map(agent => agent?.agentName) || [],
      datasets: [
        {
          label: "Storage Consumed (MB)", 
          data: sortedAgentsByStorage.map(agent => (agent?.storageConsumed / (1024 * 1024))) || [], 
          backgroundColor: ["#FF0000", "#1F77B4", "#1F77B4"], 
        },
      ],
    },
    requests: {
      labels: sortedAgentsByRequests.map(agent => agent?.agentName) || [], 
      datasets: [
        {
          label: "Requests",
          data: sortedAgentsByRequests.map(agent => agent?.requestCount) || [], 
          backgroundColor: ["#FF0000", "#1F77B4", "#1F77B4"], 
        },
      ],
    },
    tokenConsumed: {
      labels: sortedAgentsByTokens.map(agent => agent?.agentName) || [], 
      datasets: [
        {
          label: "Token Consumed",
          data: sortedAgentsByTokens.map(agent => agent?.totalTokensConsumed) || [], 
          backgroundColor: ["#FF0000", "#1F77B4", "#1F77B4"], 
        },
      ],
    },
  };



  const handleSelection = (selection) => {
    setSelectedData(selection); // Update the state when user selects
  };

  useEffect(() => {
    allPatternsList();
    if (LoginType === 'google') {
      toast.success('User Login successfully');
      localStorage.removeItem('loginType')
    }
    if (LoginType === 'microsoft') {
      toast.success('User Login successfully');
      localStorage.removeItem('loginType')
    }


    // if(isUpgradePlan){
    //   setTimeout(()=> {
    //     permissionBilling();
    //     getStorageDetail();
    //   },[3000]);
    // }else{
    permissionBilling();
    getStorageDetail();
    // }
  }, []);

  const permissionBilling = () => {
    ApiRequest.userPermissionStatus(TokenService.getAccessToken(), {}, '', (res3) => {
      if (res3.isSuccess) {
        setPermissionPlan(res3?.data?.membershipLimits?.currentPlanType);
        // localStorage.setItem("membershipPlan", JSON.stringify({ permission: res3?.data?.membershipLimits}));

        dispatch(isPermissionAction(res3?.data?.membershipLimits));
      }
    });
  };


  const allPatternsList = () => {
    ApiRequest.patternList("?page=1", "", (res) => {
      allDashboardStats();
      if (res.isSuccess) {
        setPatternsList(res.data?.patternsList);
      } else {
        setPatternsList([]);
      }
    });
  };


  const dashboardTokenConsumedStats = (filterType) => {
    const payload = {
      filterType: filterType || ""
    }
    ApiRequest.dashboardTokenConsumedStats(payload, "", (res) => {

      if (res.isSuccess) {
        setAgentTokenConsumed(res?.data?.agentsByTokenUsage || [])
      } else {
        console.log("error")
      }
    });
  };
  const handleTokenConsumeData = (selection) => {
    if (selection === 'All Token Consumed') {
      setSelectedTokenConsumedData("");
      dashboardTokenConsumedStats("");
    } else {
      setSelectedTokenConsumedData(selection);
      dashboardTokenConsumedStats(selection);
    }
  };
  const allDashboardStats = () => {
    setIsLoader(true)
    ApiRequest.dashboardChartStats('', '', (res) => {
      if (res.isSuccess) {
        const { agentsByNumberOfRequests } = res.data
        const { patternAPIRequests } = agentsByNumberOfRequests[0]

        setDashboardStats({
          noOfRequests: patternAPIRequests,
        });
        setAgentsByStorage(res?.data?.agentsByStorage || [])
        setAgentsByNumberOfRequestsPerDay(res?.data?.agentsByNumberOfRequestsPerDay || [])

        setIsLoader(false)
      } else {
        setIsLoader(false)
      }
    });
  };
  const getStorageDetail = () => {
    ApiRequest.storageUsage("", (res) => {
      if (res.isSuccess) {
        const usedAndTotalValues = res?.data?.membershipLimits
        const storageValue = res?.data?.membershipLimits?.storageLimits;
        const allowedStorageGB = storageValue?.allowedStorage;
        const totalOccupiedSizeGB = storageValue?.totalStorage;
        const allowedStorageMB = allowedStorageGB * 1024;
        const totalOccupiedSizeMB = totalOccupiedSizeGB * 1024;

        const tokensValue = res?.data?.membershipLimits?.tokenLimits;
        const allowedTokens = tokensValue?.allowedTokens;
        const totalOccupiedTokensSize = tokensValue?.totalTokens;
        setStorage({
          allowedStorage: allowedStorageGB,
          totalOccupiedSize: totalOccupiedSizeGB
        });
        setStorageMbValue({
          allowedStorage: allowedStorageMB,
          totalOccupiedSize: totalOccupiedSizeMB
        })
        const percentage = (totalOccupiedSizeGB / allowedStorageGB) * 100;
        setStoragePercentage(percentage);

        setTokens({
          allowedStorage: allowedTokens,
          totalOccupiedSize: totalOccupiedTokensSize
        });
        setUsedAndTotalLimits({
          allowedAgents: usedAndTotalValues?.agentLimits?.allowedAgents, totalAgents: usedAndTotalValues?.agentLimits?.totalAgents
          , allowedWidgets: usedAndTotalValues?.widgetLimits?.allowedWidgets, totalWidgets: usedAndTotalValues?.widgetLimits?.totalWidgets,
          allowedFunctions: usedAndTotalValues?.functionLimits?.allowedFunctions, totalFunctions: usedAndTotalValues?.functionLimits?.totalFunctions
        })

        const tokensPercentage = (totalOccupiedTokensSize / allowedTokens) * 100;
        setTokensPercentage(tokensPercentage);

        setPlanName(res?.data?.membershipLimits?.currentPlanType)
        // toast.success(res?.data?.message ? res?.data?.message : "Query successful.");
      } else {
        toast.error(res?.error?.message ? res?.error?.message : "Query Unsuccessful.")
      }
    });
  };

  // const options = {
  //   plugins: {
  //     legend: {
  //       labels: {
  //         color: 'green', // Change this to the desired color
  //       },
  //     },
  //   },
  // };
  const handleNext = () => {
    setCurrentChartIndex((prevIndex) =>
      prevIndex === 2 ? 0 : prevIndex + 1  // Cycle through charts (assuming you have 3 pie charts)
    );
  };

  const handlePrevious = () => {
    setCurrentChartIndex((prevIndex) =>
      prevIndex === 0 ? 2 : prevIndex - 1  // Cycle back through charts
    );
  };


  const DATA_COUNT = 5;
  const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>
        <DashboardNavbar />
        {/* overflowY: "auto", height: "calc(100vh - 110px)" */}
        <MDBox style={{ overflowY: "auto", height: "calc(100vh - 130px)" }}>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <div style={{ padding: "10px" }}>
                {
                  isLoader
                    ?
                    <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                    :
                    <MDBox style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
                      <MDBox style={{
                        paddingTop: dashboardStats?.noOfRequests?.length && "15px",
                        // overflowY: "auto", height: "calc(100vh - 270px)"
                      }}>
                        <Card sx={{ height: '750px', padding: '10px' }}>
                          <MDTypography sx={{ fontSize: "16px", fontWeight: "600", color: darkMode ? "white" : "#353535", padding: "0px 20px" }}>Training & Inference made</MDTypography>
                          <div className="mt-[10px]">
                            {dashboardStats?.noOfRequests?.length ? (
                              <HorizontalBarChart
                              height={ "650px"}
                            />
                            ) : (
                              <Card>
                                <MDBox display="flex" justifyContent="space-between" py={2} px={2}>
                                  <div style={{ color: "red", fontSize: "16px" }}>No record found</div>
                                </MDBox>
                              </Card>
                            )}
                          </div>
                        </Card>
                      </MDBox>
                     </MDBox>
                }
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sx={{ height: "h-[750px]" }} >
              <div className={`flex ${(isSmallMobile || isLargeMobile ? "gap-[7px]" :isZoomLoptopScreen ? "gap-[22px]" :isNestHub ? "gap-[25px]" : "gap-[35px]")} ${(isSmallMobile || isLargeMobile) && "flex-wrap"}`}>
                <MDBox>
                  {isLoader
                    ?
                    <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                    :
                    <>
                      <MDBox sx={{ marginTop: "24px" }}>
                        <Card style={{ padding: "0px 20px 0px 20px", maxHeight: "170px", width: isSmallMobile ? "292px" : isLargeMobile ? "340px" :isZoomLoptopScreen ? "280px" : isLargeLoptop ? "365px" : isMdLoptop ? "227px" :isNestHub ? "220px": isloptop ? "260px" : "380px" }}>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "58px", width: "50px", borderRadius: "9px", background: getColorVlue(themeColor), marginTop: "-20px", paddingBottom: "30px" }}><div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}><img src={usedStorageIcon} alt={'storage'} style={{ width: "21px", height: "21px" }} /></div> </div>
                          <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px", marginTop: isMdLoptop ? "35px": isNestHub ? "13px": "" , paddingLeft: isMdLoptop ? "10px" : isloptop|| isZoomLoptopScreen ? "0px" : "40px" }}>
                            <HalfDonutChart isStorage={true} percentage={(storagePercentage)?.toFixed(2)}
                              allowedStorage={storageMbValue.allowedStorage} totalOccupiedSize={storageMbValue.totalOccupiedSize} />
                          </MDBox>
                        </Card>
                      </MDBox>
                    </>

                  }
                </MDBox>
                <MDBox>
                  {isLoader
                    ?
                    <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                    :
                    <>
                      <MDBox sx={{ marginTop: "24px" }}>
                        <Card style={{ padding: "0px 20px 0px 20px", maxHeight: "170px", width: isSmallMobile ? "292px" : isLargeMobile ? "340px" :isZoomLoptopScreen ? "280px" : isLargeLoptop ? "365px" : isMdLoptop ? "227px" :isNestHub ? "220px" : isloptop ? "260px" : "380px" }}>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "58px", width: "50px", borderRadius: "9px", background: getColorVlue(themeColor), marginTop: "-20px", paddingBottom: "30px" }}><div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}><img src={StorageIcon} alt={'storage'} style={{ width: "21px", height: "21px" }} /></div> </div>
                          <MDBox sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px", marginTop: isMdLoptop ? "35px": isNestHub ? "13px": "", paddingLeft: isMdLoptop ? "10px" : isloptop || isZoomLoptopScreen ? "0px": "40px" }}>
                            <HalfDonutChart percentage={(tokensPercentage)?.toFixed(2)}
                              allowedStorage={tokens.allowedStorage} totalOccupiedSize={tokens.totalOccupiedSize} />
                          </MDBox>
                        </Card>
                      </MDBox>
                    </>
                  }

                </MDBox>
              </div>
              <div>
                {isLoader
                  ?
                  <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                  :
                  <Card sx={{ height: (isSmallMobile || isLargeMobile) ? "200px" : isloptop ? "175px" : "170px", marginTop: "17px", overflow: "hidden", width: isMdLoptop ? "487px" : "w-full" }}>
                    <div className={`flex items-center pt-[10px] justify-evenly  ${(isSmallMobile || isLargeMobile) && "flex-noWrap "} ${isSmallMobile ? "ml-[0px]" : isLargeMobile ? "ml-[0px]" :isNestHub ? "ml-[7px]":isZoomLoptopScreen ? "ml-[15px]" : ""}`}>
                      {isSmallMobile || isLargeMobile ? (
                        <>
                          <IconButton
                            onClick={handlePrevious}
                            aria-label="previous"
                            sx={{
                              cursor: "pointer",
                              visibility: currentChartIndex === 0 ? "hidden" : "visible", // hide at first index
                            }}
                          >
                            <ArrowBackIosIcon style={{color:darkMode? "white":""}}/>
                          </IconButton>
                          <DashboardDynamicPieChart
                            value={pieChartsData[currentChartIndex].value}
                            total={pieChartsData[currentChartIndex].total}
                            title={pieChartsData[currentChartIndex].title}
                          />
                          <IconButton
                            onClick={handleNext}
                            aria-label="next"
                            sx={{
                              cursor: "pointer",
                              visibility:
                                currentChartIndex === pieChartsData.length - 1 ? "hidden" : "visible", // hide at last index
                            }}
                          >
                            <ArrowForwardIosIcon  style={{color:darkMode? "white":""}}/>
                          </IconButton>
                        </>
                      ) : (
                        // Show all pie charts on larger devices
                        pieChartsData.map((data, index) => (
                          <DashboardDynamicPieChart
                            key={index}
                            value={data.value}
                            total={data.total}
                            title={data.title}
                          />
                        ))
                      )}
                    </div>
                  </Card>
                }
              </div>

              <div>
                {isLoader
                  ?
                  <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                  :

                  <Card sx={{ height: isMdLoptop && "200px", marginTop: isloptop ? "28px" : "30px" }}>
                    <VerticalBarChart
                      icon={BarChartIcon}
                      title="Agents by : "
                      storageTitle="Storage"
                      noOfrequestTitile="No of request per day"
                      tokenConsumedTitle="Tokens consumed"
                      height={isMdLoptop || isZoomLoptopScreen ? "257px" :isNestHub ? "270px": "300px"}
                      handleSelection={handleSelection}
                      selectedData={selectedData}
                      handleTokenConsumeData={handleTokenConsumeData}
                      selectedTokenConsumedData={selectedTokenConsumedData}
                      chart={chartData[selectedData]}
                    />
                  </Card>
                }
              </div>

            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox display="flex" flexDirection="column" sx={{ height: "750px", gap: "5px", marginTop: "3px" }}>

                <div style={{ padding: "20px 10px 0px 10px", }}>
                  {
                    isLoader
                      ?
                      <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                      :
                      <>
                        <MDBox>
                            <PieChart
                              pieChart={1}
                            />
                        </MDBox>
                      </>
                  }
                </div>
                <div style={{ padding: "24px 10px 0px 10px" }}>
                  {
                    isLoader
                      ?
                      <List height={60} backgroundColor="grey" foregroundColor="darkgrey" />
                      :
                      <>
                        <MDBox>
                            <PieChart
                             pieChart={2}
                            />

                        </MDBox>
                      </>
                  }
                </div>

              </MDBox>
            </Grid>
          </Grid>

        </MDBox>
        <Footer />
      </DashboardLayout>
    </div >
  );
}

export default Dashboard;
