/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useMemo } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UsageHistoryTable from "components/MDTable/MDTableUsageHistory";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DateRangePicker from "examples/DatePicker/DatePicker";
import Footer from "examples/Footer";
import { Images } from "libraries/Images";
import {useMaterialUIController} from "context";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DropdownWithSearch from "components/MDDropdownSearch";
import Constants from "libraries/Constants";
import ApiRequest from "services/ApiRequest";


function UsageHistory() {
  // const [windowSize, getWindowSize] = useScreenWidth();
  const [controller] = useMaterialUIController();
  const {  themeColor, darkMode } = controller;
  const navigate = useNavigate();
  const stateUser = useSelector((state) => state.loginReducer);

  const [isPageChange, setPageChange] = useState(false);
  const [paymentsList, setPaymentsList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [patternsList, setPatternsList] = useState([]);
  const [ isLoader, setIsLoader ] = useState(true);
  let arr = [];

  const widthhandle = ["35", "35", "20", "10"];
  // ["Feature Extraction", "Image-to-Text","LLM" ];

  const [dropdownValues, setDropdownValues] = useState({ 
    agent: "", pattern: "", startDate: "", endDate: ""
  });   

  useEffect(() => {
    allPatternsList();
    allAgentsList();
  }, []);

  useEffect(() => {
    allPaymentsList();
  }, [dropdownValues])

  const allPatternsList = () => {
    ApiRequest.patternDropdownList("", "", (res) => {
      if(res?.isSuccess && res?.data?.patternList?.length){
        setPatternsList(res?.data?.patternList?.map(ps => ps?.patternName))
      }
    })
  };

  const allAgentsList = () => {
    ApiRequest.agentDropdownList(`/${stateUser?.user?.userId}`, "", (res) => {
      if(res?.isSuccess && res?.data?.agentList?.length){
        setAgentsList(res?.data?.agentList)
      }
    })
  };

  const allPaymentsList = () => {
    setIsLoader(true);

    const data = {
      agentName: dropdownValues?.agent,
      patternName: dropdownValues?.pattern,
      startDate: dropdownValues?.startDate,
      endDate: dropdownValues?.endDate
    };

    // Filter out properties with empty string values
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== "")
    )

    ApiRequest.paymentUsageHistory(filteredData, `/${stateUser?.user?.userId}`, (res) => {
      if(res?.isSuccess){
        setPaymentsList(res?.data?.tokenUsageDetail);
      }else{
        setPaymentsList([]);
      }
      setPageChange(!isPageChange); setIsLoader(false);
    });
  };


  useEffect(()=> {
   arr = paymentsList?.length ? paymentsList?.map(pl => pl?.patternName) : [];
  },[paymentsList])

  return (
    <div style={{
      backgroundImage: `url(${darkMode ? Images.mainBgDark : Images.mainBgWhite})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundOrigin: "content-box",
      backgroundPosition: "100% 100%",
      overflowY: "auto",
      height: "100vh"
    }}>
      <DashboardLayout>
      <DashboardNavbar />

      <MDTypography sx={{ fontSize: "20px", fontWeight: "600",paddingLeft:"5px" }}>
                Usage History
      </MDTypography>

      <MDBox pt={1}>
      <div  style={{ marginTop: "15px" }} className="flex w-full justify-end flex-wrap">
        <div style={{ fontSize: "15px", marginTop: "10px", marginRight: "10px",color: darkMode && "white" }}>Filter by:</div>
       
        <div style={{ marginBottom: "5px" }}>
        <DropdownWithSearch 
            dropdownName={"Pattern"} 
            nameArr={patternsList?.length ? Constants.uniqueElements(patternsList) : []}
            isBgWhite={!darkMode}
            isLarge={true}
            isSmall={false}
            isNoLabel={true}
            // isMultiple={true}
            backSelect={(val)=> {
              setDropdownValues({ ...dropdownValues, pattern: val })
            } }
            personName={dropdownValues?.pattern?.length ? [dropdownValues?.pattern]?.filter(el => el) : ["Pattern"]} /> 
        </div>


        <div style={{ paddingLeft: "10px", marginBottom: "5px" }}>
        <DropdownWithSearch 
            dropdownName={"Agent"} 
            nameArr={agentsList?.length ? Constants.uniqueElements(agentsList) : []}
            isBgWhite={!darkMode}
            isLarge={true}
            isSmall={false}
            isNoLabel={true}
            // isMultiple={true}
            backSelect={(val)=> {
              setDropdownValues({ ...dropdownValues, agent: val })
            } }
            personName={dropdownValues?.agent?.length ? [dropdownValues?.agent]?.filter(el => el) : ["Agent"]} /> 
        </div>

        <div style={{ paddingLeft: "10px" }}>
          <DateRangePicker backDates={(dt) => {
            console.log("qq Dates Start", dt?.startDate)
            setDropdownValues({...dropdownValues, 
              startDate: Constants.formatDashDates(dt?.startDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.startDate, false),
              endDate: Constants.formatDashDates(dt?.endDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.endDate, false)
              // startDate: Constants.formatDashDates(dt?.startDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.startDate, false),
              // endDate: Constants.formatDashDates(dt?.endDate, false) === "1970-01-01" ? "" : Constants.formatDashDates(dt?.endDate, false),
            })
          }} />
        </div>
    
      </div>
      

      <MDBox pt={2} pb={3} style={{ overflowY: "auto", height: "calc(100vh - 235px)" }}>
        <Grid container spacing={6} >
          <Grid item xs={12}>
            <Card>        
              <MDBox>
                <UsageHistoryTable 
                        isLoader={isLoader} paymentsList={paymentsList} backPage={isPageChange}
                        dropdownValues={dropdownValues} widthhandle={widthhandle} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
      </MDBox>
    </DashboardLayout>
   
    </div>
    
  );
}

export default UsageHistory;
