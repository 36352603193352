import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController, setChatbotNavClose } from "context";
import { useResponsive,breakpoints } from 'services/Utils';



ChartJS.register(ArcElement, Tooltip, Legend);

const HalfDonutChart =  React.memo(({ isStorage, percentage, totalOccupiedSize, allowedStorage }) => {
  const mediaQueries = useResponsive(breakpoints);
  const { isNestHub, isMdLoptop, isloptop, isLargeMobile, isSmallMobile, isZoomLoptopScreen } = mediaQueries;
  const [controller] = useMaterialUIController();
  
  const { darkMode } = controller;
    // Determine color based on percentage
    const getColor = (percentage) => {
      let filledColor;
      if (percentage <= 39) {
        filledColor = '#495361';  // Below 40%
      } else if (percentage < 70) {
        filledColor = '#1A73E8';  // 40% to below 70%
      } else {
        filledColor = '#D90000';  // 70% and above
      }
      return filledColor;
    };

    const data = {
        // labels: [isStorage ? 'Storage Usage' : "Tokens Usage"],
        datasets: [
            {
                data: [percentage, 100 - percentage],
                backgroundColor: [getColor(percentage), '#dddddd'], // Dynamic color for used portion
                hoverBackgroundColor: [getColor(percentage), '#dddddd'],
            },
        ],
    };

    const options = {
        rotation: -90,
        circumference: 180,
        cutout: '50%',
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                  // title: function () {
                  //   return isStorage ? 'Storage Used' : "Tokens Used";
                  // },
                  label: function () {
                    return [
                      `${isStorage ? "Used: " : "Used: "}${totalOccupiedSize} ${isStorage ? "MB" : ""} `,
                      `${isStorage ? "Remaining: " : "Remaining: "} ${allowedStorage} ${isStorage ? "MB" : ""}`
                    ];
                  },
                  labelColor: function () {
                    return {
                      borderColor: getColor(),
                      backgroundColor: getColor(),
                    };
                  },
                },
                displayColors: true, 
                yAlign: 'bottom',
              },
        },
    };

    return (
        <div style={{ position: 'relative',marginTop:isMdLoptop && "-40px", height: isMdLoptop ? "190px" :isloptop ? "234px" : "235px", width: isMdLoptop ? "200px" :isNestHub ? "220px" :isloptop ? "234px" : "280px" }}>
            <Doughnut data={data} options={options} redraw/>
            <div style={{ marginTop:isMdLoptop ? "-92px" :isNestHub ? "-97px": isloptop ? "-108px" :"-115px", textAlign: 'center',paddingRight:(isSmallMobile || isMdLoptop ||isloptop) ? "0px" :isZoomLoptopScreen ? "0px" :isLargeMobile ? "20px" :"30px" }}>
                <MDTypography style={{ fontSize:isloptop ? "15px" :isMdLoptop? "18px": "24px", fontWeight: "800", color:darkMode ? "white": getColor(percentage)  }}>{percentage}%</MDTypography>
                <MDTypography style={{ fontSize: "10px", fontWeight: "500", color:darkMode ? "white" :"#3B4E6D"}}>
                  {isStorage ? "Storage used" : "Tokens used"}
                </MDTypography>
            </div>
        </div>
    );
});

export default HalfDonutChart;
