/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================
* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
 =========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Dashboard components
import { useEffect, useState, React } from "react";
import 'react-loading-skeleton/dist/skeleton.css';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import MDDropdown from "components/MDDropdown";
import { toast } from "react-toastify";
import ApiRequest from "services/ApiRequest";
import useScreenWidth from "libraries/ScreenSizeHook";
import { Tooltip } from "examples/Tooltip/Tooltip";
import { TabsCustomized } from "examples/TabsCustomized/TabsCustomized";
import { getColorVlue } from "services/Utils";
import { SpinnerLoader } from "examples/Loader/Loader";
import { TextFieldComponent } from "examples/TextField/TextField";
import { InputFieldComponent } from "examples/InputField/InputField";
// Collapsable Menu
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useMediaQuery from "@mui/material/useMediaQuery";
import { Images } from "libraries/Images";


function ChatbotConfig() {
  const navigate = useNavigate()
  const location = useLocation()
  const { id, name } = useParams()
  const { configForm } = location?.state || {};
  const { modelName, agentName, openAIKey, description, prePrompt, temperature,
    topP, frequencyPenalty, presencePenalty, maxTokens, defaultPrompt
  } = configForm || {};
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const [modelNames, setModelNames] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [backActiveTab, setBackActiveTab] = useState(0);
  const [config, setConfig] = useState(
    {
      modelName: modelName ?? "",
      agentName: agentName ?? "", 
      temperature: temperature ?? 0, topP: topP ?? 1, frequencyPenalty: frequencyPenalty ?? 0,
      presencePenalty: presencePenalty ?? 0, maxTokens: maxTokens ?? 256, prePrompt: prePrompt ?? 'Reply only based on the context provided.',
      defaultPrompt: defaultPrompt ?? 'Your name is Andy and you are a virtual assistant representing Nestle HR. You will reply to users questions with information provided in the context. Please reply in as much detail as possible.  Do not provide information which is not relevant to the prompt.',
      description: description ?? ''
    });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isRedError, setIsRedError] = useState(false);
  const [windowSize, getWindowSize] = useScreenWidth();
  const [expanded, setExpanded] = useState(['panel1']);

  const isMdLoptop = useMediaQuery('(min-width: 1000px) and (max-width: 1300px)');
  const isTablet = useMediaQuery('(min-width:700px) and (max-width:900px)');
  const isLargeMobile = useMediaQuery('(min-width:400px) and (max-width:600px)');
  const isSmallMobile = useMediaQuery('(min-width:318px) and (max-width:390px)');
  const isSmalMobileStyling = useMediaQuery('(min-width:318px) and (max-width:370px)');
  const ismdMobileStyling = useMediaQuery('(min-width:371px) and (max-width:399px)');

  const handleChangeCollapse = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel] : expanded?.filter(ef => ef !== panel));
  };

  useEffect(() => {
    allModelsList();
  }, []);

  const allModelsList = () => {
    ApiRequest.listModels('', id, (res) => {
      if (res?.isSuccess) {
        const name = res?.data?.patternModels?.map(pl => { return pl?.permission === 0 ? pl?.modelName : `${pl?.modelName} (${pl?.planName} Plan)` });
        const completeList = res?.data?.patternModels?.map(pl => {
          return {
            title: pl?.permission === 0 ? pl?.displayName : `${pl?.displayName} (${pl?.planName} Plan)`,
            isLock: pl?.permission === 1,
            apiName: pl?.permission === 0 ? pl?.modelName : `${pl?.modelName} (${pl?.planName} Plan)`,
            default: pl?.default
          }
        }
        );
        setModelNames(name);
        setModelList(completeList);
        setConfig({ ...config, modelName: location?.state?.configForm?.modelName ?? completeList?.filter(pl => pl?.default)?.[0]?.apiName });
      } else {
        setModelNames([]); setModelList([]);
      }
    });
  };

  const fields = [
    { id: 1, type: 'text', placeholder: 'Name', name: 'agentName', mendatory: true, information: "Name of the X-Agent you want to create. Note that the X-Agent name must be unique." },
    { id: 2, type: 'textarea', placeholder: 'Description', name: 'description', mendatory: true, information: "Describes the agent's purpose or functionality." },
    { id: 3, type: 'textarea', placeholder: 'Pre-Prompt', name: 'prePrompt', mendatory: false, information: "Input that guides the model's behavior, providing context or setting the tone for generating responses." },
    { id: 4, type: 'textarea', placeholder: 'System Prompt', name: 'defaultPrompt', mendatory: true, information: "Input that guides the model's behavior, providing context or setting the tone for generating responses." },
  ];

  const fieldsAdvanced = [
    { id: 0, type: 'dropdown', placeholder: 'LLM', name: 'modelName', arr: modelNames?.length ? modelNames : [''], mendatory: true, information: "Multiple options available for LLM depends upon user selection" },
    // { id: 2, type: 'text', placeholder: 'OpenAI', name: 'openAIKey', mendatory: true, information: "OpenAI Key required for API usage. You can find your API key at the OpenAI website under API keys section" },
    { id: 4, type: 'number', placeholder: 'Temperature', name: 'temperature', mendatory: false, min: 0, max: 1, step: 0.05, validation: "Minimum and maximum limits are 0 and 1", information: "Controls randomness of model's output; lowering results in less random completions. As the temperature approaches zero, the model will become deterministic and repetitive." },
    { id: 5, type: 'number', placeholder: 'TopP', name: 'topP', mendatory: false, min: 0, max: 1, step: 0.05, validation: "Minimum and maximum limits are 0 and 1", information: "Controls diversity via nucleus sampling: 0.5 means means half of all likelihood-weighted options are considered." },
    { id: 6, type: 'number', placeholder: 'Frequency Penalty', name: 'frequencyPenalty', mendatory: false, min: 0, max: 1, step: 0.05, validation: "Minimum and maximum limits are 0 and 1", information: "How much to penalize new tokens based on their existing frequency in the text so far. Decreases the model's likelihood to repeat same line verbatim." },
    { id: 7, type: 'number', placeholder: ' Presence Penalty', name: 'presencePenalty', mendatory: false, min: 0, max: 1, step: 0.05, validation: "Minimum and maximum limits are 0 and 1", information: "How much to penalize new tokens based on whether they appear in the text so far. Increases the model's likelihood to talk about new topics." },
    { id: 8, type: 'number', placeholder: ' Maximum Length', name: 'maxTokens', mendatory: true, min: 256, max: 4096, step: 1, validation: "Minimum and maximum limits are 256 and 4096", information: "The maximum number of tokens to generate shared between the prompt and the completion. The exact limit varies by model. (One token is rougly 4 characters for standard English text)" },
  ];

  const isValidate = () => {
    let validate = true;
    fields.map((field, _) => {
      if ((field?.type === "text" || field?.type === "textarea") && field?.mendatory && config[`${field?.name}`]?.replace(/\s+/g, '')?.length === 0) {
        validate = false
      }
    })
    return validate
  };

  const navigation = (route, page, step, objectId, configForm, isTableBox) => {
    navigate(`/patterns/${id}/${name}/agent-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        id: id, objectId: objectId,
        page: page, step: step,
        configForm: configForm,
        deployedURL: location?.state?.deployedURL,
        status: location?.state?.status,
        isDeploying: false,
        uploadedFile: location?.state?.uploadedFile,
        protect: location?.state?.protect,
        isEditAgent: location?.state?.isEditAgent,
        isTableBox: isTableBox,
        isTrained: page === 4 && route === '/train' ? true : false,
      }
    });
  };
  useEffect(() => (
    navigation('/config', 1, 0, location?.state?.objectId, location?.state?.configForm,false)
  ), []);

  useEffect(() => (
    navigation('/config', 1, 0, location?.state?.objectId,
      { ...config, patternId: id },false)
  ), [config]);

  const saveConfig = (flg) => {
    if (!isValidate()) {
      toast.error("Please fill all marked fields!");
      setIsRedError(true); setExpanded(['panel1', 'panel2']);
    }
    else if (!modelName?.length) {
      toast.error("Please select model type"); setExpanded(['panel2']); setIsRedError(true);
    }
    else if (isValidate()) {
      if (flg) { setIsLoading(true) }
      else { setIsLoading2(true) }
      ApiRequest.configChatbotAgent({ ...config, patternId: id }, location?.state?.objectId, (res) => {
        if (flg) { setIsLoading(false); setIsRedError(false); }
        if (res.isSuccess) {
          if (flg) {
            navigation('/config', 1, 0, res?.data?.objectId, location?.state?.configForm, false);
          } else {
            setIsLoading(false); setIsLoading2(false); setIsRedError(false)
            navigation('/tools', 2, 1, res?.data?.objectId, location?.state?.configForm, true);
            //  Deployment Chatbot
            // if (location?.state?.deployedURL) {
            //   setIsLoading(false);setIsLoading2(false);
            //   navigation('/deployment', 2, 1, res?.data?.objectId, location?.state?.configForm);
            // } else {
            //   toast.success("Chatbot agent deployment starting soon");
            //   ApiRequest.deployChatbotAgent('', res?.data?.objectId, (res2) => {
            //     setIsLoading2(false);
            //     if (res2.isSuccess) {
            //       navigation('/deployment', 2, 1, res?.data?.objectId, location?.state?.configForm);
            //     } else {
            //       toast.error(res2?.error?.message ? res2?.error?.message : "Deployment Failed");
            //       navigation('/config', 1, 0, res?.data?.objectId, location?.state?.configForm);
            //     }
            //   });
            // }
          }
          toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
        } else {
          setIsLoading(false); setIsLoading2(false); setIsRedError(false);
          toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
        }
      });
    }
    else {
      toast.error("Please fill all marked fields!");
      setIsLoading(true);
    }
  };

  const Icons = (icon) => {
    return (
      <div style={{ width: "30px", marginRight: "15px" }}>
        <MDBox bgColor={themeColor}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
          <MDBox component="img" textAlign="center"
            src={icon}
            alt="Brand" width="70%"
            style={{ height: "14px", width: "14px" }}
          />
        </MDBox>
      </div>
    )
  };

  console.log("location?.state iii", location?.state)

  return (
    <>
      <div className="w-[100%] flex flex-row flex-wrap"
        style={{padding: windowSize.innerWidth <= 600 ? "0px" : "0px 30px", gap: "20px", justifyContent: "space-between" }}>
        {/* padding: "30px" */}

        <TabsCustomized  activeTab={backActiveTab} backActiveTab={(e)=> setBackActiveTab(e)} arr={["AGENT", "MODEL"]} />

        <div style={{ width: "100%", marginTop: "12px",  height: "calc(100vh - 440px)", overflowY: "auto" }}>
          {/*======   SECTION 1 ==============  */}
          {/* <Accordion expanded={expanded?.includes('panel1')}
            style={{ backgroundColor: "transparent" }}
            onChange={handleChangeCollapse('panel1')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: darkMode ? "white" : "black" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div style={{ width: '33%', fontSize: "16px", fontWeight: "500", flexShrink: 0, color: darkMode ? "white" : "black" }}>
                Agent
              </div>
            </AccordionSummary>
            <AccordionDetails> */}
            {backActiveTab === 0 ?
              <div className="w-[100%] flex flex-row flex-wrap"
                style={{ gap: "20px", justifyContent: "space-between", padding: "5px" }}>
                {fields.map((fi, ind) => (
                  <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" 
                                    : ind === (fields.length - 1) ? "100%"
                                    : fi?.type !== "textarea" ? "100%" : "48%",
                             outline: "none", display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        {fi?.type === "textarea" ?
                        <TextFieldComponent
                          fi={fi}
                          config={config}
                          isHeight={true}
                          isMediumBox={(fi.name === "description" || fi.name === "prePrompt")}
                          isBigBox={fi.name === "defaultPrompt"}
                          setConfig={setConfig}
                        />   
                        : 
                        <InputFieldComponent
                          fi={fi}
                          config={config}
                          setConfig={setConfig}
                        /> 
                        }
                      </div>
                  </MDBox>
                ))}
              </div>
          
              :
              <div className="w-[100%] flex flex-row flex-wrap"
                style={{ gap: "20px", justifyContent: "space-between", padding: "5px" }}>
                {fieldsAdvanced.map(fi => (
                  <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "48%", outline: "none", display: "flex", alignItems: "center" }}>
                    {
                      fi.type === "dropdown"
                        ?
                        <MDDropdown
                          dropdownName={"LLM"}
                          nameArr={fi?.arr}
                          backendArr={modelList?.length ? modelList : []}
                          fi={fi}
                          isMultiple={false}
                          isLarge
                          disabledNames={modelList?.filter(str => str.apiName?.includes("Plan"))?.length
                            ? modelList?.filter(str => str.apiName?.includes("Plan"))?.map(ps => ps?.apiName)
                            : []}
                          backSelect={(value) => {
                            console.log("value?.includes(Plan)", modelList?.filter(str => str.apiName?.includes("Plan"))?.map(ps => ps?.apiName))
                            if (value?.includes("Plan")) {
                              navigate("/pricing-plans")
                            } else {
                              setConfig({ ...config, modelName: value });
                            }
                            // modelList?.filter(pl => pl?.title === value)?.[0]?.apiName
                          }}
                          personName={[config?.modelName]?.filter(el => el)} />
                        :
                        <InputFieldComponent
                          fi={fi}
                          config={config}
                          setConfig={setConfig}
                        /> 
                       
                      
                    }

                  </MDBox>
                ))}
              </div>
              }


    

            {/* </AccordionDetails>
          </Accordion> */}

          {/*======   SECTION 2 ==============  */}
          {/* <Accordion expanded={expanded?.includes('panel2')}
            style={{ backgroundColor: "transparent" }}
            onChange={handleChangeCollapse('panel2')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: darkMode ? "white" : "black" }} />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <div style={{ width: '33%', fontSize: "16px", fontWeight: "500", flexShrink: 0, color: darkMode ? "white" : "black" }}>
                Model
              </div>
            </AccordionSummary>
            <AccordionDetails> */}

            {/* </AccordionDetails>
          </Accordion> */}
        </div>

      </div>
      <MDBox mt={4} mb={2} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
        <MDButton style={{ width: "max-content" }} disabled={isLoading || isLoading2}
        //  backActiveTab === 1 ? setBackActiveTab(0) :
          onClick={() => { saveConfig(true) }} variant="outlined" color={darkMode ? "white" : themeColor} fullWidth>
          {isLoading ? <SpinnerLoader adjustment={true} enhance={` ${darkMode ? "text-white" : "text-black"} text-xs`} /> 
          // : backActiveTab === 1 ? 'Back'
          : 'Save'}
        </MDButton>
        <MDButton style={{ width: "max-content" }} disabled={isLoading || isLoading2}
        // backActiveTab === 0 ? setBackActiveTab(1) : 
          onClick={() => {  saveConfig(false) }} variant="gradient" color={themeColor} fullWidth>
          {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> 
          : 'Save & Next'}
          {/* backActiveTab === 0 ? 'Next' :  */}
        </MDButton>
      </MDBox>
    </>
  );
}
export default ChatbotConfig;