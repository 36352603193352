/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {  createGlobalStyle } from 'styled-components';


// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton"; // Add this import
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav,
} from "context";
import { Images } from "libraries/Images";
import { intervalShouldStart, setIntervalStartTime, isUserLoginStatus, isTimeInterval } from "redux/Action";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, themeColor } = controller;
  const location = useLocation();
  const navigate = useNavigate();
  const collapseName = location.pathname.replace("/", "");
  const [openCollapses, setOpenCollapses] = useState({});
  const dispatcher = useDispatch()

  let textColor = "white";

  // if (transparentSidenav || (whiteSidenav && !darkMode)) {
  //   textColor = "dark";
  // } else if (whiteSidenav && darkMode) {
  //   textColor = "inherit";
  // }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const handleCollapseToggle = (key) => {
    setOpenCollapses((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  // Function to check if a collapse is open
  const isCollapseOpen = (key) => {
    return openCollapses[key];
  };
  const handleNavigate = async () => {
    await dispatcher(isTimeInterval(false))
    await dispatcher(intervalShouldStart(false))
    await dispatcher(setIntervalStartTime(null))
    await dispatcher(isUserLoginStatus(false))

    navigate("/auth/login")
  }

  const getBackgroundColor = () => {
    switch (themeColor) {
      case "info":
        return "#314B87";
      case "success":
        return "#57A846";
      default:
        return themeColor;
    }
  };

  // Create Global Styles
  const SideNavWrapperStyles = createGlobalStyle`
    .css-1h074jt-MuiDrawer-docked .MuiDrawer-paper, .css-jgup92-MuiDrawer-docked .MuiDrawer-paper, 
     .css-b4zdbd .MuiDrawer-paper, .css-1cmd3rv .MuiDrawer-paper{
      background: ${darkMode ? "#1a2035" : getBackgroundColor()} !important;
    }

    .css-1am18op .MuiDrawer-paper, .css-xojzyr-MuiDrawer-docked .MuiDrawer-paper,
    .css-9c0plp-MuiDrawer-docked .MuiDrawer-paper
    {
          background: ${darkMode ? "#1a2035" : "#686D76"} !important;
    }

    .css-b4zdbd .MuiDrawer-paper, .css-1h074jt-MuiDrawer-docked .MuiDrawer-paper,
     .css-jgup92-MuiDrawer-docked .MuiDrawer-paper, .css-1cmd3rv .MuiDrawer-paper,
      .css-9c0plp-MuiDrawer-docked .MuiDrawer-paper, .css-vowgci .MuiDrawer-paper {
      margin: 0px;
      height: 100vh;
      border-radius: 0px;
    }

    .css-12eqrk8{
      margin-right: 0px;
      border-radius: 15px 0px 0px 15px;
    }
    `

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, collapse }) => {
    let returnValue;

    if (name === "Business Planning" && type === "collapse") {
      returnValue = (
        <div key={key} >
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
            onClick={() => {
              handleCollapseToggle(key)
            }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={openCollapses?.workToggle}
              noCollapse={noCollapse}
              toggle={openCollapses?.workToggle}
              checked={name === "Business Planning" && type === "collapse"}

            />

          </Link>
          <Collapse in={isCollapseOpen(key)} timeout="auto" unmountOnExit>
            <List component="div">
              {collapse.map(({ name, icon, route, key }) => (
                <NavLink key={key} to={route}
                >
                  <SidenavCollapse name={name} icon={icon} active={collapseName?.includes(`${key}`)} style={{ padding: "0px 10px !important", margin: "0px 16px !important" }} />
                </NavLink>
              ))}
            </List>
          </Collapse>
        </div>
      );
    } else if (type === "collapse") {
      // Render as a collapsing item
      returnValue = href ? (
        <Link
          href={href}
          key={key}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={name}
            icon={icon}
            active={collapseName?.includes(`${key}`)}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={key} to={route} >
          <SidenavCollapse name={name} icon={icon} active={collapseName?.includes(`${key}`)} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={true
            // (!darkMode && !whiteSidenav && !transparentSidenav) ||
            // (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold", color: "#FFFFFF" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" textAlign="center" 
          src={
            // (whiteSidenav || transparentSidenav) ? Images.logoIntellixcoreBlue : 
            Images.logoIntellixcoreWhite} 
          alt="Brand" width="80%" />}
        </MDBox>
      </MDBox>

      <List sx={{ marginTop: "30px" }}>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        <MDButton
          component="a"
          onClick={handleNavigate}
          rel="noreferrer"
          variant="outlined"
          color={"white"}
          fullWidth
        >
          Logout
        </MDButton>
      </MDBox>

      <SideNavWrapperStyles />
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
