import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import MDDropdown from "components/MDDropdown";
import { Tooltip } from 'examples/Tooltip/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
// import { Paths } from "libraries/Route";

import { useMaterialUIController } from "context";
import useScreenWidth from "libraries/ScreenSizeHook";
import Modal from "examples/Modal/Modal";
import { SpinnerLoader } from "examples/Loader/Loader";
import { toast } from "react-toastify";
import { Images } from "libraries/Images";
import { getColorVlue } from 'services/Utils';
import MDInput from "components/MDInput";
import ApiRequest from "services/ApiRequest";
import { CiCirclePlus } from "react-icons/ci";
import Constants from "libraries/Constants";
import { Tag } from "examples/Tag/Tag";
import { InputFieldComponent } from "examples/InputField/InputField";


function WidgetConfig(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [xAgentName, setXAgentName] = useState([]);
  const [xAgentList, setXAgentList] = useState([]);
  const [detectMsg, setDetectMsg] = useState('');
  const [modalDelOpen, setModalDelOpen] = useState({ flag: false, name: "" });


  const [windowSize, getWindowSize] = useScreenWidth();
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, themeColor } = controller;
  const { widgetConfigForm } = location?.state || {};
  const {  widgetName, agentId, active, messages, time, displayMsg, domains } = widgetConfigForm || {};

  const [widgetConfig, setWidgetConfig] = useState(
    { widgetName: widgetName ?? "",  agentId: agentId ?? '', 
      active: active ?? true, messages: messages ?? 50, time: time ?? 10, 
      displayMsg: displayMsg ?? '', domains: domains ?? [] 
    });

  const navigation = (route, page, step, objectId, widgetConfigForm) => {
    navigate(`/widgets/widget-creation${route}`, {
      state: {
        previousPath: location.state?.previousPath ?? '',
        objectId: objectId,
        page: page, step: step,
        isEditAgent: location.state?.isEditAgent,
        widgetConfigForm: widgetConfigForm,
        widgetDesignForm: location.state?.widgetDesignForm,
      }
    });
  };

  useEffect(() => {
    navigation('/config', 1, 0, location?.state?.objectId, location?.state?.widgetConfigForm);

    listReadyAgents();
  }, []);

  useEffect(() => {
    navigation('/config', 1, 0, location?.state?.objectId, widgetConfig);
  }, [widgetConfig]);

  console.log("location", location)

  const listReadyAgents = () => {
    ApiRequest.listReadyAgents('', '655f34ac4158543391a3e97c', (res)=>{
      if(res?.isSuccess){
        const name = res?.data?.chatbotAgents?.map(pl => { return pl?.agentId});
        setXAgentName(name);
        setXAgentList(res?.data?.chatbotAgents?.map(pl => {return {title: pl?.agentName, apiName: pl?.agentId  }}))
      }else{
        setXAgentName([]);  setXAgentList([]);
      }
    });
  };

  const nameActiveArr = [true, false];

  const fieldsConfigData = [
    { id: 1, type: 'text', placeholder: 'Widget Name', name: 'widgetName', mendatory: true, information: "Name of the Widget you want to create. Note that the Widget name must be unique." },
    { id: 2, type: 'dropdown', placeholder: 'Select X-Agent*', name: 'agentId', arr: xAgentName?.length ? xAgentName : [''], mendatory: true, information: "One Agent can have multiple chat widget. Please Choose X-agent." },
    { id: 3, type: 'dropdown', placeholder: 'Active', name: 'active', arr: nameActiveArr, mendatory: true, information: "It is upto user to make the chat widget active or inactive." },
  ];

  const fieldsRequestLimitData = [
    { id: 1, type: 'number', placeholder: 'No. of messages', name: 'messages', min: 0, max: 1,step: 1, mendatory: true, information: "It is total number of messages received by the user" },
    { id: 2, type: 'number', placeholder: 'Every minutes', name: 'time', min: 0, max: 1,step: 1, mendatory: true, information: "Count of time in minutes for total messages" },
    { id: 3, type: 'text', placeholder: 'Add display messages', name: 'displayMsg', mendatory: false, information: "A welcome message for user" },
  ];


  const divider = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div style={{ background: darkMode ? "white" : "#CCCCCC", height: "2px", width: "100%", borderRadius: "50%", margin: "30px 0px 0px 0px" }}></div>
      </div>
    )
  };

  const saveConfig = (flg) => {
       delete widgetConfig?.styling;

      if(!widgetConfig?.widgetName?.length){
        toast.error("Please enter widget name and it must be unique");
      }
      else if(widgetConfig?.messages == 0){
        toast.error("Please enter any value greater than 0 in No. of messages field");
      }else if(widgetConfig?.time == 0){
        toast.error("Please enter any value greater than 0 in Every minutes field");
      }else if(!widgetConfig?.agentId?.length){
        toast.error("Please select X-Agent");
      }
      else{
        if(flg){setIsLoading(true)}
        else{setIsLoading2(true)}

        ApiRequest.createWidgetConfig(widgetConfig, location?.state?.objectId, (res) => {
            if(flg) {setIsLoading(false)}
            if (res.isSuccess) {
              if (flg) {
                navigation('/config', 1, 0, location?.state?.objectId ?? res?.data?.widget?._id, location?.state?.widgetConfigForm);
              } else {
                setIsLoading(false);setIsLoading2(false); 
                navigation('/design', 2, 1, location?.state?.objectId ?? res?.data?.widget?._id, location?.state?.widgetConfigForm);

              }
              toast.success(res?.data?.message ? res?.data?.message : "Configuration Created");
            } else {
              setIsLoading(false);setIsLoading2(false);
              toast.error(res?.error?.message ? res?.error?.message : "Please fill all required fields");
            }
        });
      // }
      }
    };

  const Icons = (icon) => {
    return (
      <div style={{ width: "30px", marginRight: "15px" }}>
        <MDBox bgColor={themeColor}
          style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "30px", height: "30px" }}>
          <MDBox component="img" textAlign="center"
            src={icon}
            alt="Brand" width="70%" />
        </MDBox>
      </div>
    )
  }


  const postDomains = () => {
      if (detectMsg?.length) {
        // if(/^(ftp|http|https):\/\/[^ "]+$/.test(detectMsg)){
          setWidgetConfig({...widgetConfig, domains: [...widgetConfig?.domains, detectMsg] })
          setDetectMsg('');
        // }else{
        //   toast.error("Please enter correct domain")
        // }
        
      }
  };

  return (
    <div style={{ padding: windowSize.innerWidth <= 600 ? "0px" : "0px 20px" }}>
       {/* Config Data OPEN */}
      <div>
        <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "0px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.configWidget)}

          Widget Configuration
        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap"
            style={{ gap: "20px", marginTop: "15px" }}>
            {fieldsConfigData.map(fi => (
              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex" }}>
                {
                  fi.type === "dropdown" ?
                    <MDDropdown isSmall={false} dropdownName={fi?.placeholder}
                      nameArr={fi?.arr}
                      isMultiple={false}
                      backendArr={xAgentList?.length ? xAgentList : []}
                      backSelect={(value) => {
                        if(fi?.name === "active"){
                          setWidgetConfig({...widgetConfig, active: value })
                        }else{
                          setWidgetConfig({...widgetConfig, agentId: value })
                        }
                      } }
                      personName={fi?.name === "active" ? [widgetConfig?.active] 
                      : !xAgentName?.length ? [''] : [widgetConfig?.agentId]}
                    />
                    :

                    <InputFieldComponent
                      fi={fi}
                      config={widgetConfig}
                      setConfig={setWidgetConfig}
                  />
                    // <MDInput   
                    //       style={{ width: "210px"}}
                    //       id={fi.name} 
                    //       type={fi.type}
                    //       value={widgetConfig[fi.name]} 
                    //       onChange={(e) => {
                    //         setWidgetConfig({ ...widgetConfig, [fi.name]: e.target.value });
                    //       }}
                    //       label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                    //       variant="standard"  />
                }
               
                {/* {<Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>             } */}
              </MDBox>
            ))}
          </div>
        </div>
      </div>

        {/* Config Data CLOSE */}

        {divider()}

        {/* Request limit Data OPEN */}
        <div>
          <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.rateLimitWidget)}
          Request Limiting
        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap"
            style={{ gap: "20px", marginTop: "15px" }}>
            {fieldsRequestLimitData.map(fi => (
              <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex" }}>
                 <InputFieldComponent
                      fi={fi}
                      config={widgetConfig}
                      setConfig={setWidgetConfig}
                  />
                 
                 {/* <MDInput   
                      style={{ width: "210px"}}
                      id={fi.name} 
                      type={fi.type}
                      value={widgetConfig[fi.name]} 
                      onChange={(e) => {
                        setWidgetConfig({ ...widgetConfig, [fi.name]: e.target.value });
                      }}
                      label={`${fi.placeholder}${fi.mendatory ? "*": ""}`} 
                      variant="standard"  /> */}

                 {/* <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)}/>                            */}
              </MDBox>
            ))}
          </div>
        </div>
        </div>
        {/* Request limit Data CLOSE */}

        {divider()}

        {/* Domains Data OPEN */}
        <div>
          <div style={{ display: "flex", alignItems: "center", fontSize: "14px", fontWeight: "450", margin: "30px 0px 10px 0px", color: darkMode ? "white" : "black" }}>
          {Icons(Images.domainWidget)}
          Domains
        </div>
        <div style={{ marginLeft: windowSize.innerWidth > 600 && "43px" }}>
          <div className="w-[100%] flex flex-row flex-wrap" style={{ gap: "20px", marginTop: "15px" }}>
               <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "30%", outline: "none", display: "flex", alignItems: "center" }}>
                <div style={{ width: "210px" }} className=" mt-2 relative">
                  <textarea
                    value={detectMsg}
                    onChange={(e) => setDetectMsg(e?.target?.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) { e.preventDefault(); postDomains() }
                    }}
                    className="truncateOneLine"
                    placeholder="Add Website Domains here"
                    style={{
                    color: darkMode ? "white" : Constants.ccBlue,
                    background: !darkMode ? "white" : "transparent", resize: 'none', paddingRight: "30px",
                    width: '100%', borderRadius: "5px", 
                    borderColor: "lightgray",
                    height: "43px",maxHeight: "150px",
                    }}
                  />
                  <CiCirclePlus onClick={() => postDomains()}
                    style={{ color: darkMode ? "white" : Constants.ccBlue }}
                    className={`absolute h-6 w-6 top-2 right-2 cursor-pointer`} />
                </div>
                <Tooltip uniqueId={'domains'}
                        width={windowSize.innerWidth <= 600 ? "250px" : "400px"}
                        text={"List of domains whitelisted for the chat widget"}
                  color={getColorVlue(themeColor)} />
              </MDBox>
          </div>

          {widgetConfig?.domains?.length ? widgetConfig?.domains?.map((afl) => (
              <Tag
                name={afl}
                onClick={(e) => console.log("")}
                isDelete={true}
                onDelete={(e) => { e.stopPropagation(); setModalDelOpen({ flag: true, name: afl }) }}
                className="mt-6"
                isLogo={false}
              />
            )) : null}
        </div>
        </div>
        {/* Domains Data CLOSE */}

        <MDBox mt={4} mb={1} style={{display: "flex", gap: "20px", justifyContent: "center"}}>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(true)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save'}
            </MDButton>
            <MDButton style={{width: "max-content", minWidth: "140px"}} disabled={isLoading || isLoading2} 
                      onClick={()=> { saveConfig(false)}} variant="gradient" color={themeColor} fullWidth>
                {isLoading2 ? <SpinnerLoader adjustment={true} enhance="text-white text-xs" /> : 'Save & Next'}
            </MDButton>
        </MDBox>


        {/* Modals */}

        {modalDelOpen?.flag &&
        <Modal open={modalDelOpen?.flag} setOpen={(fl) => setModalDelOpen({ flag: fl, name: "" })}>
          <div style={{ textAlign: "center" }}
            className="flex flex-row w-full justify-center items-center text-xl mb-4" >
            Are you sure you want to delete this domain? Deleted domain will be lost
          </div >

          <div className="mt-6 flex flex-row w-full justify-center items-center  text-xl font-semibold">


            <MDBox mt={4} mb={1} style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                onClick={() => setModalDelOpen({ flag: false, name: "" })}
                variant="gradient" color={"error"} fullWidth>
                No
              </MDButton>

              <MDButton style={{ width: "max-content", minWidth: "125px" }} disabled={false}
                onClick={() => {
                  setWidgetConfig({ ...widgetConfig, domains: widgetConfig?.domains?.filter(fl => fl !== modalDelOpen?.name)})
                  setModalDelOpen({ flag: false, name: "" })
                }}
                variant="gradient" color={themeColor} fullWidth>
                Yes
              </MDButton>
            </MDBox>
          </div>
        </Modal>
      }
    </div>
  );
};

export default WidgetConfig;

