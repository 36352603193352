import { useEffect, useState, React } from "react";
import TextField from '@mui/material/TextField';
import { useMaterialUIController } from "context";
import OutlinedInput from '@mui/material/OutlinedInput';
import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";

export const TextFieldComponent = ({ fi, config, setConfig, isBigBox, isMediumBox, isHandleChange }) => {

    const [controller, dispatch] = useMaterialUIController();
    const { darkMode, themeColor } = controller;

    return (
            <>
              <div style={{ marginBottom: "3px",display: "flex", flexDirection: "row", alignItems: "center",  fontSize: "14px", fontWeight: "450", margin: "0px", color: darkMode ? "white" : "black" }}>
                  {fi?.placeholder}
                  {fi?.mendatory  &&<span style={{ color: "red" }}>*</span>}

                  <div style={{ marginLeft: "5px", marginBottom: "5px" }}>
                  <Tooltip uniqueId={fi.name} text={`${fi?.information}`} color={getColorVlue(themeColor)} />
                  </div>
                </div> 

                <div style={{ display: "flex !important", alignItems: "start  !important", border: "1px solid", 
                  borderColor: '#CCCCCC', borderRadius: "7px", overflowY: "auto",                
                  height: isBigBox ? "137px" : isMediumBox ? "97px" : null,
                  }}>
                  <OutlinedInput
                    placeholder={`Enter ${fi?.placeholder}`}
                    sx={{
                      fontSize: "14px",
                      width: "100%",
                      '& .MuiOutlinedInput-notchedOutline': {
                        // borderColor: '#636363',
                        border: 'none',
                      },
                      wordWrap: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'pre-wrap',
                      resize: 'none', // Disable resizing by the user
                      '& .MuiInputBase-input::placeholder': {
                        fontSize: '14px',
                      },
                    }}
                    multiline
                    minRows={1}  // Equivalent to the default height
                    // maxRows={isHeight ? 2 : Infinity} // Allows it to grow indefinitely
                    value={isHandleChange ? config : config[fi.name]}
                    onChange={(e) => {
                      // const inputValue = e.target.value;
                      // lineCount = inputValue.split('\n').length;
                      isHandleChange ? setConfig(e)
                      : setConfig({ ...config, [fi.name]: e.target.value });
                    }}
                  />
                </div>
                

                 {/* <TextFieldWrapperStyles /> */}

            </>
    )
};
