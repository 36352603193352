export const IS_LOGIN = 'IS_LOGIN';
export const IS_LOGOUT = 'IS_LOGOUT';
export const THEME_COLOR = 'THEME_COLOR';
export const SQL_TOOLS   = "SQL_TOOLS" ;
export const FUNCTION_AS_TOOL = "FUNCTION_AS_TOOL";
export const IS_PERMISSION = "IS_PERMISSION";
export const INTERVAL_SHOULD_START = "INTERVAL_SHOULD_START";
export const INTERVAL_START_TIME = "INTERVAL_START_TIME";
export const IS_USER_LOGIN_STATUS = "IS_USER_LOGIN_STATUS";
export const IS_TIME_INTERVAL = "IS_TIME_INTERVAL"
  
