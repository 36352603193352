import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { MdCheckBox, MdOutlineCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Autocomplete, Checkbox, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { getColorVlue } from "services/Utils";
import { Tooltip } from "examples/Tooltip/Tooltip";
import MDDropdown from "components/MDDropdown";
import { useNavigate, useLocation, useParams } from "react-router-dom";

//images
import functionAsTool from "assets/images/flow-icons/function-as-tool.svg";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import FunctionAsToolsTable from "components/MDTable/MDTableFunctionAsTool";
import { useDispatch } from "react-redux";
import { isSqlToolsAction } from "redux/Action";
import ApiRequest from "services/ApiRequest";
import { Tag } from "examples/Tag/Tag";
import MDButton from "components/MDButton";
import { useResponsive, breakpoints } from 'services/Utils';
import { Toggle } from "examples/Toggle/Toggle";
import useScreenWidth from "libraries/ScreenSizeHook";


export const FunctionAsTool = ({ isSqlEnable, functionAsToolEnable, setFunctionAsToolEnable, selectedFunctions, handleFunctionSelect, setSelectedFunctions, }) => {
    const [controller] = useMaterialUIController();
    const mediaQueries = useResponsive(breakpoints);
    const { isNestHub, isMdLoptop, isloptop, isTablet, isLargeMobile, isSmallMobile, isZoomLoptopScreen } = mediaQueries;
    const { darkMode, themeColor } = controller;
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [functionTypes, setFunctionTypes] = useState([]);
    const [functionList, setFunctionList] = useState();
    const [agentNames, setAgentNames] = useState([]);
    const [selectedValues, setSelectedValues] = useState({});
    const [isLoader, setIsLoader] = useState(true);
    const [functionId, setFunctionId] = useState('')
    const [id, setId] = useState([])
    const [searchRecord, setSearchRecord] = useState('')
    const location = useLocation();
    const [windowSize] = useScreenWidth();
    const fields = [
        { id: 1, type: 'dropdown', placeholder: 'Filter by Type', name: 'Type', arr: functionTypes || [], mendatory: true },
        { id: 2, type: 'dropdown', placeholder: 'Agent Name / URL', name: 'AgentName', arr: agentNames || [], mendatory: true }
    ];

    const setSelectedOptions = async () => {
        try {
            const filteredIds = await getValues();

            if (Array.isArray(filteredIds)) {
                setSelectedFunctions(prev => {
                    const newIds = new Set(prev);

                    for (const id of filteredIds) {
                        newIds.add(id); // Add new ids to the Set
                    }
                    return Array.from(newIds); // Convert Set back to array
                });
            }
            ApiRequest.listingOfPublisedFunction({}, (res) => {
                if (res.isSuccess) {
                    var agentArr = res.data?.publishedFunctions?.map(ps => {
                        return {
                            id: ps?._id,
                            llmFunctionName: ps?.functionName,
                            agentName: ps?.agentName,
                            agentId: ps?.agentId,
                            Type: ps?.functionType
                        };
                    });
                    setId(res.data?.publishedFunctions?.map(ps => {
                        return {
                            id: ps?._id
                        }
                    }))
                    setFunctionList(agentArr?.length ? agentArr : []);
                } else {
                    setFunctionList([]);
                }

                setIsLoader(false);
            })
        } catch {
            console.log('error')
        }
    }
    useEffect(() => {
        getFunctionTypes();
        allAgentsList()
        getValues()
        setSelectedOptions()
        listingOfPublisedFunction({ shouldSearchEmpty: false })
    }, []);
    const getValues = () => {
        return new Promise((resolve, reject) => {
            ApiRequest.agentList(`/${location?.state?.objectId}`, '', (res) => {
                if (res.isSuccess) {
                    const agentdb = res.data?.agentsInformation?.[0];
                    if (agentdb?.functionTool === true) {
                        setFunctionAsToolEnable(true);
                    }
                    setFunctionId(agentdb?.functionList);
                    resolve(agentdb?.functionList);
                } else {
                    console.log("error");
                    reject(new Error("Request failed"));
                }
            });
        });
    };

    useEffect(() => {
        if (searchRecord || Object?.keys(selectedValues)?.length > 0) {
            listingOfPublisedFunction({ shouldSearchEmpty: false });
        }
    }, [selectedValues, searchRecord]);

    const listingOfPublisedFunction = (searchOption) => {
        let payload = {}

        if (selectedValues?.Type) {
            payload = { ...payload, functionType: selectedValues?.Type }
        }
        if (selectedValues?.AgentName) {
            payload = { ...payload, agentName: selectedValues?.AgentName }
        }
        if (searchTerm) {
            if (searchOption.shouldSearchEmpty) {
                payload = { ...payload };
                setSearchRecord('')
            } else {
                payload = { ...payload, functionName: searchTerm };
            }
        }

        setIsLoader(true);
        ApiRequest.listingOfPublisedFunction(payload, (res) => {
            if (res.isSuccess) {
                const filteredIds = res.data?.publishedFunctions?.filter(ps => id.includes(ps?._id));

                var agentArr = res.data?.publishedFunctions?.map(ps => {
                    return {
                        id: ps?._id,
                        llmFunctionName: ps?.functionName,
                        agentName: ps?.agentName,
                        agentId: ps?.agentId,
                        Type: ps?.functionType
                    };
                });

                setFunctionList(agentArr?.length ? agentArr : []);
            } else {
                setFunctionList([]);
            }

            setIsLoader(false);
        })
    }
    const getFunctionTypes = () => {
        ApiRequest.getFunctionTypes('', (res) => {
            if (res.isSuccess) {
                setFunctionTypes(res.data.functionTypes || []);
            } else {
                console.log('Failed to fetch function types');
            }
        });
    };
    const allAgentsList = () => {
        ApiRequest.agentList('', '', (res) => {
            if (res.isSuccess) {
                const agentsList = res.data.agentsList || [];
                const agentNamesArr = agentsList.map(agent => agent.agentName);
                setAgentNames(agentNamesArr)
            } else {
                console.log('error')
            }
        });
    };
    const handleChangeBox = () => {
        setFunctionAsToolEnable(!functionAsToolEnable);
    };

    const handleDropdownChange = (fieldName, selectedValue) => {
        setSelectedValues((prevState) => ({
            ...prevState,
            [fieldName]: selectedValue,
        }));
    };
    const handleTagDelete = (id) => {
        setSelectedFunctions(() => selectedFunctions?.filter((func) => func !== id));
    };
    const handleFunctionSearch = (event, newValue) => {
        if (newValue) {
            handleFunctionSelect(newValue.id);
            setSearchRecord(newValue.llmFunctionName);
        }
    };
    return (
        <div>
            <div style={{ padding: "5px" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",  fontSize: "14px", fontWeight: "450", margin: "0px", color: darkMode ? "white" : "black" }}>
                                Function
                    {/* <Tooltip uniqueId={"systemSqlprompt"} text={"Input that guides the SQL Tool's behaviour, providing context, or setting the tone for generating responses."} color={getColorVlue(themeColor)} /> */}
            </div>
            <div className="w-full flex flex-row items-center" 
                style={{ borderRadius: "7px",border: "1px solid #CCCCCC", padding: "10px" }}>
                <div style={{ color: darkMode ? "white" : "black"}} className=" text-sm mr-4">Enable</div>
                <Toggle enabled={functionAsToolEnable} setEnabled={(fl) => handleChangeBox()} />
            </div>
            </div>



            {functionAsToolEnable && 
            <div  style={{  border: "1px solid #C4C5C6", margin: "5px", marginTop: "12px", borderRadius: "8px", }}>
            <div className="">

             {/*========= Filters */}

            <MDBox style={{ display: "flex", padding: "20px 20px 0px", justifyContent: "space-between" }}>
                <div>
                    <MDTypography style={{ fontSize: "18px", fontWeight: "700", color: darkMode ? "white" : "#101828" }}>Function</MDTypography>
                    {/* <MDTypography style={{ fontSize: "14px", fontWeight: "400", color: darkMode ? "white" : "#667085" }}>Description</MDTypography> */}
                </div>
                
         

                <div className={`w-[100%] flex flex-row flex-wrap`} 
                      style={{ marginBottom: "12px", gap: "5px", justifyContent: "end", paddingRight: "5px"}}>

                    {/* <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "15%" }}>
                            <div className="cursor-pointer" 
                                style={{ 
                                height: "44px", fontSize: "14px", padding: "10px",
                                color: darkMode ? "white" : "black",
                                     border: `1px solid ${darkMode ? "white" : "#CCCCCC"}`, borderRadius: "7px"
                             }}>
                            Clear All
                            </div>
                    </div>         */}

                    <div style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%" }}>
                    <Autocomplete
                        options={functionList}
                        getOptionLabel={(option) => option.llmFunctionName}
                        onInputChange={(event, newInputValue) => {
                            setSearchTerm(newInputValue);
                        }}
                        onChange={handleFunctionSearch}
                        renderInput={(params) => (
                            <TextField
                            {...params}
                            placeholder="Search"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon style={{ color: '#344054', width: 20, height: 20 }} />
                                </InputAdornment>
                              ),
                              sx: {
                                height: '44px',
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                  borderColor: '#D0D5DD',
                                  '&:hover fieldset': {
                                    borderColor: '#D0D5DD',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#D0D5DD',
                                  },
                                },
                                '& .MuiInputBase-input': {
                                  fontSize: '14px',
                                  color: '#344054',
                                  '&::placeholder': {
                                    color: '#344054',
                                    opacity: 1,
                                  },
                                },
                                '& .MuiAutocomplete-endAdornment': {
                                  display: 'none',
                                },
                              },
                            }}
                            inputProps={{
                                ...params.inputProps,
                                style: { marginTop: '2px'},
                            }}
                          />
                        )}
                        // style={{ width: '200px' }}
                        />
                    </div> 


                    {/* <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search here"
                                InputProps={{
                                    ...params.InputProps,
                                    style: {
                                        borderRadius: "7px",
                                        width: "100%",
                                        height: "44px",
                                    },
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { marginTop: '-5px', fontSize: "14px" },
                                }}
                                sx={{
                                    '& .MuiAutocomplete-endAdornment': {
                                        display: 'none',
                                    },
                                }}
                                onChange={(e) => { if (e.target?.value === "") listingOfPublisedFunction({ shouldSearchEmpty: true }) }}
                            />    */}
                    
                    
                    {/* <div className="flex gap-[10px] items-center flex-wrap"> */}
                        
                        {fields.map((field, index) => (
                            <MDBox style={{ width: windowSize.innerWidth <= 600 ? "100%" : "25%", outline: "none", display: "flex", alignItems: "center" }}>
                                <MDDropdown
                                    dropdownName={field.placeholder}
                                    nameArr={field.arr.length ? field.arr : ["No Option"]}
                                    backSelect={(selectedValue) => handleDropdownChange(field.name, selectedValue)} // Handle dropdown change
                                    isMultiple={false}
                                    isLarge={true}
                                    isSmall={false}
                                    isNoLabel={true}
                                    personName={selectedValues[field.name] ?? [field.placeholder]?.filter(el => el)}
                                    // FunctionAsTools={true}
                                    // isWebToolTrain={true}
                                />
                            </MDBox>

                                ))}
                    {/* </div> */}                        
                    
                </div>
            </MDBox>

             {/*========= Selected Functions */}
                 <div style={{ padding: "5px 25px 5px 22px" }} className="flex items-center w-full justify-between flex-wrap">
                    <div className="flex gap-[10px] w-full flex-wrap justify-between">
                        {/* <div style={{ width: "80%" }}> */}
                        {
                        
                                <div className="selected-tags" style={{ display: "flex", flexWrap: "wrap", gap: "10px", 
                                    paddingTop: "10px", }}>
                                    {selectedFunctions.map((funcId) => {
                                        const functionItem = functionList?.find((func) => func.id === funcId);

                                        return functionItem && (
                                            <Tag
                                                key={functionItem?.id}
                                                name={functionItem?.llmFunctionName}
                                                onClick={() => console.log('')}
                                                onDelete={(e) => {
                                                    e?.stopPropagation()
                                                    handleTagDelete(functionItem?.id)
                                                }}
                                                isDelete={true}
                                                isFunctionAsToolTag={true}
                                            />
                                        );
                                    })}
                                </div>
                            
                        }
                        {/* </div> */}

                        {(selectedFunctions?.length > 0 ) && (
                            <MDButton style={{ width: "max-content", minWidth: "140px", 
                                            height: "10px",
                                            marginTop: "5px",    
                                            color:darkMode && themeColor==="info" ? "white": getColorVlue(themeColor), 
                                            textDecoration: "underline", fontSize: "12px", backgroundColor: darkMode && 'transparent' }} 
                                            onClick={() => setSelectedFunctions([])}>Clear All Selections</MDButton>
                        )}

                    </div>
                </div>
            </div >

                <MDBox sx={{ marginTop: "10px" }}>
                    <FunctionAsToolsTable searchTerm={searchTerm} selectedValue={selectedValues} functionList={functionList} isLoader={isLoader} selectedFunctions={selectedFunctions} setSelectedFunctions={setSelectedFunctions} handleFunctionSelect={handleFunctionSelect} />
                </MDBox>
            </div> }
            
        </div>
    );
};
