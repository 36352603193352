class Urls {
    // ========== Develop =============
    // baseUrl = "https://intellixcore-develop-64h7qzl6ga-ew.a.run.app";
    // frontendBaseUrl = "https://recommendation-system-394707.uc.r.appspot.com";
    // chatbotDeployedUrl = "https://chatbot-pattern-dev-64h7qzl6ga-ew.a.run.app";
    // bucketBubbleUrl = "https://storage.googleapis.com/intellixcore-frontend-develop/uploads/IntellixcoreWidgetAssets/chatwidget-dev.js";

    // ========== QA ==================
    // baseUrl = "https://intellixcore-qa-64h7qzl6ga-ew.a.run.app";
    // frontendBaseUrl = "https://intellixcore-qa.ew.r.appspot.com";
    // chatbotDeployedUrl = "https://chatbot-pattern-qa-64h7qzl6ga-ew.a.run.app";
    // bucketBubbleUrl = "https://storage.googleapis.com/intellixcore-frontend-qa/uploads/IntellixcoreWidgetAssets/chatwidget-qa.js";

    // =========  Production ===========
    baseUrl = "https://intellixcore-prod-coq3pn6ojq-ew.a.run.app";
    frontendBaseUrl = "https://app.intellixcore.ai";
    chatbotDeployedUrl = "https://chatbot-pattern-prod-coq3pn6ojq-ew.a.run.app";
    bucketBubbleUrl = "https://storage.googleapis.com/intellixcore-frontend-prod/uploads/IntellixcoreWidgetAssets/chatbot-prod.js";


    // ========= Common Endpoints ==========
    baseUrl2 = "https://us-central1-recommendation-system-394707.cloudfunctions.net";
    baseUrlEast = "https://us-east1-recommendation-system-394707.cloudfunctions.net";
    loginUrl = this.baseUrl + '/auth/login';
    refreshTokenUrl = this.baseUrl + '/auth/login/refresh-token';
    signUpUrl = this.baseUrl + '/user';
    resendEmailUrl = this.baseUrl + '//user/resend-confirm-email';
    forgotPasswordlUrl = this.baseUrl + '/user/forgot-password';
    changePasswordlUrl = this.baseUrl + '/user/change-password';
    validResetTokenURL = this.baseUrl + '/auth/login/forget-password';
    setNewPasswordlUrl = this.baseUrl + '/auth/login/forget-password/update';
    googleSignupUrl = this.baseUrl + '/user/google';
    microsoftSignupUrl = this.baseUrl + '/user/azure';


    SignOutUrl = this.baseUrl + '';
    getPatterns = this.baseUrl + '/get-patterns';
    createPattern = this.baseUrl + '/create-pattern';
    deleteXAgent = this.baseUrl + '/agents/delete';

    //RefreshToken
    refreshToken=this.baseUrl + '/auth/login/refresh-token'

    // Patterns List
    patterns = this.baseUrl + '/pattern';

    // Drodown Patterns List
    patternsDropdown = this.baseUrl + '/pattern/listpattern';

    // Drodown Agents List
    agentsDropdown = this.baseUrl + '/agents/agentbyuser';
    

    // Agents List
    agents = this.baseUrl + '/agents';

    // Billing Plans
    billing = this.baseUrl + '/membership-plan/list-membership-plans';
    contactUs = this.baseUrl + '/membership-plan/contact-us';
    subscribe = this.baseUrl + '/membership-plan';
    cancel = this.baseUrl + '/membership-plan/cancel-membership/';
    downgrade = this.baseUrl + '/membership-plan/downgrade-membership/'; 
    checkout = this.baseUrl + '/membership-plan/checkout';  
    listPaymentHistory = this.baseUrl + '/membership-plan/payment-history';
    listUsageHistory = this.baseUrl + '/user/get-token-usage';
    listpaymentMehods = this.baseUrl + '/membership-plan/get-payment-methods';
    userPaymentStatus = this.baseUrl + '/membership-plan/membership-status';
    userPermissionStatus = this.baseUrl + '/membership-plan/limits';
   
    

    // Models
    listModels = this.baseUrl + '/pattern/models';

    // Feature Extraction Pattern
    configFeatureExtractionAgent = this.baseUrl + '/feature-extraction-agent';
    featureExtractionAgent = this.baseUrl + '/feature-extraction-agent/deploy';
    statusFeatureExtractionAgent = this.baseUrl + '/feature-extraction-agent/deploy/status';
    createModelFeatureExtractionAgent = this.baseUrl + '/feature-extraction-agent/model';
    deleteModelFeatureExtractionAgent = this.baseUrl + '/feature-extraction-agent/model/delete';
    uploadPreviewFeatureExtractionAgent = this.baseUrl + '/document/read';
    deleteFeatureExtractionAgent = this.baseUrl + '/document/delete';
    trainFeatureExtractionAgent = '/feature-extraction-agent/train';
    inferFeatureExtractionAgent = '/feature-extraction-agent/inference';
    inferBucketSaveFeatureExtractionAgent = this.baseUrl + '/document/download';

    // Recommendation Pattern
    configRecommendationAgent = this.baseUrl + '/recommendation-system-agent';
    recommendationAgent = this.baseUrl + '/recommendation-system-agent/deploy';
    statusRecommendationAgent = this.baseUrl + '/recommendation-system-agent/deploy/status';
    createModelRecommendationAgent = this.baseUrl + '/recommendation-system-agent/model';
    deleteModelRecommendationAgent = this.baseUrl + '/recommendation-system-agent/model/delete';
    searchRecommendationAgent = this.baseUrl + '/recommendation-system-agent/search';
    uploadPreviewRecommendationAgent = this.baseUrl + '/document/read';
    deleteRecommendationAgent = this.baseUrl + '/document/delete';
    trainRecommendationAgent = '/recommendation-system-agent/train';
    inferRecommendationAgent = '/recommendation-system-agent/inference';
    inferBucketSaverRecommendationAgent = this.baseUrl + '/document/download';

    // Chatbot Pattern
    configChatbotAgent = this.baseUrl + '/chatbot-agent';
    deployChatbotAgent = this.baseUrl + '/chatbot-agent/deploy';
    statusChatbotAgent = this.baseUrl + '/chatbot-agent/deploy/status';
    uploadChatbotAgent = this.baseUrl + '/document';
    getChatHistory = this.baseUrl + '/chatbot-agent/listpreviouschat';
    getChatDetail = this.baseUrl + '/chatbot-agent/viewconversation';
    saveGuardrailsAI = this.baseUrl + '/chatbot-agent/guardrails';
    retailFeedback = this.baseUrl + '/chatbot-agent/deleteembeddings';
    //  trainChatbotAgent = '/chatbot-agent/train';
    getWebsiteUrls = this.baseUrl + "/document/read/URL";
    saveUrls = this.baseUrl + "/chatbot-agent/tools/website";
    chatbotSqlTool = this.baseUrl + "/chatbot-agent/tools/configure";
    sqlToolDropDownValues = this.baseUrl + "/chatbot-agent/tools/sql/drivers"
    sqlDeleteDocument = this.baseUrl + '/chatbot-agent/tools/sql/secret'
    // Image To Text Pattern
    configImageToTextAgent = this.baseUrl + '/image-to-text-agent';
    inferenceImageToTextAgent = this.baseUrl + '/image-to-text-agent/inference';
     // Text To Speech Pattern
     configTextToSpeechAgent = this.baseUrl + '/text-to-speech-agent';
     inferenceTextToSpeechAgent = this.baseUrl + '/text-to-speech-agent/inference';
     dropdownTextToSpeechAgent = this.baseUrl + '/text-to-speech-agent';
     inferenceTextToSpeechAgent= '/text-to-speech/inference'

     
    // Speech To Text Pattern
    configSpeechToTextAgent = this.baseUrl + '/speech-to-text-agent';
    inferenceSpeechToTextAgent = '/speech-to-text-agent/inference';

    // Speech To Text Pattern
    uploadFiles = this.baseUrl2 + '/upload-files-to-bucket';
    generateTranscription = this.baseUrl2 + '/STT-Audio-Transcription';
    textSummary = this.baseUrl2 + '/STT-Text-Summary';
    sentimentAnalysis = this.baseUrl2 + '/STT-Sentiment-Analysis';

    // Predictive Pattern
    configPredictiveAgent = this.baseUrl + '/predictive-analysis-agent';
    deployPredictiveAgent = this.baseUrl + '/predictive-analysis-agent/deploy';
    statusPredictiveAgent = this.baseUrl + '/predictive-analysis-agent/deploy/status';
    createModelPredictiveAgent = this.baseUrl + '/predictive-analysis-agent/model';
    deployPredictiveAnalysis = this.baseUrl2 + '/predictive-model-inference';
    deleteModelPredictiveAgent = this.baseUrl + '/predictive-analysis-agent/model/delete';
    uploadPreviewPredictiveAgent = this.baseUrl + '/document/read';
    deletePredictiveAgent = this.baseUrl + '/document/delete';
    trainPredictiveAgent = '/predictive-analysis-agent/train';
    inferPredictiveAgent = '/predictive-analysis-agent/inference';


    // Recommendation Pattern
    configRecommendAnalysis = this.baseUrlEast + '/recommendation-system-config';
    inferenceRecommendAnalysis = this.baseUrlEast + '/recommendation-system-inference';

    // Dashboard Stats
    dashboardStats = this.baseUrl + '/agents/dashboard';
    storageUsage = this.baseUrl + '/membership-plan/limits'
    dashboardChartStats= this.baseUrl + '/dashboard/agent'
    dashboardTokenUsagePerAgent = this.baseUrl + '/dashboard/agent/tokens'

    //  Widget Pattern
    createModelWidget = this.baseUrl + '/chatbot-agent/widget';
    updateModelWidget = this.baseUrl + '/chatbot-agent/widget/update';
    createDesignWidget = this.baseUrl + '/chatbot-agent/widget/css';
    listWidgets = this.baseUrl + '/chatbot-agent/widget/agents';
    editWidget = this.baseUrl + '/chatbot-agent/widget';
    deleteWidget = this.baseUrl + '/chatbot-agent/widget/delete';
    listReadyAgents = this.baseUrl + '/agents/agentbypattern';
    readIcons = this.baseUrl + '/document/readimage';
    uploadIcons = this.baseUrl + '/document/uploadimage';
    deleteIcons = this.baseUrl + '/document/deleteimage';

    // Workforce
    workForceStrategyDefaultValue = this.baseUrl + '/user/strategic-planning/default';
    workForceStrategy = this.baseUrl + '/user/strategic-planning';
    estimatedSavings = this.baseUrl + '/user/strategic-planning/execute'

    //llm functions
    llmFunctionAttributeValue = this.baseUrl + '/agents/attributes'
    createLlmFunction = this.baseUrl + '/chatbot-agent/function'
    listingLlmFunction = this.baseUrl + '/chatbot-agent/function/agents'
    getLLMFunctionDetails= this.baseUrl + '/chatbot-agent/function'
    getTestAttribute = this.baseUrl + '/chatbot-agent/function/functionId/inputAttributes'
    llmFunctionInferenceScreen = this.chatbotDeployedUrl + '/chatbot-agent/function'
    llmFunctionInferenceScreenPublish = this.baseUrl + '/chatbot-agent/function/v1'
    deleteLLMFunction = this.baseUrl + '/chatbot-agent/function/delete'
    llmfunctionPublish = this.baseUrl + '/chatbot-agent/function/publish'
    llmfunctionUnPublish = this.baseUrl + '/chatbot-agent/function/unpublish'
    userApisKeys = this.baseUrl + '/user/apikeys/all'
    getAccessDetail = this.baseUrl + '/chatbot-agent/access'

    //api key management module
    createApi = this.baseUrl + '/user/apikey'
    deleteApiKey = this.baseUrl + '/user/apikey/delete'
    //function as Tool
    listPublisedFunction = this.baseUrl + '/chatbot-agent/function/published'
    getTypes=this.baseUrl + '/chatbot-agent/function/types'
    
    
}

const ApiUrls = new Urls();

export default ApiUrls;
